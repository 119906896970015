import React, { useState, useEffect, useMemo } from "react";
import Header from "./Header";
import { Tooltip } from '@mui/material';
import "./Sidebar.css";
import { IoIosArrowBack, IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import API_URL from "../../src/Config";

function importAll(r) {
  let icons = {};
  r.keys().forEach((item, index) => { icons[item.replace('./', '')] = r(item); });
  return icons;
}

const icons = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg)$/));

function Sidebar({ onSidebarToggle }) {
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showSide, setShowSide] = useState(true);
  const [dropdownState, setDropdownState] = useState({});

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
    setShowSide(!showSide);
  };

  useEffect(() => {
    onSidebarToggle(isExpanded);
  }, [isExpanded, onSidebarToggle]);

  // Fetch menu items only once, using localStorage for caching
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_menu_items`, {
          headers: {
            Authorization: authKey,
          },
        });
        setMenuItems(response.data);
        localStorage.setItem("menuItems", JSON.stringify(response.data)); // Cache menu items
      } catch (error) {
        console.error("Error fetching menu items:", error.message);
      }
    };

    // Check if menu items are already in local storage
    const cachedMenuItems = localStorage.getItem("menuItems");
    if (cachedMenuItems) {
      setMenuItems(JSON.parse(cachedMenuItems));
    } else if (menuItems.length === 0) {
      fetchData(); // Fetch only if not already fetched
    }
  }, []); // Empty dependency array to run only on component mount

  const handleLinkClick = (path) => {
    if (activeLink !== path) { 
      setActiveLink(path);
    }
  };

  const toggleDropdown = (menuItemId) => {
    if (isExpanded) {
      setIsExpanded(true);
      setShowSide(true);
    }
    
    setDropdownState((prevState) => ({
      ...prevState,
      [menuItemId]: !prevState[menuItemId],
    }));
  };

  const renderIcon = (icon) => {
    const extensions = ['png', 'jpg', 'jpeg', 'svg'];
    const className = icon === "dot" ? "sidebar-dot-icon" : "sidebar-icon";

    for (let ext of extensions) {
      try {
        const IconComponent = icons[`${icon}.${ext}`];
        if (IconComponent) {
          return <img src={IconComponent} className={className} alt={icon} />;
        }
      } catch (error) {
        console.error(`Icon not found: ${icon}.${ext}`);
      }
    }
    return null;
  };

  const renderExpandCollapseIcon = (isOpen) => {
    return isOpen ? (
      <IoIosArrowDown className="expand-collapse-icon" />
    ) : (
      <IoIosArrowForward className="expand-collapse-icon" />
    );
  };

  const renderMenuItems = (items) => {
    return (
      <div className="submenu">
        {items.map(({ id, name, route, icon, sub_menu }) => (
          <div key={id}>
            {route ? (
              <Link
                to={route}
                onClick={() => handleLinkClick(route)}
                className={`user-link ${activeLink === route ? "active" : ""}`}
              >
                <div className="user sidebar-hover">
                  <div className="user-icon">{renderIcon(icon)}</div>
                  {isExpanded && <div className="user-font">{name}</div>}
                </div>
              </Link>
            ) : (
              <div
                className={`user sidebar-hover ${dropdownState[id] ? "active" : ""}`}
                onClick={() => toggleDropdown(id)}
              >
                <div className="user-icon">{renderIcon(icon)}</div>
                {isExpanded && <div className="user-font">{name}</div>}
                {isExpanded && (
                  <div className="expand-collapse-icon">
                    {renderExpandCollapseIcon(dropdownState[id])}
                  </div>
                )}
              </div>
            )}
  
            {dropdownState[id] && sub_menu.length > 0 && isExpanded && (
              <div className="submenu-menu">
                {renderMenuItems(sub_menu)}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  // Memoize the rendered menu items to prevent unnecessary re-renders
  const renderedMenuItems = useMemo(() => renderMenuItems(menuItems), [menuItems, activeLink, dropdownState, isExpanded]);

  return (
    <>
      <Header />
      <div className="product-content-container">
        <div className="sidebar-container">
          {showSide && (
            <div className="sidebar-content">{renderedMenuItems}</div>
          )}
          {!showSide && (
            <div className="sidebar-content2">
              {menuItems.map((menuItem) => (
                <Tooltip key={menuItem.id} title={menuItem.name} arrow placement="right"> 
                  <Link
                    to={menuItem.route}
                    onClick={() => handleLinkClick(menuItem.route)}
                    className="user sidebar-hover"
                  >
                    {renderIcon(menuItem.icon)} 
                  </Link>
                </Tooltip>
              ))}
            </div>
          )}
          <div
            className="sidebar-footer"
            style={{
              width: isExpanded ? "250px" : "50px",
              transition: "width 0.3s ease-in-out",
              zIndex: 2,
            }}
            onClick={toggleSidebar}
          >
            <div className="expand-sidebar">
              {isExpanded ? <IoIosArrowBack /> : <IoIosArrowForward />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
