import React, { useState, useEffect } from "react";
import "./UserForm.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
import { format } from "date-fns";


function ProjectForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [customerMenuIsOpen, setCustomerMenuIsOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [projectTypes, setProjectTypes] = useState(null);
  const [showCustomerPopup, setShowCustomerPopup] = useState(false);
  const [selectedProjectType, setSelectedProjectType] = useState(null);

  const [projectData, setProjectData] = useState({
    project_name: "",
    customer_name: "",
    customer_id: "",
    customer_location: "",
    project_type_id: "",
    project_type: "",
    po_number: "",
    start_date: "",
    end_date: "",
  });
  const { theme } = useContext(ThemeContext);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProjectData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleCustomerChange = async (selectedOption) => {
    setSelectedCustomer(selectedOption);
    setProjectData((prevState) => ({
      ...prevState,
      customer_name: selectedOption.label,
      customer_id: selectedOption.value,
      customer_location: "",
    }));

    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_locations/${selectedOption.value}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      console.log("Locations response data:", response.data); // Debugging log

      const locationOptions = response.data.map((location) => ({
        value: location.id,
        label: location.location, // Map `location.location` to `label`
      }));

      setLocations(locationOptions);
      setSelectedLocation("");
      console.log("Updated locations state:", locationOptions); // Debugging log
    } catch (error) {
      console.error("Error fetching locations data:", error.message);
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedProjectType(selectedOption);
    setProjectData((prevState) => ({
      ...prevState,
      project_type_id: selectedOption.value,
      project_type: selectedOption.label,
    }));
  };

  const handleAddCustomer = (newCustomer) => {
    if (newCustomer) {
      setCustomers((prevCustomers) => [...prevCustomers, newCustomer]);
      setSelectedCustomer(newCustomer);
      setProjectData((prevFormData) => ({
        ...prevFormData,
        customer_name: newCustomer.label,
        customer_id: newCustomer.value,
      }));
    }
  };

  // const handleNewAddCustomer = () => {
  //   setShowCustomerPopup(true);
  //   setCustomerMenuIsOpen(false);
  // };

  const handleCustomerMenuOpen = () => {
    setCustomerMenuIsOpen(true);
  };

  const handleCancelClick = () => {
    navigate("/project");
  };

  const fetchCustomerData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_all_customers`, {
        headers: {
          Authorization: authKey,
        },
      });

      const customerOptions = response.data.Customers.map((customer) => ({
        value: customer.id,
        label: customer.customer_name,
      }));

      setCustomers(customerOptions);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
    }
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const fetchProjectType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_project_types`, {
        headers: {
          Authorization: authKey,
        },
      });

      console.log(response.data);

      const projectTypeOptions = response.data.map((project) => ({
        value: project.id,
        label: project.type_name,
      }));

      setProjectTypes(projectTypeOptions);
    } catch (error) {
      console.error("Error fetching project types data:", error.message);
    }
  };

  useEffect(() => {
    fetchProjectType();
  }, []);

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     // Add start_date and end_date to the projectData
  //     const payload = {
  //       ...projectData,
  //       start_date: selectedStartDate
  //         ? format(selectedStartDate, "yyyy-MM-dd")
  //         : "",
  //       end_date: selectedEndDate ? format(selectedEndDate, "yyyy-MM-dd") : "",
  //       location_id: selectedLocation ? selectedLocation.value : "",
  //     };

  //     const response = await axios.post(`${API_URL}/api/add_project`, payload, {
  //       headers: {
  //         Authorization: authKey,
  //       },
  //     });

  //     console.log(response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Project added successfully");
  //       setTimeout(() => {
  //         navigate("/project");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving project:", error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Validate required fields
      const errors = {};
      if (!projectData.project_name.trim()) {
        errors.project_name = "Project Name is required";
      }
      if (!projectData.customer_name) {
        errors.customer_name = "Customer Name is required";
      }
      if (!projectData.customer_location) {
        errors.customer_location = "Customer Location is required";
      }
      if (!projectData.po_number.trim()) {
        errors.po_number = "PO Number is required";
      }
      if (!projectData.project_type) {
        errors.project_type = "Project Type is required";
      }
      if (!selectedStartDate) {
        errors.start_date = "Start Date is required";
      }
      if (!selectedEndDate) {
        errors.end_date = "End Date is required";
      }

      // Check if any errors exist
      const hasErrors = Object.keys(errors).length > 0;

      if (hasErrors) {
        // Show the error message in the snackbar with red color
        setSeverity("error");
        showSnackbar("All fields are required.");
      } else {
        // Add start_date and end_date to the projectData
        const payload = {
          ...projectData,
          start_date: selectedStartDate ? format(selectedStartDate, "yyyy-MM-dd") : "",
          end_date: selectedEndDate ? format(selectedEndDate, "yyyy-MM-dd") : "",
          location_id: selectedLocation ? selectedLocation.value : "",
        };

        const response = await axios.post(`${API_URL}/api/add_project`, payload, {
          headers: {
            Authorization: authKey,
          },
        });

        console.log(response.data.message);
        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Project added successfully");
          setTimeout(() => {
            navigate("/project");
          }, 1000);
        }
      }
    } catch (error) {
      console.error("Error saving project:", error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setProjectData((prevState) => ({
      ...prevState,
      start_date: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setProjectData((prevState) => ({
      ...prevState,
      end_date: date,
    }));
  };

  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
    setProjectData((prevState) => ({
      ...prevState,
      customer_location: selectedOption ? selectedOption.label : "",
    }));
  };

  const closeCustomerPopup = () => {
    setShowCustomerPopup(false);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Project
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="project_name">
                    Project Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="project_name"
                      value={projectData.project_name}
                      onChange={handleInputChange}
                      placeholder="Enter project name"
                    />
                  </div>
                </div>
                <div className="form-group-select">
                  <label htmlFor="customer_name">
                    Customer Name<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="customer_name"
                    options={customers}
                    value={selectedCustomer}
                    onChange={handleCustomerChange}
                    // components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select customer"
                    isSearchable
                    menuIsOpen={customerMenuIsOpen}
                    onMenuOpen={handleCustomerMenuOpen}
                    onMenuClose={() => setCustomerMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="customer_location">
                    Customer Location<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="customer_location"
                    options={locations}
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    placeholder="Select location"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="po_number">
                    PO Number<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="po_number"
                      value={projectData.po_number}
                      onChange={handleInputChange}
                      placeholder="Enter PO number"
                    />
                  </div>
                </div>
                <div className="form-group-select">
                  <label htmlFor="ProjectType">
                    Project Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="projectType"
                    options={projectTypes}
                    value={selectedProjectType}
                    onChange={handleChange}
                    placeholder="Select project type"
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="start_date">
                    Start Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedStartDate}
                      onChange={handleStartDateChange}
                      name="start_date"
                      id="start_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select start date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="end_date">
                    End Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedEndDate}
                      onChange={handleEndDateChange}
                      name="end_date"
                      id="end_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select end date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
              </div>
            </form>
            {/* {showCustomerPopup && (
              <div className="inquiry-customerperson-popup">
                <AddCustomerPopup
                  closePopup={closeCustomerPopup}
                  setSelectedcustomer={setSelectedCustomer}
                  handleAddCustomer={handleAddCustomer}
                />
              </div>
            )} */}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ProjectForm;
