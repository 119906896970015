// src/components/Dashboard.js
import React, { useEffect, useState,useContext } from "react";
import axios from "axios";
import API_URL from "../../src/Config";
import { Doughnut } from "react-chartjs-2";
import "./Dashboard.css"; // Import the CSS file
import { TbRefresh } from "react-icons/tb";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import "../Responsive/DashboardResponsive.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ThemeContext } from "../../src/ThemeContext"
// Register the necessary components with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = ({ isSidebarExpanded }) => {
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const isDarkTheme = theme === 'dark';

  const fetchData = async () => {
    setIsLoading(true);
    const authKey = localStorage.getItem("authKey");

    try {
      // Fetch data for the Doughnut chart
      const chartResponse = await axios.get(
        `${API_URL}/api/todays_due_preventive_schedules`,
        {
          headers: { Authorization: authKey },
        }
      );

      // Fetch data for the table
      const tableResponse = await axios.get(
        `${API_URL}/api/todays_department_preventive_schedule_counts`,
        {
          headers: { Authorization: authKey },
        }
      );

      // Set data for the chart and table
      setChartData(chartResponse.data);
      setTableData(tableResponse.data);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data: {error.message}</p>;

  const handleDonutChartRefresh = () => {
    fetchData();
  };

  const doughnutChartData = {
    labels: [
      "Active",
      "Cancelled",
      "Completed",
      "Hold",
      "In Progress",
      "Not in Tenure",
      "Overdue",
    ], // Labels for the chart
    datasets: [
      {
        data: [
          chartData?.active || 0, // Use 0 if the data is null or 0
          chartData?.cancelled || 0,
          chartData?.completed || 0,
          chartData?.hold || 0,
          chartData?.in_progress || 0,
          chartData?.not_in_tenure || 0,
          chartData?.overdue || 0,
        ], // Data for the chart
        backgroundColor: [
          "#2980b9",
          "#f81700",
          "#2e8b57",
          "#d4ac0d",
          "#f4a462",
          "#313639",
          "#c0612b",
        ],
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };

  // Render the chart only if there is non-zero data
  const hasChartData = doughnutChartData.datasets[0].data.some(
    (value) => value > 0
  );

  // const doughnutChartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       position: "bottom", // Moves the legend to the bottom
  //       labels: {
  //         usePointStyle: true, // Makes legend points circular
  //         font: {
  //           size: 14, // Increases font size for better readability
  //           weight: "bold", // Makes labels bold
  //         },
  //         padding: 20, // Adds padding between legend items
  //         color: "#333", // Sets color of legend labels
  //       },
  //     },
  //     tooltip: {
  //       backgroundColor: "#f5f5f5",
  //       bodyColor: "#000",
  //       borderColor: "#ddd",
  //       borderWidth: 1,
  //       titleColor: "#000",
  //       padding: 10,
  //       zIndex: 99999,
  //       callbacks: {
  //         label: function (tooltipItem) {
  //           return `${tooltipItem.label}: ${tooltipItem.raw}`; // Custom label for tooltips
  //         },
  //       },
  //     },
  //   },
  //   cutout: "60%", // Creates a larger hole in the middle (donut style)
  //   layout: {
  //     padding: {
  //       top: 20,
  //       bottom: 20, // Adjusts the padding to ensure the chart fits well
  //     },
  //     elements: {
  //       arc: {
  //         borderRadius: 2,
  //         spacing: 0,
  //       },
  //     },
  //   },
  //   // animation: {
  //   //   animateScale: true, // Adds a scaling animation to the chart
  //   //   animateRotate: true, // Adds rotation animation when chart renders
  //   // },

  //   // hoverOffset: 10, // Increases the hover effect for better interactivity
  // };
  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom', // Moves the legend to the bottom
        labels: {
          usePointStyle: true, // Makes legend points circular
          font: {
            size: 14, // Increases font size for better readability
            weight: 'bold', // Makes labels bold
          },
          padding: 20, // Adds padding between legend items
          color: isDarkTheme ? '#FFFFFF' : '#333', // Set color of legend labels based on theme
        },
      },
      tooltip: {
        backgroundColor: isDarkTheme ? '#333' : '#f5f5f5', // Darker background for tooltip in dark theme
        bodyColor: isDarkTheme ? '#FFFFFF' : '#000', // Change body color to white in dark theme
        borderColor: '#ddd',
        borderWidth: 1,
        titleColor: isDarkTheme ? '#FFFFFF' : '#000', // Change title color to white in dark theme
        padding: 10,
        zIndex: 99999,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`; // Custom label for tooltips
          },
        },
      },
    },
    cutout: '60%', // Creates a larger hole in the middle (donut style)
    layout: {
      padding: {
        top: 20,
        bottom: 20, // Adjusts the padding to ensure the chart fits well
      },
      elements: {
        arc: {
          borderRadius: 2,
          spacing: 0,
        },
      },
    },
  };
  return (
    <div
      className={`data-list-content-container page-content ${
        isSidebarExpanded ? "expanded" : "collapsed"
      }`}
    >
      <div className="data-form-content">
        <div className="data-form-header">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">Dashboard</div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="dashboard-container">
          <div className="dashboard-container-content">
            <div className="chart-donut">
              <div className="chart-donut-container">
                <div className="chart-donut-heading">
                  <div> Today's preventive schedules count</div>
                  {/* <div className="heading-refresh"><button className='refresh-button-dashboard' onClick={handleDonutChartRefresh}><TbRefresh className='refresh-icons' /></button></div> */}
                </div>
                <div className="chart-donut-body">
                  <div className="chart-donut-body-content">
                    <div className="pie-content1"> Pie Chart </div>
                    <div className="pie-content2">January - October 2024 </div>
                  </div>
                  <div
                    className="donut-chart"
                    style={{
                      width: "490px",
                      height: "424px",
                      marginLeft: "15px",
                    }}
                  >
                    <Doughnut
                      data={doughnutChartData}
                      options={doughnutChartOptions}
                    />
                    <div
                      className="detail-chart"
                      style={{
                        textAlign: "center",
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      {chartData ? chartData.total_schedules : 0} <br />{" "}
                      Schedules
                    </div>
                    {/* </>
                      )} */}
                  </div>

                  <div className="chart-footer">
                    <div className="footer-content1">
                      Trending up by 5.2% this month{" "}
                      <HiMiniArrowTrendingUp className="graph-arrow" />
                    </div>
                    <div className="footer-content2">
                      Showing total projects for the last 6 months
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="chart-donut">
              <div className="chart-donut-container">
                <div className="chart-donut-heading">
                  <div> Departments & today's preventive schedules count</div>
                </div>
                <div className="dashbord-table">
                  <table>
                    <thead>
                      <tr>
                        <th>SERIAL NO</th>
                        <th>DEPARTMENTS</th>
                        <th>ACTIVE</th>
                        <th>COMPLETED</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.department_name || "NA"}</td>
                          <td className="active-status">
                            {item.active || "0"}
                          </td>
                          <td className="complet-status">
                            {item.completed || "0"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
