import React, { useState, useEffect, useRef } from "react";
import "./AllInventory.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineWatchLater } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import Pagination from "../Component/Pagination";
import { MdAttachFile } from "react-icons/md";
import useClickOutside from "../useClickOutside";
import "../TableColor.css";

function RejectedStock({ isSidebarExpanded, designationID }) {
  const [rejectedLists, setRejectedLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(null);
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  
  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedschedules = [...rejectedLists].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setRejectedLists(sortedschedules);
  };
 

  const handleIconClick = (index, event) => {
    event.preventDefault(); // Prevent default behavior if necessary
    event.stopPropagation(); // Prevent the click from bubbling up

    // Toggle the popup for the specific index
    setIsPopupOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.get(
        `${API_URL}/api/get_rejected_purchase_orders`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );
  
      if (response.status === 200) {
        // Map RejectedPurchaseOrders correctly
        setRejectedLists(response.data.RejectedPurchaseOrders || []);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching rejected POs:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching rejected POs:", error.message);
    } finally {
      setLoading(false);
    }
  };
  

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/search_product_inventory`,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query: searchInput,
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      if (response.status === 200) {
        setRejectedLists(response.data.RejectedPurchaseOrders);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? rejectedLists.map((inwardList) => inwardList.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (designationID) => {
    const updatedSelectedRows = selectedRows.includes(designationID)
      ? selectedRows.filter((id) => id !== designationID)
      : [...selectedRows, designationID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === rejectedLists.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchiveDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDesignations = rejectedLists.filter(
          (designation) => !selectedRows.includes(designation.ID)
        );
        setRejectedLists(updatedDesignations);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  // const handleViewClick = async (mat_code_id) => {
  //   navigate(`/product-inventory-view/${mat_code_id}`);
  // };

  const handleViewClick = async (id) => {
    navigate(`/rejected-view/${id}`);
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveDepartment}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Rejected Stock List
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button
                      className="sort-button"
                      onClick={handleSortpopup}
                      ref={buttonRef}
                    >
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("po_no")}>
                            PO No{" "}
                              {sortOrder.po_no === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("status")}>
                            Status{" "}
                              {sortOrder.status === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                           
                           
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : rejectedLists.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>INWARD ID</th>
                          <th>PO NO</th>
                          <th>STATUS</th>
                       
                          <th>INWARD AT</th>
                          <th>ATTACHMENT</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {rejectedLists.map((rejectedList, index) => (
                          <tr key={rejectedList.id} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(rejectedList.id)}
                                onChange={() =>
                                  handleSelectRow(rejectedList.id)
                                }
                              />
                            </td>

                            <td>{rejectedList.serial_no}</td>
                            <td className="totals">{rejectedList.random_inbound_id || "NA"}</td>
                            <td>{rejectedList.po_no || "NA"}</td>
                            <td className="active">
                              {" "}
                              <span>{rejectedList.status || "0"}</span>
                            </td>
                          
                            <td>
                              {rejectedList.inbounded_at
                                ? new Date(rejectedList.inbounded_at)
                                    .toUTCString()
                                    .split("GMT")[0]
                                    .trim() + " IST"
                                : "NA"}
                            </td>

                            <td>
  <span
    className="inquiry-file-btns"
    onClick={(event) => handleIconClick(index, event)}
    ref={buttonRef}
  >
    <MdAttachFile className="inquiry-file-icon" />
  </span>

  {isPopupOpen === index && (
    <div className="inquiry-attach-popup" ref={containerRef}>
      <div className="attach-popup-contant">
        <div className="attachment-header">
          <div className="attachment-header-content">Attachments</div>
          <button className="fiile-close-btn" onClick={handleCloseAttachment}>
            <IoCloseOutline className="file-close-icon" />
          </button>
        </div>

        <div className="attach-popup-contant-body" key={index}>
          <div className="inquiry-attach-file-containerssss">
            <div className="attach-file-showsss">
              <div className="text-attachmentss">
                {rejectedList.attachments && rejectedList.attachments.length > 0 ? (
                  <div className="attach-popup-content-body">
                    {rejectedList.attachments.map((attachment, attachmentIndex) => (
                      <div
                        className="inquiry-attach-file-container"
                        key={attachment.file_id}
                      >
                        <div className="inquiry-attacth-file-model">
                          <div className="attach-file-show">
                            <div className="text-attachment">
                              {/* Truncate filename if too long */}
                              {attachment.filename.length > 20
                                ? `${attachment.filename.substring(0, 15)}...`
                                : attachment.filename}
                            </div>

                            <div className="text-attachment-file">
                              File Size: {attachment.file_size || "NA"} KB
                            </div>
                          </div>

                          <div className="file-footer">
                            <a
                              href={`${API_URL}/api/download_pr_attachment/${attachment.file_id}/${attachment.file_type}`}
                              download
                              className="file-download-attachment file-txt"
                            >
                              Download
                            </a>
                            <a
                              href={`${API_URL}/api/view_pr_attachment/${attachment.file_id}/${attachment.file_type}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="file-download-attachment file-txt"
                            >
                              View
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-attachments">No attachments found</div>
                )}
              </div>
            </div>
            <div className="file-delete-btn"></div>
          </div>
        </div>
      </div>
    </div>
  )}
</td>

                            <td>
                              
                              <button
                                onClick={() => handleViewClick(rejectedList.id)}
                                className="view-button"
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Rejected Stock List Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}
        {/* {isMainContainerVisible && (
          <div className="main-containers">
            <UserView userID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )} */}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default RejectedStock;
