import React, { useState, useEffect } from "react";
import "./POConfigurationForm.css";
import { useNavigate, useParams } from "react-router-dom"; // useParams to get po_id
import axios from "axios"; // Ensure axios is imported
import API_URL from "../../src/Config"; // Ensure the correct path to your config file
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";

function POView({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams(); // Get po_id from the route parameters
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [poData, setPoData] = useState({ po_no: "" });
  const [rows, setRows] = useState([]);

  // Fetch API Data
  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_po_list_items/${id}`, {
        headers: {
          Authorization: authKey,
        },
      });

      const { po_list, po_list_items } = response.data; // Correctly extract from the response

      // Set data into the respective states
      setPoData({ po_no: po_list.po_no }); // Accessing PO number from po_list
      setSelectedVendor({
        label: po_list.vendor_name,
        value: po_list.vendor_id,
      });

      // Map items to rows for the table
      const rowsData = po_list_items.map((item) => ({
        matCodes: item.mat_code,
        quantity: item.quantity,
        price: item.price,
        unit: item.unit,
        est_unit_rate: item.est_unit_rate,
        description: item.description,
        inwarded_qty: item.inwarded_qty,
        mat_code_description: item.mat_code_description,
      }));

      setRows(rowsData);
    } catch (error) {
      console.error("Error fetching PO data:", error.message);
    }
  };


  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]); // Only fetch when po_id is available

  const handleBackProject = () => {
    navigate("/po-list");
  };

  return (
    <div
      className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
        }`}
    >
      <div className="data-form-content">
        <div className="data-form-header">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">
              <Tooltip title="Back" arrow>
                <IoMdArrowBack
                  className="back-company"
                  onClick={handleBackProject}
                />
              </Tooltip>PO List Items</div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="data-form-body">
          <div className="action-purchase-header">
            <div className="project-detailss">
              <div className="project-detail-arround">
                <div className="project-details-left-side">
                  <div className="project-detail">
                    <div className="detail-header">PO Number:</div>
                    {/* Display PO number here */}
                    <div className="detail-part">{poData.po_no || "N/A"}</div>
                  </div>
                  <div className="project-detail">
                    <div className="detail-header">Vendor:</div>
                    {/* Display Vendor name here */}
                    <div className="detail-part">
                      {selectedVendor ? selectedVendor.label : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="action-purchase-sheet-header">
            <div className="actions-purchase-sheet-header-content">
              <div className="actions-purchase-sheet-heading-content-left-side">
                Item Table
              </div>
            </div>
          </div>

          <div className="action-purchase-body">
            <div className="action-purchase-scroll-body">
              <div className="action-purchase-table">
                <table className="action-table action-multilevel-data ">
                  <thead className="action-purchase-heads">
                    <tr>
                      <th className="th2">MAT CODE</th>

                      <th className="th3">ESTIMATED UNIT RATE</th>
                      <th className="th5">DESCRIPTION</th>
                      <th className="th4">PRICE</th>
                      <th className="th3 Total-Qty">PO QTY</th>
                      <th className="th4">UNIT</th>
                      <th className="th3 Inward-Qty">INWARDED QTY</th>
                    </tr>
                  </thead>
                  <tbody className="action-purchase-scroll-bodys">
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td className="th1">{row.matCodes || "N/A"}</td>

                        <td className="th3">{row.est_unit_rate || "N/A"}</td>
                        <td className="th5">{row.mat_code_description || "N/A"}</td>
                        <td className="th4">{row.price || "N/A"}</td>
                        <td className="th2">{row.quantity || "N/A"}</td>
                        <td className="th4">{row.unit || "N/A"}</td>
                        {/* <td className="th2">
                          {row.quantity ? `${row.quantity} ${row.unit}` : "N/A"}
                        </td> */}

                        <td className="th2 inward">{row.inwarded_qty || "0"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default POView;
