
// import { createContext, useContext, useEffect, useState } from "react";

// const ThemeContext = createContext();

// export const useTheme = () => useContext(ThemeContext);

// export const ThemeProvider = ({ children }) => {
//   const [theme, setTheme] = useState("light");

//   // Function to determine the theme based on current time
//   const getThemeByTime = () => {
//     const hour = new Date().getHours(); // Get current hour (0 - 23)
//     if (hour >= 6 && hour < 18) {
//       return "light"; // Morning and day
//     } else {
//       return "dark"; // Evening and night
//     }
//   };

//   useEffect(() => {
//     const updateTheme = () => {
//       const newTheme = getThemeByTime();
//       setTheme(newTheme);
//       document.body.className = newTheme; // Apply theme class to body
//       console.log(`Theme updated to: ${newTheme}`);
//     };


//     updateTheme();

//     const intervalId = setInterval(updateTheme, 60000);

//     // Clean up the interval when the component unmounts
//     return () => clearInterval(intervalId);
//   }, []);

//   return (
//     <ThemeContext.Provider value={{ theme }}>
//       {children}
//     </ThemeContext.Provider>
//   );
// };
import React, { createContext, useState } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light';
      document.body.className = newTheme; // Update body class
      return newTheme;
    });
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
        