import React, { useState, useEffect } from "react";
import "./POConfigurationForm.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API_URL from "../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";

function OutwardView({ isSidebarExpanded }) {
    const navigate = useNavigate();
    const { id } = useParams();
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [outwardData, setOutwardData] = useState({ po_no: "" });
    const [rows, setRows] = useState([]);

    const fetchData = async () => {
        try {
            const authKey = localStorage.getItem("authKey");
            const response = await axios.get(`${API_URL}/api/get_product_outward_items/${id}`, {
                headers: {
                    Authorization: authKey,
                },
            });

            const { product_outward_items } = response.data;

            // Set data into the respective states
            setOutwardData({ po_no: response.data.po_no }); // Assuming po_no is in the response
            setSelectedVendor({
                label: response.data.vendor_name,
                value: response.data.vendor_id,
            });

            // Map items to rows for the table
            const rowsData = product_outward_items.map((item) => ({
                id: item.id,
                mat_code: item.mat_code,
                outwarded_qty: item.outwarded_qty,
                description: item.description,
            }));

            setRows(rowsData);

        } catch (error) {
            console.error("Error fetching PO data:", error.message);
        }
    };

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id]);

    const handleBackProject = () => {
        navigate("/outward-list");
    };

    return (
        <div
            className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
                }`}
        >
            <div className="data-form-content">
                <div className="data-form-header">
                    <div className="data-form-header-content">
                        <div className="data-form-heading-content-left-side">
                            <Tooltip title="Back" arrow>
                                <IoMdArrowBack
                                    className="back-company"
                                    onClick={handleBackProject}
                                />
                            </Tooltip>Outward List Items</div>
                    </div>
                </div>
                <div className="header-separator"></div>
                <div className="data-form-body">
                    {/* <div className="action-purchase-header">
                        <div className="project-detailss">
                            <div className="project-detail-arround">
                                <div className="project-details-left-side">
                                    <div className="project-detail">
                                        <div className="detail-header">MIN Number:</div>
                                        <div className="detail-part">{outwardData.po_no || "N/A"}</div>
                                    </div>
                                 
                                  
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="action-purchase-sheet-header">
                        <div className="actions-purchase-sheet-header-content">
                            <div className="actions-purchase-sheet-heading-content-left-side">
                                Item Table
                            </div>
                        </div>
                    </div>

                    <div className="action-purchase-body">
                        <div className="action-purchase-scroll-body">
                            <div className="action-purchase-table">
                                <table className="action-table action-multilevel-data ">
                                    <thead className="action-purchase-heads">
                                        <tr>
                                            <th className="th2">MAT CODE</th>
                                            <th className="th5">DESCRIPTION</th>
                                            {/* <th className="th4 quantity">AVAILABLE QTY</th> */}
                                            <th className="th3 total">OUTWARDED QTY </th>
                                        </tr>
                                    </thead>
                                    <tbody className="action-purchase-scroll-bodys">
                                        {rows.map((row, index) => (
                                            <tr key={index}>
                                                <td className="th1">{row.mat_code || "N/A"}</td>
                                                <td className="th5">{row.description || "N/A"}</td>
                                                {/* <td className="th4 quantity">{row.price || "N/A"}</td> */}
                                                <td className="th2 total">{row.outwarded_qty || "N/A"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OutwardView;
