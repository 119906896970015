import React, { useState, useEffect, useRef } from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { Link } from "react-router-dom";
import { MdAttachFile } from "react-icons/md";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Pagination from "../Component/Pagination";
import { MdDelete } from "react-icons/md";
import useClickOutside from '../useClickOutside';

function BreakdownSchedules({ isSidebarExpanded }) {
  const [breakdownSchedules, setBreakdownSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPermitPopupOpen, setIsPermitPopupOpen] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const searchTimeoutRef = useRef(null);
  const [sortOrders, setSortOrders] = useState({});

  // const handleSort = (column) => {
  //   setSortOrders((prevSortOrders) => ({
  //     ...prevSortOrders,
  //     [column]: !prevSortOrders[column],
  //   }));
  //   setIsSortPopup(false);
  //   if (column === sortColumn) {
  //     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  //   } else {
  //     setSortColumn(column);
  //     setSortOrder("asc");
  //   }
  // };

  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedBreakdown = [...breakdownSchedules].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setBreakdownSchedules(sortedBreakdown);
  };

  // const containerRef = useRef(null);
  // const buttonRef = useRef(null)
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const handleIconClick = (index) => {
    // setIsPopupOpen(inquiryID);
    setIsPopupOpen(index === isPopupOpen ? null : index);
    setSelectedRowIndex(false);
    setIsPermitPopupOpen(false);
  };
  const handlePermitIconClick = (index) => {
    // setIsPopupOpen(inquiryID);
    setIsPermitPopupOpen(index === isPermitPopupOpen ? null : index);
    setSelectedRowIndex(false);
    setIsPopupOpen(false);
  };
  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };
  // const handleViewAttachment = (attachment) => {
  //   window.open(attachment.preview_url, "_blank");
  // };

  // const handleViewAttachment = (attachment) => {
  //   window.open(
  //     `${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`,
  //     "_blank"
  //   );
  // };

  const handleViewAttachment = (attachment) => {
    const fileUrl = `${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`;

    // Check the file type to conditionally handle viewing
    const fileType = attachment.file_type.toLowerCase();

    // If the file type is a viewable type, open it in a new tab
    if (fileType === 'pdf' || fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png') {
      window.open(fileUrl, "_blank");
    } else {
      // For other file types, download instead of view
      window.location.href = fileUrl;
    }
  };


  const handleDeleteAttachment = (file_id) => {
    const authKey = localStorage.getItem("authKey");
    const deleteUrl = `${API_URL}/api/delete_breakdown_attachment/${file_id}`;

    // Confirm before deleting
    const isConfirmed = window.confirm("Are you sure you want to delete this attachment?");
    if (!isConfirmed) return;

    // Make the API call to delete the file with authorization header
    axios
      .delete(deleteUrl, {
        headers: {
          Authorization: authKey, // Assuming Bearer token auth
        },
      })
      .then((response) => {
        if (response.status === 200) {
          alert("File deleted successfully");
          // Update your state/UI here to remove the deleted file
        }
      })
      .catch((error) => {
        console.error("Error deleting the attachment:", error);
        if (error.response && error.response.status === 401) {
          alert("Unauthorized: Please check your authorization.");
        } else if (error.response && error.response.status === 404) {
          alert("Attachment not found.");
        } else {
          alert("Failed to delete the attachment. Please try again.");
        }
      });
  };



  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleClosePermitAttachment = () => {
    setIsPermitPopupOpen(false);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };


  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchInput.trim() !== "") {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      } else {
        setIsSearching(false);
        fetchData(page, itemsPerPage);
      }
    }, 600); // Adding delay for debouncing

    return () => clearTimeout(delay);
  }, [searchInput, page, itemsPerPage]);


  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_breakdown_schedules`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      setBreakdownSchedules(response.data.BreakdownSchedules);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
      setLoading(false);
    }
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      if (value.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        handleSearch(page, itemsPerPage);
      }
    }, 500);
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_breakdown_schedules`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setBreakdownSchedules(response.data.BreakdownSchedules);
        setTotalItems(response.data.TotalItems);
        setPage(response.data.CurrentPage);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error searching data:", error.message);
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll
        ? breakdownSchedules.map((breakdowSchedule) => breakdowSchedule.id)
        : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (scheduleID) => {
    const updatedSelectedRows = selectedRows.includes(scheduleID)
      ? selectedRows.filter((id) => id !== scheduleID)
      : [...selectedRows, scheduleID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === breakdownSchedules.length);
    setChecked(updatedSelectedRows.length > 0);
  };

  const handleArchiveschedule = async () => { };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     // Close popup if the click happens outside both the button and popup
  //     if (
  //       containerRef.current &&
  //       !containerRef.current.contains(event.target) &&
  //       buttonRef.current &&
  //       !buttonRef.current.contains(event.target)
  //     ) {
  //       setIsSortPopup(false);
  //     }
  //   };

  //   // Add click event listener to document
  //   document.addEventListener("mousedown", handleClickOutside);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const handleRefresh = () => {
    setSearchInput("");
    fetchData(page, itemsPerPage);
  };

  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };


  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveschedule}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Breakdown Schedules
                  </div>

                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search ..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Link to="/breakdown-form" className="data-list-new">
                      <Tooltip title="New break-down" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link>
                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup} ref={buttonRef}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("department_name")}>
                             Depaartment Name{" "}
                              {sortOrders.department_name ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button
                              onClick={() =>
                                handleSort("tag_no")
                              }
                            >
                              Tag No{" "}
                              {sortOrders.tag_no ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("status")}>
                              Status{" "}
                              {sortOrders.status ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("problem_desc")}>
                              Description{" "}
                              {sortOrders.problem_desc ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                          </div>
                          {/* <button className='delete-item' onClick={handleArchiveDeparment}>Archived Sub Department</button> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : breakdownSchedules.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>DEPARTMENT</th>
                          <th>TAG SECTION</th>
                          <th>STATUS</th>
                          <th>PROBLEM DESCRIPTION</th>
                          <th>BREAKDOWN ATTACHMENT</th>
                          <th>PERMIT ATTACHMENT</th>

                          {/* <th></th> */}
                          <th></th>
                        </tr>
                      </thead>

                      {breakdownSchedules.map((breakdownSchedule, index) => (
                        <tr key={index} className="hoverable-row">
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(breakdownSchedule.id)}
                              onChange={() => handleSelectRow(breakdownSchedule.id)}
                            />
                          </td>

                          <td>{index + 1}</td>
                          <td>{breakdownSchedule.department_name}</td>
                          <td>{breakdownSchedule.tag_no}</td>
                          <td>{breakdownSchedule.status || "NA"}</td>
                          <td>{breakdownSchedule.problem_desc}</td>

                          {/* Breakdown Attachments */}
                          <td>
                            <span className="inquiry-file-btns" onClick={() => handlePermitIconClick(index)}>
                              <MdAttachFile className="inquiry-file-icon" />
                            </span>
                            {isPermitPopupOpen === index && (
                              <div className="inquiry-attach-popups">
                                <div className="attach-popup-contant">
                                  <div className="attachment-header">
                                    <div className="attachment-header-content"> Breakdown Attachments</div>
                                    <button className="fiile-close-btn" onClick={handleClosePermitAttachment}>
                                      <IoCloseOutline className="file-close-icon" />
                                    </button>
                                  </div>
                                  <div className="attach-popup-contant-body">
                                    {breakdownSchedule.attachments.breakdown && breakdownSchedule.attachments.breakdown.length > 0 ? (
                                      <div className="attach-popup-content-body">
                                        {breakdownSchedule.attachments.breakdown.map((attachment, attachmentIndex) => (
                                          <div className="inquiry-attach-file-container" key={attachmentIndex}>
                                            <div className="inquiry-attacth-file-model">
                                              <div className="attach-file-show">
                                                <div className="text-attachment">
                                                  {attachment.filename.length > 20 ? `${attachment.filename.substring(0, 15)}...` : attachment.filename}
                                                </div>
                                                <div className="text-attachment-file">File Size: {attachment.file_size || "NA"} KB</div>
                                              </div>
                                              <div className="file-footer">
                                                <a
                                                  href={`${API_URL}/api/download_attachment/${attachment.file_id}/${attachment.file_type}`}
                                                  download
                                                  className="file-download-attachment file-txt"
                                                >
                                                  Download
                                                </a>
                                                <a
                                                  href={`${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="file-download-attachment file-txt"
                                                >
                                                  View
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    ) : (
                                      <div className="no-attachments">No breakdown attachments found</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>

                          {/* Permit Attachments */}
                          <td>
                            <span className="inquiry-file-btns" onClick={() => handleIconClick(index)}>
                              <MdAttachFile className="inquiry-file-icon" />
                            </span>
                            {isPopupOpen === index && (
                              <div className="inquiry-attach-popupss">
                                <div className="attach-popup-contant">
                                  <div className="attachment-header">
                                    <div className="attachment-header-content">Permit Attachments</div>
                                    <button className="fiile-close-btn" onClick={handleCloseAttachment}>
                                      <IoCloseOutline className="file-close-icon" />
                                    </button>
                                  </div>
                                  <div className="attach-popup-contant-body">
                                    {breakdownSchedule.attachments.permit && breakdownSchedule.attachments.permit.length > 0 ? (
                                      <div className="attach-popup-content-body">
                                        {breakdownSchedule.attachments.permit.map((attachment, attachmentIndex) => (
                                          <div className="inquiry-attach-file-container" key={attachmentIndex}>
                                            <div className="inquiry-attacth-file-model">
                                              <div className="attach-file-show">
                                                <div className="text-attachment">
                                                  {attachment.filename.length > 20 ? `${attachment.filename.substring(0, 15)}...` : attachment.filename}
                                                </div>
                                                <div className="text-attachment-file">File Size: {attachment.file_size || "NA"} KB</div>
                                              </div>
                                              <div className="file-footer">
                                                <a
                                                  href={`${API_URL}/api/download_attachment/${attachment.file_id}/${attachment.file_type}`}
                                                  download
                                                  className="file-download-attachment file-txt"
                                                >
                                                  Download
                                                </a>
                                                <a
                                                  href={`${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="file-download-attachment file-txt"
                                                >
                                                  View
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    ) : (
                                      <div className="no-attachments">No permit attachments found</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>

                          <td className="edit-pop">
                            <button className="edit-data-button" onClick={() => handleButtonClick(index)}>
                              <div className="edit-button">
                                <MdExpandMore />
                              </div>
                            </button>
                          </td>

                          {selectedRowIndex === index && (
                            <div className="edit-popup">
                              <div className="edit-popup-contant">
                                <div className="edit-popup-button">
                                  <Link to={`/edit-breakdown/${breakdownSchedule.id}`} className="inquiry-new">
                                    <button className="edit-button-inquiry">
                                      <MdOutlineModeEditOutline /> Edit
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </tr>
                      ))}

                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">No Schedules Found</div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BreakdownSchedules;
