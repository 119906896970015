import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { useNavigate, useParams } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import "./PrVendorDetails.css";
import { FiUpload } from "react-icons/fi";

function ConfirmPRRequest({ isSidebarExpanded }) {
  const { pr_id } = useParams();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [prRequestHistory, setPRRequestHistory] = useState([]);
  const [prRequestData, setPRRequestData] = useState({
    date: "",
    description: "",
    attachments: [],
  });

  const navigate = useNavigate();
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (e) => {
    setAttachments([...e.target.files]);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const fetchPRRequestHistory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}/api/get_vendor_pr_requests/${pr_id}`,
        {
          headers: { Authorization: localStorage.getItem("authKey") },
        }
      );
      setPRRequestHistory(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching vendor pr request history:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPRRequestHistory();
  }, [pr_id]);

  //   const handleSaveClick = async () => {
  //     // Validate the quotationData before submission
  //     if (!prRequestData.date) {
  //       setSnackbarMessage("Date is required");
  //       setSeverity("warning");
  //       setSnackbarOpen(true);
  //       return;
  //     }

  //     try {
  //       setIsLoading(true);
  //       const formData = new FormData();
  //       formData.append("pr_request_id", pr_id);
  //       formData.append("date", prRequestData.date);
  //       formData.append("description", prRequestData.description);

  //       // Append attachments
  //       attachments.forEach((file) => {
  //         formData.append("attachments", file); // Append each file
  //       });

  //       const response = await axios.post(
  //         `${API_URL}/api/add_vendor_pr_requests`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             Authorization: localStorage.getItem("authKey"),
  //           },
  //         }
  //       );

  //       if (response.status === 201) {
  //         setSnackbarMessage("Vendor PR Request added successfully");
  //         setSeverity("success");
  //         setSnackbarOpen(true);
  //         fetchPRRequestHistory();

  //         // Reset form fields after successful submission
  //         setPRRequestData({ date: "", description: "" });
  //         setAttachments([]); // Clear attachments
  //       }
  //     } catch (error) {
  //       console.error("Error saving vendor pr request history:", error);
  //       setSnackbarMessage("Error adding pr request history");
  //       setSeverity("error");
  //       setSnackbarOpen(true);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  const validateForm = () => {
    let tempErrors = {};
    if (!prRequestData.date) tempErrors.date = "Date is required.";
    if (!prRequestData.description)
      tempErrors.description = "Description is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // Returns true if no errors
  };

  // const handleSaveClick = async () => {
  //   // Validate the quotationData before submission
  //   if (!prRequestData.date) {
  //     setSnackbarMessage("Date is required");
  //     setSeverity("warning");
  //     setSnackbarOpen(true);
  //     return;
  //   }

  //   try {
  //     setIsLoading(true);
  //     const formData = new FormData();
  //     formData.append("pr_request_id", pr_id);
  //     formData.append("date", prRequestData.date);
  //     formData.append("description", prRequestData.description);

  //     // Append attachments
  //     if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
  //       attachmentFile.forEach((file) => {
  //         formData.append("attachments", file); // Directly append file to attachments
  //       });
  //     }

  //     const response = await axios.post(
  //       `${API_URL}/api/add_vendor_pr_requests`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: localStorage.getItem("authKey"),
  //         },
  //       }
  //     );

  //     if (response.status === 201) {
  //       setSnackbarMessage("Vendor PR Request added successfully");
  //       setSeverity("success");
  //       setSnackbarOpen(true);
  //       fetchPRRequestHistory();

  //       // Reset form fields after successful submission
  //       setPRRequestData({ date: "", description: "" });
  //       setAttachments([]); // Clear attachments
  //       setSelectedFiles([]);
  //     }
  //   } catch (error) {
  //     console.error("Error saving vendor pr request history:", error);
  //     setSnackbarMessage("Error adding pr request history");
  //     setSeverity("error");
  //     setSnackbarOpen(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async () => {

    if (!validateForm()) return;
    // Validate the quotationData before submission
   

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("pr_request_id", pr_id);
      formData.append("date", prRequestData.date);
      formData.append("description", prRequestData.description);

      // Append attachments
      if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
        attachmentFile.forEach((file) => {
          formData.append("attachments", file); // Directly append file to attachments
        });
      }

      const response = await axios.post(
        `${API_URL}/api/add_vendor_pr_requests`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("authKey"),
          },
        }
      );

      if (response.status === 201) {
        setSnackbarMessage("Vendor PR Request added successfully");
        setSeverity("success");
        setSnackbarOpen(true);
        fetchPRRequestHistory();

        // Reset form fields after successful submission
        setPRRequestData({ date: "", description: "" });
        setAttachments([]); // Clear attachments
        setSelectedFiles([]);
      }
    } catch (error) {
      console.error("Error saving vendor pr request history:", error);
      setSnackbarMessage("Error adding pr request history");
      setSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };

  const handleInputChange = (field, value) => {
    setPRRequestData((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" })); // Clear error for the field
  };

  return (
    <>
      <div className="data-form-content">
        <div className="data-form-bodys">
          <div className="pr-form">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="date">
                    Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="date"
                      name="date"
                      value={prRequestData.date}
                      // onChange={(e) =>
                      //   setPRRequestData({
                      //     ...prRequestData,
                      //     date: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleInputChange("date", e.target.value)
                      }
                      placeholder="Enter date"
                      className="your-custom-classname-date"
                    />
                  
                  </div>
                  {errors.date && (
                      <div className="error-message">{errors.date}</div>
                    )}
                </div>
                <div className="form-group">
                  <label htmlFor="description">
                    Description <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      name="Description"
                      id="Description"
                      cols="35"
                      rows="3"
                      placeholder="Enter description"
                      value={prRequestData.description}
                      // onChange={(e) =>
                      //   setPRRequestData({
                      //     ...prRequestData,
                      //     description: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                    ></textarea>
                  </div>
                  {errors.description && (
    <div className="error-message">{errors.description}</div>
  )}
                </div>
                <div className="form-group">
                  <label htmlFor="attachments">Attachments</label>
                  <div className="input-groups">
                    {/* <input
                      type="file"
                      multiple
                      name="attachments"
                      onChange={handleFileChange}
                    /> */}
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={handleUploadButtonClick}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      id="attachments"
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                      multiple
                    />
                    {selectedFiles.length > 0 && (
                      <div className="attchment-file-lists">
                        <ul
                          style={{
                            listStyleType: "none",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {selectedFiles.map((file, index) => (
                            <li key={index}>{file.name}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="history-button">
              <div className="button-group">
                <button
                  className="data-form-save-button"
                  type="button"
                  onClick={handleSaveClick}
                  disabled={isLoading}
                >
                  {isLoading ? "Saving..." : "Save"}
                </button>
                <button
                  className="data-form-cancel-button"
                  // onClick={() => navigate("/vendor")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {/* Quotation History Table */}
          <div className="quotation-history">
            <h2>PR Request History</h2>
            <div className="data-list-scroll-body">
              <div className="data-list-tabless">
                {loading ? (
                  <div className="loader-container">
                    {/* <div className="loader"></div>
                      <div>Loading...</div> */}
                  </div>
                ) : prRequestHistory.length > 0 ? (
                  <table className="tables multilevel-data">
                    <thead>
                      <tr>
                        <th>SR. NO.</th>
                        <th>DATE</th>
                        <th>DESCRIPTION</th>
                        <th>ATTACHMENTS</th>
                      </tr>
                    </thead>

                    <tbody>
                      {prRequestHistory.length > 0 &&
                        prRequestHistory.map((prRequest, index) => (
                          <tr key={prRequest.pr_request_id || index}>
                            {" "}
                            {/* Use a unique ID if available */}
                            <td>{index + 1}</td>
                            <td>{formatDate(prRequest.date)}</td>
                            <td>{prRequest.description || "NA"}</td>
                            <td>
                              {prRequest.attachments &&
                              prRequest.attachments.length > 0 ? (
                                prRequest.attachments.map((file, idx) => (
                                  <div
                                    key={file.file_id || idx}
                                    className="attachment-item"
                                  >
                                    {" "}
                                    {/* Use a unique ID if available */}
                                    <span>
                                      {file.filename.length > 15
                                        ? `${file.filename.substring(0, 15)}...`
                                        : file.filename}
                                      ({file.file_size} KB)
                                    </span>
                                    <a
                                      href={`${API_URL}/api/download_pr_attachment/${file.file_id}/${file.file_type}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download // Force download
                                    >
                                      <FiDownload className="download-icon" />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <span>No attachments available</span> // Keep this line if you want to indicate that there are no attachments
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No pr request history available
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ConfirmPRRequest;
