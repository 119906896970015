import React, { useState } from "react";
import "./UserForm.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";

function Manpowerrequirment({ isSidebarExpanded  }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [manPowerData, setManPowerData] = useState({
    project_id: id || "",
    position: "",
    profile: "", // This will be renamed to expected_profile
    no_of_position: "", // This will be renamed to no_of_positions
    status: "open", // Add status with a default value
  });
  
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setManPowerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    // navigate("/man-powers/");
    navigate(`/man-powers/${id}`);
  };

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Construct the payload
      const payload = {
        project_id: manPowerData.project_id,  // Ensure 'project_id' is correctly included
        position: manPowerData.position,
        expected_profile: manPowerData.profile, // Rename 'profile' to 'expected_profile'
        no_of_positions: manPowerData.noOfPosition, // Rename 'no_of_position' to 'no_of_positions'
        status: manPowerData.status,
      };

      const response = await axios.post(
        `${API_URL}/api/add_manpower_requirement`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Manpower requirement added successfully");
        setTimeout(() => {
          navigate(`/man-powers/${id}`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving manpower requirement:", error.message);
      setSeverity("error");
      showSnackbar("Failed to save manpower requirement");
    } finally {
      setIsLoading(false);
    }
  };

  


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Manpower Requirment
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="position">
                    Position<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="position"
                      value={manPowerData.position}
                      onChange={handleInputChange}
                      placeholder="Enter position"
                    />
                  </div>
                </div>
               
                <div className="form-group">
                  <label htmlFor="noOfPosition">
                   No. Of Position<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="noOfPosition"
                      value={manPowerData.noOfPosition}
                      onChange={handleInputChange}
                      placeholder="Enter number of position"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="profile">
                    Expected Profile<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="profile"
                      value={manPowerData.profile}
                      onChange={handleInputChange}
                      placeholder="Enter expected profile"
                    />
                  
                  </div>
                </div>
               
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Manpowerrequirment;
