import React, { useState, useEffect } from "react";
import "./UserForm.css";
import "./EquipmentTypeForm.css";
import Select, { components } from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { CiCirclePlus } from "react-icons/ci";
import AddDepartmentPopup from "../Popups/AddDepartmentPopup";
import AddSubDepartmentPopup from "../Popups/AddSubDepartmentPopup";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
const { Control, Menu } = components;

function EquipmentTypeForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [subDepartmentMenuIsOpen, setSubDepartmentMenuIsOpen] = useState(false);
  const [equipmentTypeData, setEquipmentTypeData] = useState({
    EquipmentType: "",
    DepartmentName: "",
    DepartmentId: "",
  });
  const [showSubDepartmentPopup, setShowSubDepartmentPopup] = useState(false);
  const [showDepartmentPopup, setShowDepartmentPopup] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEquipmentTypeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleCancelClick = () => {
    navigate("/equipment-type");
  };

  const handleSaveClick = async () => {
    const errors = {};
    let error = false;
    Object.keys(equipmentTypeData).forEach((key) => {
      if (!equipmentTypeData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        showSnackbar("Required fields are empty");
        error = true;
      }
    });

    if (error === false) {
      const updatedEquipmentData = {
        ...equipmentTypeData,
        SubDepartmentId: selectedSubDepartment?.value, // Include Sub Department ID
        SubDepartmentName: selectedSubDepartment?.label, // Include Sub Department Name
      };

      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");

        const response = await axios.post(
          `${API_URL}/api/add_equipment_type`,
          updatedEquipmentData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );

        console.log(response.data.message);
        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Equipment type added successfully");
          setTimeout(() => {
            navigate("/equipment-type");
          }, 1000);
        }
      } catch (error) {
        console.error("Error saving user:", error.message);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
    setSubDepartmentMenuIsOpen(false);
  };

  const handleSubDepartmentMenuOpen = () => {
    setSubDepartmentMenuIsOpen(true);
  };

  const CustomSubDepartmentControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomSubDepartmentMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewSubDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Sub Department
      </button>
    </Menu>
  );

  const CustomControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Department
      </button>
    </Menu>
  );
  const handleAddNewDepartment = () => {
    setShowDepartmentPopup(true);
    setDepartmentMenuIsOpen(false);
  };

  const handleAddNewSubDepartment = () => {
    setShowSubDepartmentPopup(true);
    setSubDepartmentMenuIsOpen(false);
  };
  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setEquipmentTypeData((prevFormData) => ({
      ...prevFormData,
      DepartmentName: selectedOption.label,
      DepartmentId: selectedOption.value,
    }));
    setSelectedSubDepartment(null);
  };

  const handleSubDepartmentChange = (selectedOption) => {
    setSelectedSubDepartment(selectedOption);
    setEquipmentTypeData((prevFormData) => ({
      ...prevFormData,
      SubDepartmentName: selectedOption.label,
      SubDepartmentId: selectedOption.value,
    }));
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const fetchSubDepartments = async () => {
    try {
      if (selectedDepartment) {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_sub_department/${selectedDepartment.value}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authKey,
          },
        });
        if (response.ok) {
          const data = await response.json();
          const options = data.map((subDepartment) => ({
            label: subDepartment.Name,
            value: subDepartment.id,
          }));
          setSubDepartments(options);
        } else {
          console.error("Failed to fetch sub-departments");
        }
      } else {
        setSubDepartments([]);
      }
    } catch (error) {
      console.error("An error occurred while fetching sub-departments:", error);
    }
  };

  useEffect(() => {
    if (selectedDepartment) {
      fetchSubDepartments();
      // Reset selectedPerson when selectedCompany changes
      setSelectedSubDepartment(null);
    } else {
      // If no company is selected, clear the contact persons and selectedPerson
      setSubDepartments([]);
      setSelectedSubDepartment(null);
    }
  }, [selectedDepartment]);

  const closeDepartmentPopup = (newDepartment) => {
    setShowDepartmentPopup(false);
    if (newDepartment) {
      setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
      setSelectedDepartment(newDepartment);
      setEquipmentTypeData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: newDepartment.label,
        DepartmentId: newDepartment.value,
      }));
    }
  };

  // const closeSubDepartmentPopup = () => {
  //   setShowSubDepartmentPopup(false);
  //   fetchSubDepartments();
  // };

  const closeSubDepartmentPopup = (newSubDepartment) => {
    setShowSubDepartmentPopup(false);
    fetchSubDepartments(); // Fetch sub-departments again to include the newly added one

    if (newSubDepartment) {
      // If a new sub-department is added
      const updatedSubDepartment = {
        label: newSubDepartment.SubDepartment, // Assuming the label of the new sub-department is 'SubDepartment'
        value: newSubDepartment.DepartmentId, // Assuming the value of the new sub-department is 'DepartmentId'
      };
      setSelectedSubDepartment(updatedSubDepartment); // Set the selected sub-department
    }
  };

  const handleNewSubDepartment = (newSubDepartment) => {
    setSubDepartments((prevSubDepartments) => [
      ...prevSubDepartments,
      newSubDepartment,
    ]);

    const updatedSubDepartment = {
      label: newSubDepartment.label,
      value: newSubDepartment.value,
    };

    setSelectedSubDepartment(updatedSubDepartment);
  };
  const handleNewDepartment = (newDepartment) => {
    if (newDepartment) {
      setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
      setSelectedDepartment(newDepartment);
      setEquipmentTypeData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: newDepartment.label,
        DepartmentId: newDepartment.value,
      }));
      showSnackbar("Department added successfully");
    }
  };
  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Equipement Type
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    //  onInputChange={handleInputChange}
                    components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    //  onKeyDown={handleKeyDown}
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubDepartment">
                    Sub Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="subDepartment"
                    options={subDepartments}
                    value={selectedSubDepartment}
                    onChange={handleSubDepartmentChange}
                    components={{
                      Control: CustomSubDepartmentControl,
                      Menu: CustomSubDepartmentMenu,
                    }}
                    placeholder="Select Sub Department"
                    isSearchable
                    menuIsOpen={subDepartmentMenuIsOpen}
                    onMenuOpen={handleSubDepartmentMenuOpen}
                    onMenuClose={() => setSubDepartmentMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="EquipmentType">
                    Equipment Type<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="EquipmentType"
                      value={equipmentTypeData.EquipmentType}
                      onChange={handleInputChange}
                      placeholder="Enter Equipment Type"
                    />
                  </div>
                </div>
              </div>
            </form>
            {showDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddDepartmentPopup
                  closePopup={closeDepartmentPopup}
                  setSelectedDepartment={setSelectedDepartment}
                  handleNewDepartment={handleNewDepartment}
                />
              </div>
            )}
            {showSubDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddSubDepartmentPopup
                  closePopup={closeSubDepartmentPopup}
                  selectedDepartment={selectedDepartment}
                  parentSelectedDepartment={selectedDepartment}
                  handleNewSubDepartment={handleNewSubDepartment}
                />
              </div>
            )}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EquipmentTypeForm;
