import React, { useState, useEffect, useRef } from "react";
import "./POConfigurationForm.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import { FiUpload } from "react-icons/fi";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";

function GeneratePO({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { id } = useParams();
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [vendorOptions, setVendorOptions] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [poNumber, setPoNumber] = useState(""); // Add state for PO number
  const [poData, setPoData] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
const [errors, setErrors] = useState({ poNumber: "", vendor: "", attachments: "" });



  const [isPopupOpen, setIsPopupOpen] = useState(false);
  
  const handleUploadButtonClick = (fileInputRef) => {
    fileInputRef.current.click();
  
    // Clear attachment error if present
    if (errors.attachments) {
      setErrors((prevErrors) => ({ ...prevErrors, attachments: "" }));
    }
  };
  
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleFileInputChange = (files, setFiles) => {
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };

  // const handlePoNumberChange = (e) => {
  //   setPoNumber(e.target.value); // Update the poNumber state inside this function
  // };
  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
  
    // Clear vendor error if present
    if (errors.vendor) {
      setErrors((prevErrors) => ({ ...prevErrors, vendor: "" }));
    }
  };
  
  const handlePoNumberChange = (e) => {
    setPoNumber(e.target.value);
  
    // Clear PO number error if present
    if (errors.poNumber) {
      setErrors((prevErrors) => ({ ...prevErrors, poNumber: "" }));
    }
  };
  
  const handleInputChange = (index, field, value) => {
    const updatedData = [...poData];
    updatedData[index][field] = value;
    setPoData(updatedData);
  };


  useEffect(() => {
    const fetchPOData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_po_pr_list_items/${id}`,
          {
            headers: {
              Authorization: authKey,
            }
          }
        );
  
        const responseData = response.data;
        console.log("Response data:", responseData);
  
        // Set vendor if present in the response
        if (responseData.vendor_name && vendorOptions.length > 0) {
          const selectedVendorOption = vendorOptions.find(
            (vendor) => vendor.label === responseData.vendor_name
          );
          setSelectedVendor(selectedVendorOption || null);
        }
  
        // Set PO data if available
        if (
          responseData.po_list_items &&
          Array.isArray(responseData.po_list_items)
        ) {
          setPoData(responseData.po_list_items);
        } else {
          setPoData([]); // Fallback to empty array
        }
      } catch (error) {
        console.error("Error fetching PO data:", error);
      }
    };

    fetchPOData();
  }, [id, vendorOptions]);



  const handleSaveClick = async () => {
    let validationErrors = {};
  
    // Validate inputs
    if (!poNumber.trim()) {
      validationErrors.poNumber = "PO number is required.";
    }
    if (!selectedVendor) {
      validationErrors.vendor = "Vendor selection is required.";
    }
    if (selectedFiles.length === 0) {
      validationErrors.attachments = "At least one attachment is required.";
    }
    
  
    let missingPrice = false;
    poData.forEach((row, index) => {
      if (!row.price || row.price <= 0) {
        missingPrice = true;
      }
    });

    if (missingPrice) {
      setSeverity("warning");
      showSnackbar("Price is required for all items.");
      return;
    }

    // If validation fails, set errors and stop execution
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Prepare FormData for the API request
      const formData = new FormData();
      formData.append("pr_request_id", id);
      formData.append("po_no", poNumber);
      formData.append("vendor_id", selectedVendor.value);
      formData.append("file_types", "po_file");
  
      // Add each item as a JSON string in the 'items' list
      poData.forEach((row) => {
        const item = {
          mat_code_id: row.mat_code_id || "",
          price: row.price || 0,
          est_unit_rate: row.est_unit_rate || 0,
          quantity: row.quantity || 0,
          unit: row.unit || "",
          description: row.description || "",
        };
        formData.append("items", JSON.stringify(item));
      });
  
      // Add attachments to FormData
      selectedFiles.forEach((file) => {
        if (file) {
          formData.append("attachments", file);
        }
      });
  
      console.log("Payload to be sent:", formData);
  
      // Send the POST request with FormData
      const response = await axios.post(
        `${API_URL}/api/add_po_list_bulk`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      // Handle the response
      if (response.status === 200) {
        console.log(response.data.message);
        // setSeverity("success");
        // showSnackbar("PO configurations added successfully");
        // navigate("/po-list");

        setSeverity("success");
         showSnackbar("PO generated successfully");
       setTimeout(() => navigate("/po-list"), 1000);
      }
    } catch (error) {
      console.error(
        "Error saving PO generate:",
        error.response ? error.response.data : error.message
      );
      setSeverity("error");
      showSnackbar("An error occurred while saving PO generate");
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const handleCancelClick = () => {
    navigate("/approved-pr-requests");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const fetchVendorData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_vendors`, {
        headers: {
          Authorization: authKey,
        },
      });

      const formattedVendors = response.data.Vendors.map((vendor) => ({
        label: vendor.vendor_name,
        value: vendor.id,
      }));

      setVendorOptions(formattedVendors);
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendorData();
  }, []);



  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New PO Generate
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="action-purchase-header">
              <div className="form-group">
                <label htmlFor="po_no">
                  PO Number<span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="po_no"
                    value={poNumber} // Bind the value to poNumber state
                    onChange={handlePoNumberChange} // Update poNumber state on input change
                    placeholder="Enter po number"
                  />
                </div>
                {errors.poNumber && <span className="error-message">{errors.poNumber}</span>}
              </div>
              <div className="form-group-select">
                <label htmlFor="DepartmentName">
                  Select Vendor<span className="text-danger">*</span>
                </label>
                <Select
                  id="EquipmentSubType"
                  options={vendorOptions}
                  placeholder="Select vendor"
                  isSearchable
                  value={selectedVendor} 
                  onChange={handleVendorChange}
                />
                 {errors.vendor && <span className="error-message">{errors.vendor}</span>}
              </div>

              <div className="form-group-attachments">
                <div className="form-group">
                  <label htmlFor="gst_attachments">
                    Attachment<span className="text-danger">*</span>
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={() => handleUploadButtonClick(fileInputRef)}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    type="file"
                    id="gst_attachments"
                    ref={fileInputRef}
                    onChange={(e) =>
                      handleFileInputChange(
                        e.target.files,
                        setSelectedFiles
                      )
                    }
                    style={{ display: "none" }}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-inward">
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                  // onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                   {errors.attachments && <span className="error-message">{errors.attachments}</span>}
                </div>
              </div>

              {/* <div className="form-group">
                <label htmlFor="attachment">Attachment<span className="text-danger">*</span></label>
                <input/>
               
              </div> */}
            </div>
            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Item Table
                </div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-table">
                  <table className="action-table action-multilevel-data">
                    <thead className="action-purchase-heads">
                      <tr>
                        <th className="th2">MAT CODE</th>
                        <th className="th5">DESCRIPTION</th>
                        <th className="th3">QUANTITY</th>
                        <th className="th4">ESTIMATED UNIT RATE</th>
                      
                        <th className="th4">PRICE <span className="text-danger">*</span></th>
                      </tr>
                    </thead>
                    <tbody className="action-purchase-scroll-bodys">
                      {poData.length > 0 ? (
                        poData.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <span>{row.mat_code || "N/A"}</span>
                            </td>
                            <td>
                              <span>{row.description || "N/A"}</span>
                            </td>
                            <td>
                              <span>{row.quantity || "N/A"}</span>
                            </td>
                            <td>
                              <span>{row.est_unit_rate || "N/A"}</span>
                            </td>
                           
                            <td className="th3">
                              <input
                                type="number"
                                placeholder="Enter Price"
                                value={row.price}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ top: "75px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default GeneratePO;
