import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { FaPlusCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

function EditContractor({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [contractorCompanyData, setContractorCompanyData] = useState({
    contractor_name: "",
    address: "",
    contact_no: "",
    locations: [
      {
        location: "",
        contact_person_name: "",
        location_contact_no: "",
        location_email: "",
        location_address: "",
      },
    ],
    
  });
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchContractorData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_contractor_company/${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const responseData = response.data;
        setContractorCompanyData({
          contractor_name: responseData.contractor_name,
          locations: responseData.locations,
          address: responseData.address,
          contact_no: responseData.contact_no,
        });
        setSelectedStartDate(new Date(responseData.start_date));
        setSelectedEndDate(new Date(responseData.end_date));
      } catch (error) {
        console.error("Error fetching contractor data:", error.message);
        setError("An error occurred while fetching contractor data");
      }
    };

    fetchContractorData();
  }, [id]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleInputChange = (e, index = null, field = null) => {
    const { name, value } = e.target;
    if (index !== null && field) {
      const updatedLocations = [...contractorCompanyData.locations];
      updatedLocations[index] = {
        ...updatedLocations[index],
        [field]: value,
      };
      setContractorCompanyData({
        ...contractorCompanyData,
        locations: updatedLocations,
      });
    } else {
      setContractorCompanyData({ ...contractorCompanyData, [name]: value });
    }
  };

  const handleCancelClick = () => {
    // navigate("/contractor-company");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     // Filter out empty locations
  //     const filteredLocations = contractorCompanyData.locations.filter(
  //       (location) =>
  //         (location.location && location.location.trim() !== "") ||
  //         (location.contact_person_name &&
  //           location.contact_person_name.trim() !== "") ||
  //         (location.contact_no && location.contact_no.trim() !== "") ||
  //         (location.email && location.email.trim() !== "") ||
  //         (location.address && location.address.trim() !== "")
  //     );

  //     const payload = {
  //       ...contractorCompanyData,
  //       locations: filteredLocations,
  //       start_date: selectedStartDate
  //         ? format(new Date(selectedStartDate), "yyyy-MM-dd")
  //         : "",
  //       end_date: selectedEndDate
  //         ? format(new Date(selectedEndDate), "yyyy-MM-dd")
  //         : "",
  //     };

  //     const response = await axios.put(
  //       `${API_URL}/api/update_contractor_company/${id}`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Data updated successfully");
  //       setTimeout(() => {
  //         navigate("/contractor-company");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error updating contractor:", error.message);
  //     setSeverity("error");
  //     showSnackbar("Error updating contractor");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async () => {
    const { contractor_name } = contractorCompanyData;
  
    // Check for empty required fields
    if (!contractor_name || !selectedStartDate || !selectedEndDate) {
      setSeverity("error");
      showSnackbar("Company name, Start date, and End date are required");
      return;
    }
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Filter out empty locations
      const filteredLocations = contractorCompanyData.locations.filter(
        (location) =>
          (location.location && location.location.trim() !== "") ||
          (location.contact_person_name &&
            location.contact_person_name.trim() !== "") ||
          (location.location_contact_no && location.location_contact_no.trim() !== "") ||
          (location.location_email && location.location_email.trim() !== "") ||
          (location.location_address && location.location_address.trim() !== "")
      );
  
      const payload = {
        ...contractorCompanyData,
        locations: filteredLocations,
        start_date: format(new Date(selectedStartDate), "yyyy-MM-dd"),
        end_date: format(new Date(selectedEndDate), "yyyy-MM-dd"),
      };
  
      const response = await axios.put(
        `${API_URL}/api/update_contractor_company/${id}`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate("/contractor-company");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating contractor:", error.message);
      setSeverity("error");
      showSnackbar("Error updating contractor");
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  

  const handleStartDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedStartDate(date);
      setContractorCompanyData((prevFormData) => ({
        ...prevFormData,
        start_date: formattedDate,
      }));
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedEndDate(date);
      setContractorCompanyData((prevFormData) => ({
        ...prevFormData,
        end_date: formattedDate,
      }));
    }
  };
  const handleRemoveLocation = (index) => {
    const updatedLocations = [...contractorCompanyData.locations];
    updatedLocations.splice(index, 1);
    setContractorCompanyData({
      ...contractorCompanyData,
      locations: updatedLocations,
    });
  };
  const handleAddLocation = () => {
    setContractorCompanyData({
      ...contractorCompanyData,
      locations: [
        ...contractorCompanyData.locations,
        {
          location: "",
          contact_person_name: "",
          location_contact_no: "",
          location_email: "",
          location_address: "",
        },
      ],
    });
  };
  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={() => navigate("/contractor-company")}
                  />
                </Tooltip>
                Edit Contractor Company
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
                <div className="form-group">
                  <label htmlFor="contractor_name">
                    Company Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="contractor_name"
                      value={contractorCompanyData.contractor_name}
                      onChange={handleInputChange}
                      placeholder="Enter company name"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="start_date">
                    Contract Start Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedStartDate}
                      onChange={handleStartDateChange}
                      name="start_date"
                      id="start_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a start date"
                      className="datesss"
                      isClearable={false}
                      showYearDropdown
                      showMonthDropdown
                      todayButton="Today"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="end_date">
                    Contract End Date <span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedEndDate}
                      onChange={handleEndDateChange}
                      name="end_date"
                      id="end_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select an end date"
                      className="datesss"
                      isClearable={false}
                      showYearDropdown
                      showMonthDropdown
                      todayButton="Today"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="address">
                    Address
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="address"
                      value={contractorCompanyData.address}
                      onChange={handleInputChange}
                      placeholder="Enter address"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Locations
                  </label>
                  <div className="new-frequency-rowss">
                    {contractorCompanyData.locations.map((location, index) => (
                      <div key={index} className="location-group-content">
                        <div className="location-group">
                          <div className="input-group frequency-row">
                            <textarea
                              
                              className="point-fr"
                              name={`location_${index}`}
                              value={location.location}
                              onChange={(e) =>
                                handleInputChange(e, index, "location")
                              }
                              placeholder={`Enter location ${index + 1}`}
                               cols="30"
                              rows="1"
                            />
                          </div>
                          <div className="flex-group">
                          <div>
                          <div className="input-group frequency-row">
                            <input
                              type="text"
                              className="point-fr"
                              name={`ContactPersonName_${index}`}
                              value={location.contact_person_name}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  index,
                                  "contact_person_name"
                                )
                              }
                              placeholder={`Enter contact person name`}
                            />
                          </div>
                          <div className="input-group frequency-row">
                            <input
                              type="text"
                              className="point-fr"
                              name={`LocationContactNo_${index}`}
                              value={location.location_contact_no}
                              onChange={(e) =>
                                handleInputChange(e, index, "location_contact_no")
                              }
                              placeholder={`Enter location contact number`}
                            />
                          </div>
                          <div className="input-group frequency-row">
                            <input
                              type="text"
                              className="point-fr"
                              name={`LocationEmail_${index}`}
                              value={location.location_email}
                              onChange={(e) =>
                                handleInputChange(e, index, "location_email")
                              }
                              placeholder={`Enter location email`}
                            />
                          </div>
                          </div>
                          <div>
                          <div className="input-group frequency-row">
                            <textarea
                              
                              className="point-fr"
                              name={`LocationAddress_${index}`}
                              value={location.location_address}
                              onChange={(e) =>
                                handleInputChange(e, index, "location_address")
                              }
                              placeholder={`Enter location address`}
                               cols="30"
                                  rows="6.9"
                            />
                          </div>
                          </div>
                          </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="add-close-button"
                            onClick={() => handleRemoveLocation(index)}
                            disabled={
                              contractorCompanyData.locations.length === 1
                            }
                          >
                            <IoClose className="frequency-close-icon" />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="add-new-frequency-customer">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddLocation}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditContractor;
