import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
function MatCodeLevelTwoUpdateForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [levelOneData, setLevelOneData] = useState([]);
  const [isDescriptionDuplicate, setIsDescriptionDuplicate] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { l2_id } = useParams(); // Get the ID of the Level 2 to update from the URL

  const [levelTwoData, setLevelTwoData] = useState({
    levelTwo: "",
    Description: "",
    levelOneId: "",
  });

  const [levelOptions, setLevelOptions] = useState(
    Array.from({ length: 100 }, (_, i) => ({
      value: i.toString().padStart(2, "0"),
      label: i.toString().padStart(2, "0"),
    }))
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };
  const {theme} = useContext(ThemeContext)

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLevelTwoData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
    if (name === "Description" && value.trim()) {
      checkDescription(value.trim());
    }
  };


  const checkDescription = async (description) => {
    try {
      const authKey = localStorage.getItem("authKey");

      // Make the API call
      await axios.post(
        `${API_URL}/api/l2/check_mat_code_description`,
        { description },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      setIsDescriptionDuplicate(false);
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message === "Description already exists"
      ) {
        setIsDescriptionDuplicate(true);
        setErrors((prevState) => ({
          ...prevState,
          Description: "Description already exists",
        }));
      } else {
        // Handle other errors
        console.error("Error checking description:", error.message);
        setSeverity("error");
        showSnackbar("An error occurred while checking the description");
      }
    }
  };


  const handleLevelOneChange = (selectedOption) => {
    setLevelTwoData((prevState) => ({
      ...prevState,
      levelOneId: selectedOption ? selectedOption.value : "",
      level_one_description: selectedOption ? selectedOption.description : "",
    }));
  };

  const handleLevelTwoChange = (selectedOption) => {
    setLevelTwoData((prevState) => ({
      ...prevState,
      levelTwo: selectedOption ? selectedOption.value : "",
    }));
    setErrors((prevState) => ({
      ...prevState,
      levelTwo: "",
    }));
  };

  const handleCancelClick = () => {
    navigate("/matcode-levels?tab=level-two");
  };

  const fetchLevelOneData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_mat_code_level_ones`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const options = response.data.map((level) => ({
        // label: level.level,
        label: `${level.level} (${level.description})`,
        value: level.id,
        description: level.description,
      }));

      setLevelOneData(options);
    } catch (error) {
      console.error("Error fetching level 1 data:", error.message);
    }
  };

  const fetchLevelTwoData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_mat_code_level_two/${l2_id}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const data = response.data;
      setLevelTwoData({
        levelTwo: data.level,
        Description: data.description,
        levelOneId: data.level_one_id,
        level_one_description: data.level_one_description,
      });
    } catch (error) {
      console.error("Error fetching level 2 data:", error.message);
    }
  };

  useEffect(() => {
    fetchLevelOneData();
    fetchLevelTwoData();
  }, []);

  const handleSaveClick = async () => {
    const errors = {};
    let error = false;
    Object.keys(levelTwoData).forEach((key) => {
      if (!levelTwoData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        error = true;
      }
    });

    if (isDescriptionDuplicate) {
      if (isDescriptionDuplicate) {
        errors.Description = "Description already exists";
      }
      setErrors(errors);
      return;
    }

    
    if (error) {
      showSnackbar("Required fields are empty");
      setErrors(errors);
      return;
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.put(
        `${API_URL}/api/update_mat_code_level_two/${l2_id}`,
        {
          level: levelTwoData.levelTwo,
          description: levelTwoData.Description,
          level_one_id: levelTwoData.levelOneId,
        },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Level 2 updated successfully");
        setTimeout(() => {
          navigate("/matcode-levels?tab=level-two");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating level 2:", error.message);
      setSeverity("error");
      showSnackbar("Failed to update level 2");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                Edit Level 2
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="LevelOne">
                    Level 1<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelOneData}
                    value={levelOneData.find(
                      (option) => option.value === levelTwoData.levelOneId
                    )}
                    filterOption={(option, inputValue) =>
                      option.label.toLowerCase().includes(inputValue.toLowerCase())
                    } 
                    onChange={handleLevelOneChange}
                    placeholder="Select Level 1"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.levelOneId && (
                    <div className="error-message">{errors.levelOneId}</div>
                  )}
                </div>
              
                <div className="form-group-select">
                  <label htmlFor="level">
                    Level 2<span className="text-danger">*</span>
                  </label>

                  <Select
                    name="levelTwo"
                    value={levelOptions.find(
                      (option) => option.value === levelTwoData.levelTwo
                    )}
                    onChange={handleLevelTwoChange}
                    options={levelOptions}
                    placeholder="Select Level 2"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.levelTwo && (
                    <div className="error-message">{errors.levelTwo}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentCode">
                    Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Description"
                      value={levelTwoData.Description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                      className={errors.Description ? "input-error" : ""}
                      />
                      {errors.Description && (
                        <div className="error-message">{errors.Description}</div>
                      )}
                    </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Update"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default MatCodeLevelTwoUpdateForm;
