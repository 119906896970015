import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";

function EditManpower({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projectId, setProjectId] = useState("");
  const [manPowerData, setManPowerData] = useState({
    project_id: id || "",
    position: "",
    expected_profile: "", 
    no_of_positions: "",
    status: "open",
  });

  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchManpoerData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_manpower_requirement/${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const responseData = response.data;

        // Ensure the response data is correctly mapped to your state
        const formattedData = {
          project_id: responseData.project_id || id,
          position: responseData.position || "",
          expected_profile: responseData.expected_profile || "",
          no_of_positions: responseData.no_of_positions || "",
          status: responseData.status || "open",
        };
        setProjectId(responseData.project_id || "");
        setManPowerData(formattedData);
      } catch (error) {
        console.error("Error fetching department data:", error.message);
        setError("An error occurred while fetching department data");
      }
    };

    fetchManpoerData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setManPowerData({ ...manPowerData, [name]: value });
  };

  const handleCancelClick = () => {
    navigate(`/man-powers/${projectId}`);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    const errors = {};
    let error = false;
    Object.keys(manPowerData).forEach((key) => {
      if (!manPowerData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        showSnackbar("Required fields are empty");
        error = true;
      }
    });

    if (error === false) {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");
        const response = await axios.put(
          `${API_URL}/api/update_manpower_requirement/${id}`,
          manPowerData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        console.log(response.data.message);
        if (response.status === 200) {
            setSeverity("success")
          showSnackbar("Data updated successfully");
          setTimeout(() => {
            navigate(`/man-powers/${projectId}`);
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating company:", error.message);
        setError("An error occurred while updating company data");
        setIsLoading(false);
      }
    }
  };
  
  const handleBackProject = () => {
    navigate(`/man-powers/${projectId}`);
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Edit Manpower
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="position">
                    Position<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="position"
                      value={manPowerData.position}
                      onChange={handleInputChange}
                      placeholder="Enter position"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="no_of_positions">
                    No. Of Position<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="no_of_positions"
                      value={manPowerData.no_of_positions}
                      onChange={handleInputChange}
                      placeholder="Enter number of positions"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="expected_profile">
                    Expected Profile<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="expected_profile"
                      value={manPowerData.expected_profile}
                      onChange={handleInputChange}
                      placeholder="Enter expected profile"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditManpower;
