import React, { useState, useEffect, useRef } from "react";
// import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import { FiUpload } from "react-icons/fi";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import '../TableColor.css';
import useClickOutside from '../useClickOutside';
import { MdDeleteOutline } from 'react-icons/md'; // Add this line at the top
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
function InwardForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const containerRef = useRef(null)
  const buttonRef = useRef(null)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPO, setSelectedPO] = useState(null);
  const [poOptions, setPoOptions] = useState([]);
  const [poDetails, setPoDetails] = useState(null);
  const [inwardData, setInwardData] = useState({
    invoice_no: "",
    invoice_attachment: null,
    inwardQuantities: {}, // Store inward quantities by item ID
  });

  const handleUploadButtonClick = (fileInputRef) => {
    fileInputRef.current.click();

    if (errors.attachments) {
      setErrors((prevErrors) => ({ ...prevErrors, attachments: "" }));
    }
  };
  const handleFileInputChange = (files, setFiles) => {
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };
  useClickOutside([containerRef, buttonRef, fileInputRef], () => {
    // setIsSortPopupOpen(false);
    setIsPopupOpen(false);
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInwardData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };
  const {theme} = useContext(ThemeContext)
  const handleCancelClick = () => {
    navigate("/po-inbound-list");
  };

  const handleDeleteFile = (indexToDelete) => {
    console.log('Before delete:', selectedFiles); // Debugging
  
    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter((_, index) => index !== indexToDelete);
    
    // Update the state with filtered files
    setSelectedFiles(updatedFiles);
  
    console.log('After delete:', updatedFiles); // Debugging
  
    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };
  const handleSaveClick = async () => {
    let validationErrors = {};

    // Ensure selectedPO exists and has a value
    if (!selectedPO || !selectedPO.value) {
      validationErrors.po_no = "PO number is required.";
    }

    // Validate invoice_no
    if (!inwardData.invoice_no || inwardData.invoice_no.trim() === "") {
      validationErrors.invoice_no = "Invoice number is required.";
    }

    // Validate attachments
    if (selectedFiles.length === 0) {
      validationErrors.attachments = "At least one attachment is required.";
    }

    // If validation fails, set errors and stop execution
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Prepare FormData
      const formData = new FormData();
      formData.append("po_id", selectedPO.value);
      formData.append("vendor_id", selectedPO?.vendor_id);
      formData.append("invoice_no", inwardData.invoice_no.trim());

      formData.append(
        "items",
        JSON.stringify(
          poDetails?.po_list_items.map((item) => ({
            mat_code_id: item.mat_code_id,
            price: item.price,
            qty: item.quantity,
            unit: item.unit,
            est_unit_rate: item.est_unit_rate,
            inbounded_qty:
              inwardData.inwardQuantities[item.id] || item.quantity - item.inwarded_qty,
            description: item.description || "",
          }))
        )
      );

      formData.append("file_types", "inbound_po");

      selectedFiles.forEach((file) => formData.append("attachments", file));

      console.log("FormData entries:");
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      const response = await axios.post(
        `${API_URL}/api/inbound_purchase_orders`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Purchase orders inbound successfully.");
        setTimeout(() => navigate("/po-inbound-list"), 1000);
      }
    } catch (error) {
      console.error("Error saving inbound purchase orders:", error);
      setSeverity("error");
      showSnackbar("Failed to save inbound purchase orders.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_purchase_order_list`, {
        headers: { Authorization: authKey },
      });

      // Check if the response has the expected data structure
      const options = response.data.POLists.map((item) => ({
        value: item.id,
        label: item.po_no,
        vendor_id: item.vendor_id,
      }));

      setPoOptions(options);
    } catch (error) {
      console.error("Error fetching PO data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedPO) {
      const fetchPoDetails = async () => {
        try {
          const authKey = localStorage.getItem("authKey");
          const response = await axios.get(
            `${API_URL}/api/get_po_list_items/${selectedPO.value}`,
            {
              headers: { Authorization: authKey },
            }
          );

          setPoDetails(response.data);
        } catch (error) {
          console.error("Error fetching PO details:", error.message);
        }
      };

      fetchPoDetails();
    }
  }, [selectedPO]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handlePOChange = (selectedOption) => {
    setSelectedPO(selectedOption);
    if (errors.po_no) {
      setErrors((prevErrors) => ({ ...prevErrors, po_no: "" }));
    }
  };
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleInwardQuantityChange = (itemId, value, poQty, inwardedQty) => {
    const parsedValue = Number(value);
    const totalQuantity = poQty - inwardedQty
    // Check if the value exceeds total quantity
    if (parsedValue > totalQuantity) {
      setInwardData((prevState) => ({
        ...prevState,
        inwardQuantities: {
          ...prevState.inwardQuantities,
          [itemId]: totalQuantity,
        },
      }));
    } else {
      setInwardData((prevState) => ({
        ...prevState,
        inwardQuantities: {
          ...prevState.inwardQuantities,
          [itemId]: value === "" ? null : parsedValue,
        },
      }));
    }
  };

  const handleIconClick = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-form-content" ref={containerRef}>
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Inward
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="action-purchase-header">
              <div className="form-group-select">
                <label htmlFor="po_no">
                  PO Number<span className="text-danger">*</span>
                </label>

                <Select
                  id="POSelect"
                  options={poOptions}
                  placeholder="Select PO Number"
                  isSearchable
                  onChange={handlePOChange}
                  value={selectedPO}
                  styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Background color for dropdown menu based on theme
                        zIndex: 1000, // Add high z-index for dropdown menu
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light blue when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                />
                {errors.po_no && (
                  <span className="error-message">{errors.po_no}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="invoice_no">
                  Invoice No<span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="invoice_no"
                    value={inwardData.invoice_no}
                    onChange={handleInputChange}
                    placeholder="Enter invoice no"
                  />
                </div>
                {errors.invoice_no && (
                  <span className="error-message">{errors.invoice_no}</span>
                )}
              </div>

              <div className="form-group-attachments">
                <div className="form-group">
                  <label htmlFor="gst_attachments">
                    Attachment<span className="text-danger">*</span>
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={() => handleUploadButtonClick(fileInputRef)}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    type="file"
                    id="gst_attachments"
                    ref={fileInputRef}
                    onChange={(e) =>
                      handleFileInputChange(e.target.files, setSelectedFiles)
                    }
                    style={{ display: "none" }}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                      ref={buttonRef}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-inward" ref={containerRef}>
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div className="attach-popup-contant-body" key={index}>
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">{file.name}</div>
                                    <div className="text-attachment-file">
                                      File Size: {(file.size / 1024).toFixed(2)} KB
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>

                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {errors.attachments && (
                    <span className="error-message">{errors.attachments}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Inward Item Table
                </div>
                <div className="actions-purchase-heading-content-right-side">
                  {/* <Link to="/purchaseform" className='purchase-new'>
              <button className='new-button'><HiOutlinePlus className="new-plus-icon" /> New</button>
              </Link> */}
                </div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-table">
                  <>
                    {poDetails ? (
                      <table className="action-table action-multilevel-data">
                        <thead className="action-purchase-heads">
                          <tr>
                            <th className="th2">VENDOR</th>
                            <th className="th2">MAT CODE</th>

                            <th className="th4">ESTIMATED UNIT RATE</th>
                            <th className="th4">DESCRIPTION</th>
                            <th className="th4">PRICE</th>
                            <th className="th3 total">TOTAL PO QTY</th>
                            <th className="th4 quantity">RECEIVED QTY</th>
                            <th className="th4 balance">BALANCE QTY</th>
                            <th className="th4">INWARD QTY<span className="text-danger">*</span></th>
                          </tr>
                        </thead>

                        <tbody className="action-purchase-scroll-bodys">
                          {poDetails.po_list_items.map((item) => (
                            <tr key={item.id}>
                              <td>{poDetails.po_list.vendor_name || "NA"}</td>
                              <td>{item.mat_code || "NA"}</td>

                              <td>{item.est_unit_rate || "NA"}</td>
                              <td>{item.description || "NA"}</td>
                              <td>{item.price}</td>
                              <td className="total">{item.quantity || "NA"}</td>
                              <td className="quantity">{item.inwarded_qty || "0"}</td>
                              <td className="balance">{item.balance_qty}</td>

                              <td className="th3">
                                <input
                                  type="number"
                                  value={
                                    inwardData.inwardQuantities[item.id] !== undefined
                                      ? inwardData.inwardQuantities[item.id]
                                      : item.quantity - item.inwarded_qty
                                  }
                                  onChange={(e) =>
                                    handleInwardQuantityChange(
                                      item.id,
                                      e.target.value,
                                      item.quantity,
                                      item.inwarded_qty
                                    )
                                  }
                                  placeholder="Enter inward quantity"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="no-users-container">
                        <div className="no-users-message">
                          Please select PO number
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="action-caluculation">
                        <div className="new-rows"></div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="button-group">
                <button
                  className="data-form-save-button"
                  type="save"
                  onClick={handleSaveClick}
                  disabled={isLoading}
                >
                  {isLoading ? "Saving..." : "Save"}
                </button>
                <button
                  className="data-form-cancel-button"
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ top: "75px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default InwardForm;
