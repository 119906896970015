import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PreventiveReports from "../Reports/PreventiveReports";
import AmcReports from "../Reports/AmcReports";
import BreakdownReports from "../Reports/BreakdownReports";
import ProcurmentReports from "../Reports/ProcurmentReports";
import { Tabs, Tab } from "@mui/material";

function ReportPage({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Extracting tab from URL query
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(
    tabFromUrl || "preventive-schedule-reports" 
  );

  useEffect(() => {
    // Update tab value if URL param changes
    if (tabFromUrl && tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl);
    }
  }, [location.search]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Update URL query parameter to reflect the current tab
    navigate(`?tab=${newValue}`);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => handleTabChange(event, newValue)}
              aria-label="report tabs"
              className="tabs-container"
            >
              <Tab label="Preventive reports" value="preventive-schedule-reports" />
              <Tab label="AMC reports" value="amc-reports" />
              <Tab label="Breakdown reports" value="breakdown-reports" />
              <Tab label="Procurement reports" value="procurement-reports" />
            </Tabs>
            <div className="header-separater"></div>
            <div className="tabs-content">
              {tabValue === "preventive-schedule-reports" && <PreventiveReports />}
              {tabValue === "amc-reports" && <AmcReports />}
              {tabValue === "breakdown-reports" && <BreakdownReports />}
              {tabValue === "procurement-reports" && <ProcurmentReports />}
            </div>
          </div>
        )}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ReportPage;
