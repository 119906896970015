import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";

function EditDepartment({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [departmentData, setDepartmentData] = useState({
    DepartmentName: "",
  });

  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_department/${ID}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const responseData = response.data[0];
        const formattedData = {
          DepartmentName: responseData.DepartmentName,
        };
        setDepartmentData(formattedData);
      } catch (error) {
        console.error("Error fetching department data:", error.message);
        setError("An error occurred while fetching department data");
      }
    };

    fetchDepartmentData();
  }, [ID]);


  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDepartmentData({ ...departmentData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    // navigate("/department");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    const errors = {};
    let error = false;
    Object.keys(departmentData).forEach((key) => {
      if (!departmentData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        showSnackbar("Required fields are empty");
        error = true;
      }
    });

    if (error === false) {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");
        const response = await axios.put(
          `${API_URL}/api/update_department/${ID}`,
          departmentData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        console.log(response.data.message);
        if (response.status === 200) {
            setSeverity("success")
          showSnackbar("Data updated successfully");
          setTimeout(() => {
            navigate("/department");
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating company:", error.message);
        setError("An error occurred while updating company data");
        setIsLoading(false);
      }
    }
  };
  const handleBackProject = () => {
    navigate("/department");
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Department
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
                <div className="form-group">
                  <label htmlFor="DepartmentName">
                    Department<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="DepartmentName"
                      value={departmentData.DepartmentName}
                      onChange={handleInputChange}
                      placeholder="Enter department"
                    />
                  </div>
                </div>
             
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditDepartment;
