import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
import "./EditUserForm.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import Select, { components } from "react-select";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import API_URL from "../../src/Config";
const { Control, Menu } = components;

function EditUserForm({ isSidebarExpanded, onclose }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [designations, setDesignations] = useState([]);
  const [designationMenuIsOpen, setDesignationMenuIsOpen] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [designationError, setDesignationError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [projects, setProjects] = useState(null);
  const [selectedSalutation, setSelectedSalutation] = useState("Mr.");
  const [showDepartmentPopup, setShowDepartmentPopup] = useState(false);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [userType, setUserType] = useState([]);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordError, setPasswordError] = useState(""); // New state for password error
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [userData, setUserData] = useState({
    Title: "",
    FirstName: "",
    LastName: "",
    Designation: "",
    Email: "",
    ContactNo: "",
    department_id: "",
    department_name: "",
    role: "",
  });
  const { theme } = useContext(ThemeContext);
  const handleCancelClick = () => {
    window.history.back();
  };

  const fetchUserTypes = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_user_types`, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.status === 200) {
        const data = response.data.map((userType) => ({
          value: userType.id,
          label: userType.type_name,
        }));
        setUserType(data);
      } else {
        console.error("Failed to fetch user type data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user type data:", error.message);
    }
  };
  useEffect(() => {
    fetchUserTypes();
  }, []);

  const handleUserTypeChange = (selectedOption) => {
    setSelectedUserType(selectedOption);
    setUserData((prevUserData) => ({
      ...prevUserData,
      role: selectedOption ? selectedOption.label : "",
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleProjectChange = (selectedOption) => {
    setSelectedProject(selectedOption);
  };

  const fetchProjects = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_all_projects`, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.data) {
        const projectOptions = response.data.Projects.map((project) => ({
          value: project.id,
          label: project.project_name,
        }));
        setProjects(projectOptions);
      } else {
        setProjects([]); // Set to an empty array if response.data is null or undefined
      }
    } catch (error) {
      console.error("Error fetching project types data:", error.message);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");

        const response = await axios.get(`${API_URL}/api/get_user/${ID}`, {
          headers: {
            Authorization: authKey,
          },
        });

        const user = response.data[0];
        const {
          Name,
          assigned_projects,
          assigned_project_ids,
          department_id,
          department_name,
          Designation,
          role,
          ...restUserData
        } = user;
        const [Title, FirstName, LastName] = Name.split(" ");

        const assignedProjects = assigned_project_ids
          .split(",")
          .map((id, index) => ({
            value: id,
            label: assigned_projects[index],
          }));

        // const userType = userTypeOptions.find(option => option.value === user.role);
        const department = {
          value: department_id,
          label: department_name,
        };
        const userRole = {
          value: role,
          label: role,
        };
        const designation = {
          value: Designation,
          label: Designation,
        };
        setUserData({
          Title,
          FirstName,
          LastName,
          ...restUserData,
        });
        setSelectedProject(assignedProjects);
        setSelectedUserType(userRole);
        setSelectedDepartment(department);
        setSelectedDesignation(designation); // Set the selected designation
      } catch (error) {
        console.error("Error fetching user:", error.message);
        // Handle error (e.g., show an error message to the user)
      }
    };

    fetchUserData();
  }, [ID]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    let isValid = true;

    if (!userData.FirstName) {
      setFirstNameError("First Name is required");
      isValid = false;
    }

    if (!userData.Email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(userData.Email)) {
      setEmailError("Invalid email format");
      isValid = false;
    }

    if (!userData.ContactNo) {
      setContactNoError("Contact No is required");
      isValid = false;
    }

    if (!selectedDesignation) {
      setDesignationError("Designation is required");
      isValid = false;
    }

    if (!selectedDepartment) {
      setDepartmentError("Department is required");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");
      setIsLoading(true);

      const { Title, FirstName, LastName, ...restUserData } = userData;
      const updatedName = `${Title} ${FirstName} ${LastName}`;

      const assignedProjectIds = selectedProject.map(
        (project) => project.value
      );

      const updatedUserData = {
        ...restUserData,
        Name: updatedName,
        project_ids: assignedProjectIds,
        department_id: selectedDepartment ? selectedDepartment.value : "",
        department_name: selectedDepartment ? selectedDepartment.label : "",
        role: selectedUserType ? selectedUserType.label : "",
        Designation: selectedDesignation ? selectedDesignation.label : "",
      };

      const response = await axios.put(
        `${API_URL}/api/update_user/${ID}`,
        updatedUserData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      console.log(response.data.message);
      if (response.status === 200) {
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate("/user");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating user:", error.message);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleTitleChange = (selectedOption) => {
    setUserData({ ...userData, Title: selectedOption.value });
  };

  const titleOptions = [
    { value: "", label: "Salutation", isDisabled: true },
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Miss", label: "Miss" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "Password") {
      // When the password changes, update the password value in userData
      setUserData((prevUserData) => ({
        ...prevUserData,
        Password: value,
      }));

      // Check if the confirm password field is already filled and if the passwords match
      if (userData.ConfirmPassword && value !== userData.ConfirmPassword) {
        setPasswordMatchError("Passwords do not match");
      } else {
        setPasswordMatchError(""); // Passwords match, clear the error
      }
    } else if (name === "ConfirmPassword") {
      // When the confirm password changes, update the confirm password value in userData
      setUserData((prevUserData) => ({
        ...prevUserData,
        ConfirmPassword: value,
      }));

      // Check if the password field is already filled and if the passwords match
      if (userData.Password && value !== userData.Password) {
        setPasswordMatchError("Passwords do not match");
      } else {
        setPasswordMatchError(""); // Passwords match, clear the error
      }
    } else if (name === "FirstName") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        Name: `${selectedSalutation} ${value} ${prevUserData.LastName}`.trim(),
        FirstName: value,
      }));
    } else if (name === "LastName") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        Name: `${selectedSalutation} ${prevUserData.FirstName} ${value}`.trim(),
        LastName: value,
      }));
    } else {
      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
    switch (name) {
      case "FirstName":
        setFirstNameError("");
        break;
      case "Email":
        setEmailError("");
        break;
      case "ContactNo":
        setContactNoError("");
        break;
      case "Designation":
        setDesignationError("");
        break;
      case "Password":
        setPasswordError("");
        break;
      case "ConfirmPassword":
        setConfirmPasswordError("");
        break;
      case "department_name":
        setDepartmentError("");
        break;
      default:
        break;
    }
  };

  const handleBackProject = () => {
    navigate("/user");
  };

  const handleChangePassword = () => {
    setIsChangePassword(true);
  };

  const handleClosePopup = () => {
    setIsChangePassword(false);
  };

  // const handlePasswordSaveClick = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const newPassword = userData.Password; // Assuming you have a "Password" field in userData

  //     setIsLoading(true);

  //     const response = await axios.put(
  //       `${API_URL}/api/update_user_password/${ID}`,
  //       { NewPassword: newPassword },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       showSnackbar("Password updated successfully");
  //       handleClosePopup();
  //     }
  //   } catch (error) {
  //     console.error("Error updating password:", error.message);
  //     // Handle error (e.g., show an error message to the user)
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handlePasswordSaveClick = async () => {
    let isValid = true;

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!userData.Password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (!passwordRegex.test(userData.Password)) {
      setPasswordError(
        "Password must contain at least 8 characters, at least one uppercase letter, one lowercase letter, at least one number, and at least one special character"
      );
      isValid = false;
    }

    if (!userData.ConfirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      isValid = false;
    } else if (userData.Password !== userData.ConfirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    // Perform API call to update password if validation is successful
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_user_password/${ID}`,
        {
          NewPassword: userData.Password,
        },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        showSnackbar("Password updated successfully");
      }
    } catch (error) {
      console.error("Error updating password:", error.message);
    }
  };

  const handlePasswordCancelClick = () => {
    setIsChangePassword(false);
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };
  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setUserData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
    setDepartmentError("");
  };

  const CustomControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Department
      </button>
    </Menu>
  );
  const handleAddNewDepartment = () => {
    setShowDepartmentPopup(true);
    setDepartmentMenuIsOpen(false);
  };
  const fetchDesignationData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_designations`, {
        headers: { Authorization: authKey },
      });
      if (response.data) {
        const designationOptions = response.data.Designations.map(
          (designation) => ({
            value: designation.id,
            label: designation.designation_name,
          })
        );
        setDesignations(designationOptions);
      }
    } catch (error) {
      console.error("Error fetching designations data:", error.message);
    }
  };

  useEffect(() => {
    fetchDesignationData();
  }, []);

  const handleDesignationChange = (selectedOption) => {
    setSelectedDesignation(selectedOption); // Update selected designation state
    setUserData((prevUserData) => ({
      ...prevUserData,
      Designation: selectedOption ? selectedOption.label : "", // Update Designation field
    }));
    // setDesignationError("");
  };
  const handleDesignationMenuOpen = () => {
    setDesignationMenuIsOpen(true);
  };
  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit User
              </div>
              <div className="data-edit-form-heading-content-right-side">
                <button
                  className="Change-password"
                  onClick={handleChangePassword}
                >
                  Change Password
                </button>
                {isChangePassword && (
                  <div className="change-password-container">
                    <div className="change-password-container-content">
                      <div className="change-password-header">
                        <div className="change-password-header-content">
                          <div className="">Change Password</div>
                          <div className="" onClick={handleClosePopup}>
                            {" "}
                            <IoCloseOutline className="delete-icon" />
                          </div>
                        </div>
                      </div>
                      <div className="change-password-container-body">
                        <form action="">
                          <div className="edit-newperson-user-forms">
                            <div className="form-group-popup">
                              <label htmlFor="Password">
                                Password<span className="text-danger">*</span>
                              </label>
                              <div className="input-group-popup">
                                <input
                                  type="password"
                                  name="Password"
                                  value={userData.password}
                                  onChange={handleInputChange}
                                  placeholder="Enter password"
                                />
                                {passwordError && (
                                  <span className="text-danger">
                                    {passwordError}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group-popup">
                              <label htmlFor="ConfirmPassword">
                                Confirm Password
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group-popup">
                                <input
                                  type="password"
                                  name="ConfirmPassword"
                                  value={userData.ConfirmPassword}
                                  onChange={handleInputChange}
                                  placeholder="Enter Confirm Password"
                                />
                                {confirmPasswordError && (
                                  <span className="text-danger">
                                    {confirmPasswordError}
                                  </span>
                                )}
                                {passwordMatchError && (
                                  <span className="text-danger">
                                    {passwordMatchError}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="footer-popup">
                        <div className="button-group">
                          <button
                            className="edit-person-user-save-button"
                            type="save"
                            onClick={handlePasswordSaveClick}
                            disabled={isLoading}
                          >
                            {isLoading ? "Saving..." : "Save"}
                          </button>
                          <button
                            className="edit-person-user-cancel-button"
                            onClick={handlePasswordCancelClick}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
                <div className="form-group-selectname">
                  <label htmlFor="Name">
                    Name<span className="text-danger">*</span>
                  </label>
                  <Select
                    name="Title"
                    value={titleOptions.find(
                      (option) => option.value === userData.Title
                    )}
                    options={titleOptions}
                    onChange={handleTitleChange}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  <input
                    type="text"
                    name="FirstName"
                    value={userData.FirstName}
                    onChange={handleInputChange}
                  />
                  {firstNameError && (
                    <span className="text-danger">{firstNameError}</span>
                  )}
                  <input
                    type="text"
                    name="LastName"
                    value={userData.LastName}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department<span className="text-danger">*</span>
                  </label>

                  {/* <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  /> */}
                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    placeholder="Select Department"
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="Designation">
                    Designation<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="Designation"
                    options={designations}
                    value={selectedDesignation}
                    onChange={handleDesignationChange}
                    // components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Designation"
                    isSearchable
                    menuIsOpen={designationMenuIsOpen}
                    onMenuOpen={handleDesignationMenuOpen}
                    onMenuClose={() => setDesignationMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {/* {designationError && (
                    <span className="error-message">{designationError}</span>
                  )} */}
                </div>
                <div className="form-group">
                  <label htmlFor="Email">
                    Email<span className="text-danger">*</span>{" "}
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Email"
                      value={userData.Email}
                      onChange={handleInputChange}
                    />
                  </div>
                  {emailError && (
                    <span className="error-message">{emailError}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="ContactNo">
                    Contact No<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="ContactNo"
                      value={userData.ContactNo}
                      onChange={handleInputChange}
                    />
                  </div>
                  {contactNoError && (
                    <span className="error-message">{contactNoError}</span>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="UserType">
                    User Type<span className="text-danger">*</span>
                  </label>

                  <Select
                    options={userType}
                    value={selectedUserType}
                    onChange={handleUserTypeChange}
                    placeholder="Select user type"
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="Projects">
                    Assign Project<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="projects"
                    options={projects}
                    value={selectedProject}
                    onChange={handleProjectChange}
                    placeholder="Select project"
                    isSearchable
                    isMulti
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditUserForm;
