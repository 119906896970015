import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { GiBackwardTime } from "react-icons/gi";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "../Component/Pagination";
import API_URL from "../../src/Config";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import useClickOutside from '../useClickOutside';


function PRRequestList({ isSidebarExpanded, departmentID }) {
  const [prRequestsList, setPrRequestsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const fetchTimeout = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    const sortedVendors = [...prRequestsList].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setPrRequestsList(sortedVendors);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_all_pr_requests`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      setPrRequestsList(response.data.Items);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };

 const handleSearch = async (currentPage, itemsPerPage) => {
  if (!searchInput.trim()) {
    fetchData(currentPage, itemsPerPage); 
    return;
  }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_pr_requests`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setPrRequestsList(response.data.pr_requests); // assuming the array is `pr_requests`
        setTotalItems(response.data.total); // setting total items for pagination 
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage); 
      }
    }, 600); 

    return () => clearTimeout(fetchTimeout.current); 
  }, [searchInput, page, itemsPerPage]);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));


  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };


  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? prRequestsList.map((prRequest) => prRequest.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (departmentID) => {
    const updatedSelectedRows = selectedRows.includes(departmentID)
      ? selectedRows.filter((id) => id !== departmentID)
      : [...selectedRows, departmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === prRequestsList.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchiveDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDepartments = prRequestsList.filter(
          (department) => !selectedRows.includes(department.ID)
        );
        setPrRequestsList(updatedDepartments);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDeparment = () => {
    // navigate('/archive-department');
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    setSearchInput("");
  };

  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const formatDate = (dateString) => {
    if (!dateString || isNaN(new Date(dateString))) {
      return "NA"; // Or any default value you want to show for invalid dates
    }

    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return formatter.format(date);
  };

  const handlePRRequest = async (pr_request_id) => {
    navigate(`/pr-request-view/${pr_request_id}`);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "#D4AC0D";
      case "Approved":
        return "green";
      case "Rejected":
        return "red";
      case "Returned":
        return "#ff9800";
      default:
        return "black";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "Pending":
        return (
          <MdOutlineWatchLater
            style={{
              fontSize: "1.1rem",
              color: "#D4AC0D",
              verticalAlign: "top",
              paddingTop: "1.3px",
            }}
          />
        );
      case "Approved":
        return (
          <FaRegCheckCircle
            style={{
              fontSize: "1.1rem",
              color: "green",
              verticalAlign: "top",
              paddingTop: "1.3px",
            }}
          />
        );
      case "Rejected":
        return (
          <RxCrossCircled
            style={{
              fontSize: "1.1rem",
              color: "red",
              verticalAlign: "top",
              paddingTop: "1.3px",
            }}
          />
        );
      case "Returned":
        return (
          <GiBackwardTime
            style={{
              fontSize: "1.1rem",
              color: "#ff9800",
              verticalAlign: "top",
              paddingTop: "1.3px",
            }}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveDepartment}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All PR Request List
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup} ref={buttonRef}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {sortOrder[sortColumn] === "asc"
                              ? "(Ascending)"
                              : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button
                              onClick={() => handleSort("department_name")}
                            >
                              Department Name{" "}
                              {sortOrder.department_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("pr_type")}>
                              PR Type{" "}
                              {sortOrder.pr_type === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("qty")}>
                              Quantity{" "}
                              {sortOrder.qty === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("status")}>
                              Status{" "}
                              {sortOrder.status === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("est_cost")}>
                              Estimated Cost{" "}
                              {sortOrder.est_cost === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("est_unit_rate")}>
                              Estimated Unit Rate{" "}
                              {sortOrder.est_cost === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : prRequestsList.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>PR REQUEST ID</th>
                          <th>DEPARTMENT</th>
                          <th>PREFERRED VENDOR</th>
                          <th>PR TYPE</th>
                          <th>STATUS</th>
                          <th>DELIVERY DATE</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {prRequestsList.map((prRequest, index) => (
                          <tr key={prRequest.id} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(prRequest.id)}
                                onChange={() => handleSelectRow(prRequest.id)}
                              />
                            </td>

                            <td>{prRequest.serial_no}</td>
                            <td
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                              }}
                              onClick={() => handlePRRequest(prRequest.id)}
                            >
                              {prRequest.random_request_id}
                            </td>
                            <td>{prRequest.department_name || "NA"}</td>
                            <td>{prRequest.preferred_vendor || "NA"}</td>
                            <td>{prRequest.pr_type || "NA"}</td>
                            <td
                              style={{
                                color: getStatusColor(prRequest.pr_status),
                              }}
                            >
                              {getStatusIcon(prRequest.pr_status)}{" "}
                              {prRequest.pr_status || "NA"}
                            </td>
                            <td>
                              {formatDate(
                                prRequest.expected_delivery_date || "NA"
                              )}
                            </td>
                            {/* <td> <button>Approve</button> 
                            <button>Reject</button>
                            <button>Return to requester</button>
                            
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No PR Request List Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}
        {/* {isMainContainerVisible && (
          <div className="main-containers">
            <UserView userID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )} */}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PRRequestList;
