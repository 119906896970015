import React, { useState, useEffect } from "react";
import "./Level1Form.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Select, { components } from "react-select";
import API_URL from "../../src/Config";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
function MatCodeGenerateForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [levelOneData, setLevelOneData] = useState([]);
  const [levelTwoData, setLevelTwoData] = useState([]);
  const [levelThreeData, setLevelThreeData] = useState([]);
  const [generatedMatCode, setGeneratedMatCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    levelOneId: "",
    levelTwoId: "",
    levelThreeId: "",
    levelFour: "",
    levelFive: "",
    Description: "",
    MatCode:"",
  });
  const {theme} = useContext(ThemeContext)

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveMatCode();
    }
  };

  useEffect(() => {
    fetchLevelOneData();
  }, []);


  const fetchLevelOneData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_level_ones`, {
        headers: {
          Authorization: authKey,
        },
      });

      const options = response.data.map((level) => ({
        label: `${level.level} (${level.description})`,
        value: level.id,
      }));

      setLevelOneData(options);
      setLevelOneOptions(
        response.data.reduce((acc, level) => {
          acc[level.id] = level.level; // Store description instead of label
          return acc;
        }, {})
      );
    } catch (error) {
      console.error("Error fetching Level 1 data:", error.message);
    }
  };

  const fetchLevelTwoData = async (levelOneId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_level_two_by_level_one/${levelOneId}`, {
        headers: {
          Authorization: authKey,
        },
      });

      const options = response.data.map((item) => ({
        label: `${item.level} (${item.description})`,
        value: item.id,
      }));

      setLevelTwoData(options);
      setLevelTwoOptions(
        response.data.reduce((acc, item) => {
          acc[item.id] = item.level; // Store description instead of label
          return acc;
        }, {})
      );
    } catch (error) {
      console.error("Error fetching Level 2 data:", error.message);
    }
  };

  const fetchLevelThreeData = async (levelTwoId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_level_three_by_level_two/${levelTwoId}`, {
        headers: {
          Authorization: authKey,
        },
      });

      const options = response.data.map((item) => ({
        label: `${item.level} (${item.description})`,
        value: item.id,
      }));

      setLevelThreeData(options);
      setLevelThreeOptions(
        response.data.reduce((acc, item) => {
          acc[item.id] = item.level; // Store description instead of label
          return acc;
        }, {})
      );
    } catch (error) {
      console.error("Error fetching Level 3 data:", error.message);
    }
  };
  const [levelOneOptions, setLevelOneOptions] = useState({});
  const [levelTwoOptions, setLevelTwoOptions] = useState({});
  const [levelThreeOptions, setLevelThreeOptions] = useState({});




  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  // const handleLevelFourChange = (event) => {
  //   const levelFour = event.target.value;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     levelFour,
  //   }));
  //   generateMatCode(formData.levelOneId, formData.levelTwoId, formData.levelThreeId, levelFour, formData.levelFive);
  // };

  // const handleLevelFiveChange = (event) => {
  //   const levelFive = event.target.value;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     levelFive,
  //   }));
  //   generateMatCode(formData.levelOneId, formData.levelTwoId, formData.levelThreeId, formData.levelFour, levelFive);
  // };

  const handleLevelFourChange = (event) => {
    const levelFour = event.target.value;
    if (levelFour.length > 2) {
      setSeverity("error");
      showSnackbar("Level 4 cannot exceed 2 digits");
      return; // Stop further processing
    }
    setFormData((prevState) => ({
      ...prevState,
      levelFour,
    }));
    generateMatCode(
      formData.levelOneId,
      formData.levelTwoId,
      formData.levelThreeId,
      levelFour,
      formData.levelFive
    );
  };
  
  const handleLevelFiveChange = (event) => {
    const levelFive = event.target.value;
    if (levelFive.length > 2) {
      setSeverity("error");
      showSnackbar("Level 5 cannot exceed 2 digits");
      return; // Stop further processing
    }
    setFormData((prevState) => ({
      ...prevState,
      levelFive,
    }));
    generateMatCode(
      formData.levelOneId,
      formData.levelTwoId,
      formData.levelThreeId,
      formData.levelFour,
      levelFive
    );
  };
  

  // const handleLevelOneChange = (selectedOption) => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     levelOneId: selectedOption ? selectedOption.value : "",
  //     levelTwoId: "", // Reset Level 2 when Level 1 changes
  //   }));
  //   fetchLevelTwoData(selectedOption ? selectedOption.value : "");
  // };

  // const handleLevelTwoChange = (selectedOption) => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     levelTwoId: selectedOption ? selectedOption.value : "",
  //     levelThreeId: "", // Reset Level 3 when Level 2 changes
  //   }));
  //   fetchLevelThreeData(selectedOption ? selectedOption.value : "");
  // };

  // const handleLevelThreeChange = (selectedOption) => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     levelThreeId: selectedOption ? selectedOption.value : "",
  //   }));
  // };

  const handleLevelOneChange = (selectedOption) => {
    const levelOneId = selectedOption ? selectedOption.value : "";
    setFormData((prevState) => ({
      ...prevState,
      levelOneId,
      levelTwoId: "", // Reset Level 2 when Level 1 changes
      levelThreeId: "", // Reset Level 3 when Level 1 changes
    }));
    fetchLevelTwoData(levelOneId);
    generateMatCode(levelOneId, formData.levelTwoId, formData.levelThreeId);
  };

  const handleLevelTwoChange = (selectedOption) => {
    const levelTwoId = selectedOption ? selectedOption.value : "";
    setFormData((prevState) => ({
      ...prevState,
      levelTwoId,
      levelThreeId: "", // Reset Level 3 when Level 2 changes
    }));
    fetchLevelThreeData(levelTwoId);
    generateMatCode(formData.levelOneId, levelTwoId, formData.levelThreeId);
  };

  const handleLevelThreeChange = (selectedOption) => {
    const levelThreeId = selectedOption ? selectedOption.value : "";
    setFormData((prevState) => ({
      ...prevState,
      levelThreeId,
    }));
    generateMatCode(formData.levelOneId, formData.levelTwoId, levelThreeId);
  };

  // const generateMatCode = (levelOneId, levelTwoId, levelThreeId) => {
  //   const levelOneDesc = levelOneOptions[levelOneId] || "xx";
  //   const levelTwoDesc = levelTwoOptions[levelTwoId] || "xx";
  //   const levelThreeDesc = levelThreeOptions[levelThreeId] || "xx";

  //   const code = `${levelOneDesc}-${levelTwoDesc}-${levelThreeDesc}-xx-xx`;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     mat_code: code,
  //   }));
  // };

  const generateMatCode = (levelOneId, levelTwoId, levelThreeId, levelFour, levelFive) => {
    const levelOneDesc = levelOneOptions[levelOneId] || "xx";
    const levelTwoDesc = levelTwoOptions[levelTwoId] || "xx";
    const levelThreeDesc = levelThreeOptions[levelThreeId] || "xx";
    const code = `${levelOneDesc}-${levelTwoDesc}-${levelThreeDesc}-${levelFour || "xx"}-${levelFive || "xx"}`;
    setFormData((prevState) => ({
      ...prevState,
      MatCode: code,
    }));
  };

  const handleCancelClick = () => {
    navigate("/mat-code");
  };




  // const handleSaveMatCode = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  
  //     const response = await axios.post(
  //       `${API_URL}/api/add_mat_code`,
  //       {
  //         LevelOneId: formData.levelOneId,
  //         LevelTwoId: formData.levelTwoId,
  //         LevelThreeId: formData.levelThreeId,
  //         LevelFour: formData.levelFour,
  //         LevelFive: formData.levelFive,
  //         Description: formData.Description,
  //         MatCode: formData.MatCode,  
  //       },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );
  
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Mat code saved successfully");
  //       setTimeout(() => {
  //         navigate("/mat-code");  
  //       }, 1500);  
  //     }
  //   } catch (error) {
  //     console.error("Error in saving mat code:", error.message);
  //     setSeverity("error");
  //     showSnackbar("Failed to save mat code");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  

  // const handleSaveMatCode = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  
  //     // Remove dashes from MatCode
  //     const formattedMatCode = formData.MatCode.replace(/-/g, "");
  
  //     const response = await axios.post(
  //       `${API_URL}/api/add_mat_code`,
  //       {
  //         LevelOneId: formData.levelOneId,
  //         LevelTwoId: formData.levelTwoId,
  //         LevelThreeId: formData.levelThreeId,
  //         LevelFour: formData.levelFour,
  //         LevelFive: formData.levelFive,
  //         Description: formData.Description,
  //         MatCode: formattedMatCode,  // Use the formatted MatCode here
  //       },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         }
  //       }
  //     );
  
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Mat code saved successfully");
  //       setTimeout(() => {
  //         navigate("/mat-code");
  //       }, 1500);
  //     }
  //   } catch (error) {
  //     console.error("Error in saving mat code:", error.message);
  //     setSeverity("error");
  //     showSnackbar("Failed to save mat code");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  
  const handleSaveMatCode = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Remove dashes from MatCode
      const formattedMatCode = formData.MatCode.replace(/-/g, "");
  
      const response = await axios.post(
        `${API_URL}/api/add_mat_code`,
        {
          LevelOneId: formData.levelOneId,
          LevelTwoId: formData.levelTwoId,
          LevelThreeId: formData.levelThreeId,
          LevelFour: formData.levelFour,
          LevelFive: formData.levelFive,
          Description: formData.Description,
          MatCode: formattedMatCode, // Use the formatted MatCode
        },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Mat code saved successfully");
        setTimeout(() => {
          navigate("/mat-code");
        }, 1500);
      }
    } catch (error) {
      setIsLoading(false);
  
      if (error.response && error.response.status === 409) {
        // Handle duplicate mat code error
        setSeverity("error");
        showSnackbar("This mat code already exists");
      } else {
        console.error("Error in saving mat code:", error.message);
        setSeverity("error");
        showSnackbar("Failed to save mat code");
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                Generate New Mat Code
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="LevelOne">
                    Level 1<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelOneData}
                    onChange={handleLevelOneChange}
                    filterOption={(option, inputValue) =>
                      option.label.toLowerCase().includes(inputValue.toLowerCase())
                    } 
                    placeholder="Select Level 1"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="LevelTwo">
                    Level 2<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelTwoData}
                    onChange={handleLevelTwoChange}
                    filterOption={(option, inputValue) =>
                      option.label.toLowerCase().includes(inputValue.toLowerCase())
                    } 
                    placeholder="Select Level 2"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="LevelTwo">
                    Level 3<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelThreeData}
                    onChange={handleLevelThreeChange}
                    filterOption={(option, inputValue) =>
                      option.label.toLowerCase().includes(inputValue.toLowerCase())
                    } 
                    placeholder="Select Level 3"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentName">
                    Level 4<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="levelFour"
                      value={formData.levelFour}
                      onChange={handleLevelFourChange}
                      placeholder="Enter Level 4 (2 digit)"
                    />
                    {errors.levelFour && (
                      <div className="error-message">
                        {errors.levelFour}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentCode">
                    Level 5<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="levelFive"
                      value={formData.levelFive}
                      onChange={handleLevelFiveChange}
                      placeholder="Enter level 5 (2 digit)"
                    />
                     {errors.levelFive && (
                      <div className="error-message">
                        {errors.levelFive}
                      </div>
                    )}
                  </div>
                  
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentCode">
                    Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Description"
                      value={formData.Description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                    />
                     {errors.Description && (
                      <div className="error-message">
                        {errors.Description}
                      </div>
                    )}
                  </div>
                  
                </div>
                
                <div className="mate-code-button">
                    <input
                      type="text"
                      id="MatCode"
                      value={formData.MatCode || ""}
                      placeholder="xx-xx-xx-xx-xx"
                      readOnly
                    />
                 </div>
              </div>
            </form>

   
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="button"
                onClick={handleSaveMatCode}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
               
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default MatCodeGenerateForm;
