import React, { useState } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { FaPlusCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

function CustomerForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [customerData, setCustomerData] = useState({
    customer_name: "",
    locations: [
      {
        location: "",
        contact_person_name: "",
        location_contact_no: "",
        location_email: "",
        location_address: "",
      },
    ],
    address: "",
    contact_no: "",
  });

  // const handleInputChange = (event, index = null, field = null) => {
  //   const { name, value } = event.target;
  //   if (index !== null) {
  //     const newLocations = [...customerData.locations];
  //     if (field) {
  //       newLocations[index][field] = value;
  //     } else {
  //       newLocations[index] = value;
  //     }
  //     setCustomerData((prevState) => ({
  //       ...prevState,
  //       locations: newLocations,
  //     }));
  //   } else {
  //     setCustomerData((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   }
  //   setErrors((prevState) => ({
  //     ...prevState,
  //     [name]: "", // Clear the error message for this field
  //   }));
  // };

  const handleInputChange = (event, index = null, field = null) => {
    const { name, value } = event.target;
  
    // If handling contact number validation
    if (name === "contact_no") {
      if (value.length > 10) {
        setErrors((prevState) => ({
          ...prevState,
          contact_no: "Contact number cannot exceed 10 digits",
        }));
        return; // Exit early if validation fails
      } else {
        setErrors((prevState) => ({
          ...prevState,
          contact_no: "", // Clear error if valid
        }));
      }
    }
  
    if (index !== null) {
      const newLocations = [...customerData.locations];
      if (field) {
        newLocations[index][field] = value;
      } else {
        newLocations[index] = value;
      }
      setCustomerData((prevState) => ({
        ...prevState,
        locations: newLocations,
      }));
    } else {
      setCustomerData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };
  

  
  const handleAddLocation = () => {
    setCustomerData((prevState) => ({
      ...prevState,
      locations: [
        ...prevState.locations,
        {
          location: "",
          contact_person_name: "",
          location_contact_no: "",
          location_email: "",
          location_address: "",
        },
      ],
    }));
  };

  const handleRemoveLocation = (index) => {
    const newLocations = customerData.locations.filter((_, i) => i !== index);
    setCustomerData((prevState) => ({
      ...prevState,
      locations: newLocations,
    }));
  };

  const handleCancelClick = () => {
    navigate("/customer");
  };

  
  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Validate required fields
      if (!customerData.customer_name || !customerData.contact_no) {
        setSeverity("error");
        showSnackbar("Customer name and contact number are required");
        return; // Exit early if validation fails
      }
      if (customerData.contact_no.length < 10) {
        setSeverity("error");
        showSnackbar("Contact number must be at least 10 digits", "error");
        return; // Exit the function if there are validation errors
      }
  
      // Filter out locations with empty fields
      const filteredLocations = customerData.locations.filter(
        (location) =>
          location.location.trim() !== "" &&
          location.contact_person_name.trim() !== "" &&
          location.location_contact_no.trim() !== "" &&
          location.location_email.trim() !== "" &&
          location.location_address.trim() !== ""
      );
  
      const dataToSend = {
        ...customerData,
        locations: filteredLocations,
      };
  
      const response = await axios.post(
        `${API_URL}/api/add_customer`,
        dataToSend,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Customer added successfully");
        setTimeout(() => {
          navigate("/customer");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving customer:", error.message);
      setSeverity("error");
      showSnackbar("Error saving customer");
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Customer
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="customer_name">
                    Customer Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="customer_name"
                      value={customerData.customer_name}
                      onChange={handleInputChange}
                      placeholder="Enter customer name"
                    />
                    
                  </div>
                  {errors.customer_name && (
                      <div className="error-message">
                        {errors.customer_name}
                      </div>
                    )}
                </div>
                <div className="form-group">
                  <label htmlFor="contact_no">
                    Contact No<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="contact_no"
                      value={customerData.contact_no}
                      onChange={handleInputChange}
                      placeholder="Enter contact no"
                    />
                   
                  </div>
                  {errors.contact_no && (
                      <div className="error-message">{errors.contact_no}</div>
                    )}
                </div>
                <div className="form-group">
                  <label htmlFor="address">
                    Address
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="address"
                      value={customerData.address}
                      onChange={handleInputChange}
                      placeholder="Enter address"
                    />
                    {errors.address && (
                      <div className="error-message">{errors.address}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Locations
                  </label>
                  <div className="new-frequency-rowss">
                    {customerData.locations.map((location, index) => (
                      <div key={index} className="location-group-content">
                        <div className="location-group">
                          <div className="input-group frequency-row">
                            <textarea
                              className="point-fr"
                              name={`Location_${index}`}
                              value={location.location}
                              onChange={(e) =>
                                handleInputChange(e, index, "location")
                              }
                              placeholder={`Enter location ${index + 1}`}
                              cols="30"
                              rows="1"
                            ></textarea>
                          </div>
                          <div className="flex-group">
                            <div>
                              <div className="input-group frequency-row">
                                <input
                                  type="text"
                                  className="point-fr"
                                  name={`ContactPersonName_${index}`}
                                  value={location.contact_person_name}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "contact_person_name"
                                    )
                                  }
                                  placeholder="Enter contact person name"
                                />
                              </div>
                              <div className="input-group frequency-row">
                                <input
                                  type="number"
                                  className="point-fr"
                                  name={`LocationContactNo_${index}`}
                                  value={location.location_contact_no}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "location_contact_no"
                                    )
                                  }
                                  placeholder="Enter location contact no"
                                />
                              </div>
                              <div className="input-group frequency-row">
                                <input
                                  type="email"
                                  className="point-fr"
                                  name={`LocationEmail_${index}`}
                                  value={location.location_email}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "location_email"
                                    )
                                  }
                                  placeholder="Enter location email"
                                />
                              </div>
                            </div>
                            <div>
                              <div className="input-group frequency-row">
                                <textarea
                                  className="point-fr"
                                  name={`LocationAddress_${index}`}
                                  value={location.location_address}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "location_address"
                                    )
                                  }
                                  placeholder="Enter location address"
                                  cols="30"
                                  rows="6.9"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="add-close-button"
                            onClick={() => handleRemoveLocation(index)}
                            disabled={customerData.locations.length === 1}
                          >
                            <IoClose className="frequency-close-icon" />
                          </button>
                        </div>
                      </div>
                    ))}

                    <div className="add-new-frequency-customer">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddLocation}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default CustomerForm;
