import React, { useState, useEffect, useRef } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";

function DepartmentForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedInstallationDate, setSelectedInstallationDate] =
    useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const { id } = useParams();
  const [showAttachment, setShowAttachment] = useState(false);
  const fileInputRef = useRef(null);
  const joinFileInputRef = useRef(null);
  const [isJoinPopupOpen, setIsJoinPopupOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedJoinFiles, setSelectedJoinFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [manPowerData, setManPowerData] = useState({
    project_id: id || "",
    person_name: "",
    position: "",
    contact_no: "",
    email: "",
    status: "",
    ec_no: "",
    joining_attachments: "",
    site_clr_attachments: "",
    joining_expected_date: "",
  });

  const handleChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    if (selectedOption && selectedOption.value === "Exited") {
      setShowAttachment(true);
    } else {
      setShowAttachment(false);
    }
    setManPowerData((prevState) => ({
      ...prevState,
      status: selectedOption ? selectedOption.value : "",
    }));
    setErrors((prevState) => ({
      ...prevState,
      status: "", // Clear the error message for this field
    }));
  };

  const handleUploadButtonClick = (inputRef) => {
    // This function handles the file upload button click
    // Open the file input dialog
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const handleFileInputChange = (files, setFiles) => {
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
    setIsJoinPopupOpen(false);
  };
  const handleJoinIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(false);
    setIsJoinPopupOpen(true);
  };
  const handleDeleteFile = (indexToDelete) => {
    console.log("Delete function called for index:", indexToDelete);
    console.log("Before delete:", selectedFiles);

    // Filter out the file at the given index
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state
    setSelectedFiles(updatedFiles);
    console.log("After delete:", updatedFiles);

    // Check if no files remain, then close the popup
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
      console.log("Popup closed");
    }
  };
  const handleJoinDeleteFile = (indexToDelete) => {
    console.log("Delete function called for index:", indexToDelete);
    console.log("Before delete:", selectedJoinFiles);

    // Filter out the file at the given index
    const updatedFiles = selectedJoinFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state
    setSelectedJoinFiles(updatedFiles);
    console.log("After delete:", updatedFiles);

    // Check if no files remain, then close the popup
    if (updatedFiles.length === 0) {
      handleJoinCloseAttachment(); // Close the popup if no files remain
      console.log("Popup closed");
    }
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  const handleJoinCloseAttachment = () => {
    setIsJoinPopupOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setManPowerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedDate(date);
      setManPowerData((prevState) => ({
        ...prevState,
        joining_expected_date: formattedDate,
      }));
    }
  };

  const handleInstallationDateChange = (date) => {
    if (date) {
      const year = date.getFullYear();
      setSelectedInstallationDate(date);
      setManPowerData((prevState) => ({
        ...prevState,
        passing_year: year,
      }));
    }
  };

  const handleCancelClick = () => {
    navigate(`/actualmanpower/${id}`);
  };

  const statusOptions = [
    { value: "Exited", label: "Exited" },
    { value: "Absconding", label: "Absconding" },
    { value: "Present at site", label: "Present at site" },
    {
      value: "Appointment issued (yet to join)",
      label: "Appointment issued (yet to join)",
    },
  ];

  const validateForm = () => {
    const newErrors = {};
    if (!manPowerData.person_name)
      newErrors.person_name = "Person name is required";
    if (!manPowerData.contact_no)
      newErrors.contact_no = "Contact number is required";
    if (!manPowerData.status) newErrors.status = "Current status is required";
    if (!manPowerData.qualification)
      newErrors.qualification = "Qualification is required";
    if (!manPowerData.passing_year)
      newErrors.passing_year = "Year of passing is required";
    if (!manPowerData.joining_expected_date)
      newErrors.joining_expected_date = "Expected date of joining is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleSaveClick = async () => {


  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const payload = {
  //       project_id: selectedPosition.project_id, // Ensure project_id is included
  //       person_name: manPowerData.person_name || "",
  //       contact_no: manPowerData.contact_no || "",
  //       email: manPowerData.email || "",
  //       status: manPowerData.status || "",
  //       ec_no: manPowerData.ec_no || "",
  //       joining_expected_date: manPowerData.joining_expected_date || "",
  //       man_power_id: selectedPosition ? selectedPosition.value : null, // Add position ID if selected
  //     };

  //     const response = await axios.post(
  //       `${API_URL}/api/add_actual_manpower`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Actual manpower added successfully");
  //       setTimeout(() => {
  //         navigate(`/actualmanpower/${id}`);
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving actual manpower:", error.message);
  //     setSeverity("error");
  //     showSnackbar("Failed to save actual manpower");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleSaveClick = async (e) => {
  //   e.preventDefault();
  
  //   // Create FormData object
  //   const formData = new FormData();
  
  //   // Append regular form data
  //   formData.append('project_id', 'dfa1e8bb-6b08-4766-9bff-2b8c9dff2438');
  //   formData.append('person_name', 'aa');
  //   formData.append('contact_no', '1234567895');
  //   formData.append('email', 'bansikhanpara560@gmail.com');
  //   formData.append('status', 'Exited');
  //   formData.append('ec_no', '45');
  //   formData.append('joining_expected_date', '2024-11-10');
  //   formData.append('man_power_id', 'd5400206-3c20-44d9-83b1-af11567f6df6');
  
  //   // Append selected files for site clearance
  //   selectedFiles.forEach((file) => {
  //     formData.append('site_clr_attachments', file);
  //   });
  
  //   // Append selected files for joining attachments
  //   selectedJoinFiles.forEach((file) => {
  //     formData.append('joining_attachments', file);
  //   });
  
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     // Make the API request using axios
  //     const response = await axios.post( `${API_URL}/api/add_actual_manpower`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         Authorization: authKey, // Include Authorization if required
  //       },
  //     });
  
  //     // Handle successful response
  //     console.log('Response:', response.data);
  //   } catch (error) {
  //     // Handle error
  //     console.error('Error submitting form:', error);
  //   }
  // };
  const handleSaveClick = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    
    // Dynamically get values from state (make sure manPowerData is correctly updated)
    formData.append('project_id', selectedPosition.project_id); // Assuming selectedPosition is correctly populated
    formData.append('person_name', manPowerData.person_name || ""); // Ensure default value if undefined
    formData.append('contact_no', manPowerData.contact_no || ""); // Ensure default value if undefined
    formData.append('email', manPowerData.email || ""); // Ensure default value if undefined
    formData.append('status', manPowerData.status || ""); // Ensure default value if undefined
    formData.append('ec_no', manPowerData.ec_no || ""); // Ensure default value if undefined
    formData.append('joining_expected_date', manPowerData.joining_expected_date || ""); // Ensure default value if undefined
    formData.append('man_power_id', selectedPosition ? selectedPosition.value : null); // Dynamically get value from selectedPosition

    // Append files if available
    selectedFiles.forEach((file) => {
        formData.append('site_clr_attachments', file);
    });
  
    selectedJoinFiles.forEach((file) => {
        formData.append('joining_attachments', file);
    });

    try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.post(`${API_URL}/api/add_actual_manpower`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: authKey,
            },
        });

        console.log('Response:', response.data);
        setSnackbarMessage('Actual manpower added successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => {
            navigate(`/actualmanpower/${id}`);
        }, 1000);

    } catch (error) {
        console.error('Error response:', error.response);

        if (error.response && error.response.data) {
            console.log('Error data:', error.response.data);

            if (error.response.data.error === 'ec_no must be unique. This ec_no already exists.') {
                setSnackbarMessage('EC No must be unique. This EC No already exists.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            } else {
                setSnackbarMessage('Error submitting form. Please try again.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarMessage('Error submitting form. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    }
};

  
  
  
  

  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const fetchPositionData = async (currentPage, itemsPerPage) => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/manpower_requirements/${id}`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      setManPowerData(response.data.Items);

      // Extract positions and format them for react-select
      const options = response.data.Items.map((item) => ({
        value: item.id,
        label: item.position, // Use position as the label
        project_id: item.project_id, // Include other properties as needed
      }));

      setPositionOptions(options);
    } catch (error) {
      console.error("Error fetching position data:", error.message);
    }
  };

  useEffect(() => {
    fetchPositionData();
  }, []);

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Actual Manpower
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="person_name">
                    Person Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="person_name"
                      value={manPowerData.person_name}
                      onChange={handleInputChange}
                      placeholder="Enter person name"
                    />
                    {errors.person_name && (
                      <div className="error-message">{errors.person_name}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="contact_no">
                    Contact No <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="contact_no"
                      value={manPowerData.contact_no}
                      onChange={handleInputChange}
                      placeholder="Enter contact number"
                    />
                    {errors.contact_no && (
                      <div className="error-message">{errors.contact_no}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <div className="input-group">
                    <input
                      type="email"
                      name="email"
                      value={manPowerData.email}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                    />
                  </div>
                </div>
                <div className="form-group-select">
                  <label htmlFor="DepartmentCode">
                    Position<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="EquipmentSubType"
                    options={positionOptions}
                    placeholder="Select position"
                    isSearchable
                    onChange={setSelectedPosition}
                    value={selectedPosition}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="ec_no">
                    EC No <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="ec_no"
                      value={manPowerData.ec_no}
                      onChange={handleInputChange}
                      placeholder="Enter EC no"
                    />
                  </div>
                </div>

                <div className="form-group-select">
                  <label htmlFor="status">
                    Current Status <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="currentStatus"
                    value={selectedStatus}
                    onChange={handleChange}
                    options={statusOptions}
                    placeholder="Select status..."
                    isClearable
                  />
                  {errors.status && (
                    <div className="error-message">{errors.status}</div>
                  )}
                </div>
                {showAttachment && (
                  <div className="form-group-attachments">
                    <div className="form-group">
                      <label htmlFor="site_clr_attachments">
                        Site Clearance <span className="text-danger">*</span>
                      </label>
                      <button
                        type="button"
                        className="challan-btn"
                        onClick={() => {
                          handleUploadButtonClick(fileInputRef);
                        }}
                      >
                        <FiUpload /> Upload File
                      </button>
                      <input
                        type="file"
                        id="site_clr_attachments"
                        ref={fileInputRef}
                        onChange={(e) =>
                          handleFileInputChange(
                            e.target.files,
                            setSelectedFiles
                          )
                        }
                        style={{ display: "none" }}
                        multiple
                      />
                      {selectedFiles.length > 0 && (
                        <span
                          className="inquiry-file-btn"
                          onClick={handleIconClick}
                        >
                          <MdAttachFile className="inquiry-file-icon" />{" "}
                          {`${selectedFiles.length}`}
                        </span>
                      )}
                      {isPopupOpen && (
                        <div className="attach-popup-amc1">
                          <div className="attach-popup-contant">
                            <div className="attachment-header">
                              <div className="attachment-header-content">
                                Attachments
                              </div>
                              <button
                                className="fiile-close-btn"
                                onClick={handleCloseAttachment}
                              >
                                <IoCloseOutline className="file-close-icon" />
                              </button>
                            </div>
                            {selectedFiles.length > 0 && (
                              <div className="attach-scrroll-body">
                                {selectedFiles.map((file, index) => (
                                  <div
                                    className="attach-popup-contant-body"
                                    key={index}
                                  >
                                    <div className="attach-file-container">
                                      <div className="attach-file-show">
                                        <div className="text-attachment">
                                          {file.name}
                                        </div>
                                        <div className="text-attachment-file">
                                          File Size:(
                                          {(file.size / 1024).toFixed(2)} KB)
                                        </div>
                                      </div>
                                      <div
                                        className="file-delete-btn"
                                        onClick={() => handleDeleteFile(index)}
                                      >
                                        <span>
                                          {" "}
                                          <MdDeleteOutline className="file-delete-icon" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="form-group-attachments">
                  <div className="form-group">
                    <label htmlFor="joining_attachments">Joining Documents</label>
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={() => {
                        handleUploadButtonClick(joinFileInputRef);
                      }}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      type="file"
                      id="joining_attachments"
                      ref={joinFileInputRef}
                      onChange={(e) =>
                        handleFileInputChange(
                          e.target.files,
                          setSelectedJoinFiles
                        )
                      }
                      style={{ display: "none" }}
                      multiple
                    />
                    {selectedJoinFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handleJoinIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedJoinFiles.length}`}
                      </span>
                    )}
                    {isJoinPopupOpen && (
                      <div className="attach-popup-amc1">
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handleJoinCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          {selectedJoinFiles.length > 0 && (
                            <div className="attach-scrroll-body">
                              {selectedJoinFiles.map((file, index) => (
                                <div
                                  className="attach-popup-contant-body"
                                  key={index}
                                >
                                  <div className="attach-file-container">
                                    <div className="attach-file-show">
                                      <div className="text-attachment">
                                        {file.name}
                                      </div>
                                      <div className="text-attachment-file">
                                        File Size:(
                                        {(file.size / 1024).toFixed(2)} KB)
                                      </div>
                                    </div>
                                    <div
                                      className="file-delete-btn"
                                      onClick={() =>
                                        handleJoinDeleteFile(index)
                                      }
                                    >
                                      <span>
                                        {" "}
                                        <MdDeleteOutline className="file-delete-icon" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="joining_expected_date">
                    Expected Date of Joining{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      name="joining_expected_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="datesss"
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          setSelectedDate(null);
                        }
                      }}
                    />
                    {errors.joining_expected_date && (
                      <div className="error-message">
                        {errors.joining_expected_date}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default DepartmentForm;