import React, { useState, useEffect } from "react";
import "./POConfigurationForm.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API_URL from "../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";

function InwardView({ isSidebarExpanded }) {
    const navigate = useNavigate();
    const { id } = useParams();
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [poData, setPoData] = useState({ po_no: "" });
    const [rows, setRows] = useState([]);

    const fetchData = async () => {
        try {
            const authKey = localStorage.getItem("authKey");
            const response = await axios.get(`${API_URL}/api/get_inbound_purchase_orders/${id}`, {
                headers: {
                    Authorization: authKey,
                },
            });

            const poDataResponse = response.data.inbound_purchase_orders[0];

            // Set data into the respective states
            setPoData({ po_no: poDataResponse.po_no });
            setSelectedVendor({
                label: poDataResponse.vendor_name,
                value: poDataResponse.vendor_id,
            });

            // Map items to rows for the table
            const rowsData = poDataResponse.items.map((item) => ({
                matCodes: item.mat_code,
                quantity: item.qty,
                price: item.price,
                unit: item.unit,
                est_unit_rate: item.est_unit_rate,
                description: item.description,
                inbounded_qty: item.inbounded_qty,
                inbound_list_id: item.id,
                mat_code_description: item.mat_code_description,
            }));

            setRows(rowsData);

        } catch (error) {
            console.error("Error fetching PO data:", error.message);
        }
    };

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id]);

    const handleBackProject = () => {
        navigate("/po-inbound-list");
    };

    return (
        <div
            className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
                }`}
        >
            <div className="data-form-content">
                <div className="data-form-header">
                    <div className="data-form-header-content">
                        <div className="data-form-heading-content-left-side">
                            <Tooltip title="Back" arrow>
                                <IoMdArrowBack
                                    className="back-company"
                                    onClick={handleBackProject}
                                />
                            </Tooltip>Inward List Items</div>
                    </div>
                </div>
                <div className="header-separator"></div>
                <div className="data-form-body">
                    <div className="action-purchase-header">
                        <div className="project-detailss">
                            <div className="project-detail-arround">
                                <div className="project-details-left-side">
                                    <div className="project-detail">
                                        <div className="detail-header">PO Number:</div>
                                        <div className="detail-part">{poData.po_no || "N/A"}</div>
                                    </div>
                                    <div className="project-detail">
                                        <div className="detail-header">Invoice No:</div>
                                        <div className="detail-part">{poData.po_no || "N/A"}</div>
                                    </div>
                                    <div className="project-detail">
                                        <div className="detail-header">Vendor:</div>
                                        {/* Display Vendor name here */}
                                        <div className="detail-part">
                                            {selectedVendor ? selectedVendor.label : "N/A"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="action-purchase-sheet-header">
                        <div className="actions-purchase-sheet-header-content">
                            <div className="actions-purchase-sheet-heading-content-left-side">
                                Item Table
                            </div>
                        </div>
                    </div>

                    <div className="action-purchase-body">
                        <div className="action-purchase-scroll-body">
                            <div className="action-purchase-table">
                                <table className="action-table action-multilevel-data ">
                                    <thead className="action-purchase-heads">
                                        <tr>
                                            <th className="th2">MAT CODE</th>

                                            <th className="th3">ESTIMATED UNIT RATE</th>
                                            <th className="th5">DESCRIPTION</th>
                                            <th className="th4">PRICE</th>
                                            <th className="th3 Total-Qty">PO QTY</th>
                                            <th className="th4">UNIT</th>
                                            <th className="th4 Inward-Qty">INWARDED QTY</th>
                                        </tr>
                                    </thead>
                                    <tbody className="action-purchase-scroll-bodys">
                                        {rows.map((row, index) => (
                                            <tr key={index}>
                                                <td className="th1">{row.matCodes || "N/A"}</td>

                                                <td className="th3">{row.est_unit_rate || "N/A"}</td>
                                                <td className="th5">{row.mat_code_description || "N/A"}</td>
                                                <td className="th4">{row.price || "N/A"}</td>
                                                <td className="th2">{row.quantity || "N/A"}</td>
                                                <td className="th4">{row.unit || "N/A"}</td>
                                                <td className="th4 inward">{row.inbounded_qty || "N/A"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InwardView;
