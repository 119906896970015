import React, { useState, useEffect } from "react";
import "./POConfigurationForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import { FiPlus } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { PiDotsThreeCircleLight } from "react-icons/pi";

function POConfigurationForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [savedRows, setSavedRows] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [matCodeOptions, setMatCodeOptions] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [poData, setPoData] = useState({ po_no: "" });
  const initialRow = {
    matCodes: [],
    quantity: "",
    price: "",
    description: "",
    po_no: "",
  };

  const [rows, setRows] = useState([initialRow]);
  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };
  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setPoData({ ...poData, [name]: value });
  };

  const handleSaveIconClick = async () => {
    const payload = {
      vendor_id: selectedVendor?.value,
      mat_code_id: rows[0]?.matCodes,
      quantity: rows[0]?.quantity,
      price: rows[0]?.price,
      description: rows[0]?.description || "",
      po_no: poData.po_no,
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Send POST request to add PO configuration
      const postResponse = await axios.post(
        `${API_URL}/api/add_po_configuration`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      console.log(postResponse.data.message);

      if (postResponse.status === 200) {
        const po_id = postResponse.data.po_id; 

        // Fetch updated PO list data using the po_id
        const getResponse = await axios.get(
          `${API_URL}/api/get_po_list/${po_id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );

        const { po_list_items } = getResponse.data;
        console.log("Fetched PO List Items:", po_list_items);

        // Update the rows with the fetched data
        const updatedRows = po_list_items.map((item) => ({
          matCodes: item.mat_code_id, 
          quantity: item.quantity, 
          price: item.price, 
          description: item.description, 
        }));

        setRows(updatedRows);

        setSeverity("success");
        showSnackbar("PO configuration added and data fetched successfully");
      }
    } catch (error) {
      console.error("Error handling PO configuration:", error.message);
      setSeverity("error");
      showSnackbar("An error occurred while processing PO configuration");
    } finally {
      setIsLoading(false);
    }
  };

  // const handleSaveIconClick = async (index) => {
  //   // Construct the payload for the specific row
  //   const payload = {
  //     vendor_id: selectedVendor?.value,
  //     mat_code_id: rows[index]?.matCodes,
  //     quantity: rows[index]?.quantity,
  //     price: rows[index]?.price,
  //     description: rows[index]?.description || "",
  //     po_no: poData.po_no,
  //   };

  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     // Send POST request to the API with the payload
  //     const response = await axios.post(
  //       `${API_URL}/api/add_po_configuration`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     console.log(response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("PO configuration added successfully");
  //       // Mark this row as saved
  //       setSavedRows((prev) => [...prev, index]);
  //     }
  //   } catch (error) {
  //     console.error("Error saving PO configuration:", error.message);
  //     setSeverity("error");
  //     showSnackbar("An error occurred while saving PO configuration");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const generatePoNumber = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/generate_po_number`, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.data && response.data.po_number) {
        return response.data.po_number;
      } else {
        throw new Error("PO number not found in response");
      }
    } catch (error) {
      console.error("Error generating PO number:", error);
      return "";
    }
  };

  // Fetch PO number on component mount
  useEffect(() => {
    const fetchPoNumber = async () => {
      const po_number = await generatePoNumber();
      setPoData((prevData) => ({ ...prevData, po_no: po_number }));
    };

    fetchPoNumber();
  }, []);

  // Handle adding a new row
  const handleAddRow = () => {
    const newRow = { ...initialRow };
    setRows((prevRows) => [...prevRows, newRow]); 
  };

  const handleRemoveRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleCancelClick = () => {
    navigate("/po-list");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const fetchVendorData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_vendors`, {
        headers: {
          Authorization: authKey,
        },
      });

      const formattedVendors = response.data.Vendors.map((vendor) => ({
        label: vendor.vendor_name,
        value: vendor.id,
      }));

      setVendorOptions(formattedVendors);
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  const fetchMattCodeData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_details`, {
        headers: {
          Authorization: authKey,
        },
      });

      const formattedMattCodes = response.data.mat_code_details.map((mat) => ({
        label: mat.mat_code,
        value: mat.mat_code_id,
      }));

      setMatCodeOptions(formattedMattCodes);
    } catch (error) {
      console.error("Error fetching mat code data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendorData();
    fetchMattCodeData();
  }, []);

  const handleSaveClick = async () => {
    if (!selectedVendor) {
      setSeverity("error");
      showSnackbar("Please select a vendor");
      return;
    }

    if (!poData.po_no) {
      setSeverity("error");
      showSnackbar("PO number is required");
      return;
    }

    const payload = {
      po_no: poData.po_no,
      vendor_id: selectedVendor.value,
      items: rows.map((row) => ({
        description: row.description || "",
        mat_code_id: row.matCodes,
        price: row.price,
        quantity: row.quantity,
      })),
    };

    // Log payload for debugging
    console.log("Payload:", JSON.stringify(payload, null, 2));

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/add_po_configurations_bulk`,
        payload,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("PO configurations added successfully");
      }
    } catch (error) {
      console.error(
        "Error saving PO configurations:",
        error.response ? error.response.data : error.message
      );
      setSeverity("error");
      showSnackbar("An error occurred while saving PO configurations");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New PO Configuration
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="action-purchase-header">
              <div className="form-group">
                <label htmlFor="po_no">
                  PO Number<span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="po_no"
                    value={poData.po_no}
                    onChange={handleInputChanges}
                    placeholder="Enter po number"
                  />
                </div>
              </div>
              <div className="form-group-select">
                <label htmlFor="DepartmentName">
                  Select Vendor<span className="text-danger">*</span>
                </label>
                <Select
                  id="EquipmentSubType"
                  options={vendorOptions}
                  placeholder="Select vendor"
                  isSearchable
                  onChange={setSelectedVendor}
                  value={selectedVendor}
                />
              </div>
            </div>
            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Item Table
                </div>
                <div className="actions-purchase-heading-content-right-side">
                  {/* <Link to="/purchaseform" className='purchase-new'>
                <button className='new-button'><HiOutlinePlus className="new-plus-icon" /> New</button>
                </Link> */}
                </div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-table">
                  <>
                    <table className="action-table action-multilevel-data ">
                      <thead className="action-purchase-heads">
                        <tr>
                          <th className="th2">MAT CODE</th>
                          <th className="th3">QUANTITY</th>
                          <th className="th4">PRICE</th>
                          <th className="th5">DESCRIPTION</th>
                          <th className="th6">ACTION</th>
                        </tr>
                      </thead>

                      <tbody className="action-purchase-scroll-bodys">
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td className="th1">
                              <Select
                                options={matCodeOptions}
                                value={matCodeOptions.find(
                                  (option) => option.value === row.matCodes
                                )}
                                onChange={(selectedOption) =>
                                  handleInputChange(
                                    index,
                                    "matCodes",
                                    selectedOption ? selectedOption.value : ""
                                  )
                                }
                              />
                            </td>

                            <td className="th2">
                              <input
                                type="number"
                                value={row.quantity}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                              />
                            </td>

                            <td className="th3">
                              <input
                                type="number"
                                value={row.price}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                              />
                            </td>

                            <td className="th4">
                              <input
                                type="textarea"
                                value={row.description}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "description",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                                  
                            <td className="item-action">
                              <FaRegCheckCircle
                                onClick={handleSaveIconClick}
                                className={`item-action-icon save-icon ${
                                  savedRows.includes(index) ? "saved" : ""
                                }`}
                              />

                              <MdOutlineCancel
                                onClick={() => handleRemoveRow(index)}
                                className="item-action-icon cancel-icon"
                              />

                              <PiDotsThreeCircleLight className="item-action-icon dot-icon" />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="row">
                      <div className="action-caluculation">
                        <div className="new-rows">
                          <button onClick={handleAddRow} className="add-rows">
                            <FiPlus className="add-row-icon" /> Add New Row
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="button-group">
                <button
                  className="data-form-save-button"
                  type="save"
                  onClick={handleSaveClick}
                  disabled={isLoading}
                >
                  {isLoading ? "Saving..." : "Save"}
                </button>
                <button
                  className="data-form-cancel-button"
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default POConfigurationForm;


