import React, { useState, useEffect } from "react";
import "./History.css";
import Select from "react-select";
import axios from "axios";
import API_URL from "../../src/Config";
import { IoSearchOutline } from "react-icons/io5";
import moment from "moment-timezone";
import { FiDownload } from "react-icons/fi";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
function History({ isSidebarExpanded }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [historys, setHistorys] = useState([]);
  const [equipmentDetails, setEquipmentDetails] = useState([]);
  const [isDropdownAtTop, setIsDropdownAtTop] = useState(false);
  const { theme } = useContext(ThemeContext);

  // Fetch options for the dropdown (Equipment Tags)
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_equipment_tag_no`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const optionsData = response.data.Items.map((item) => ({
          value: item.ID,
          label: `${item.TagNo} (${item.Category})`,
        }));
        setOptions(optionsData);
        // Handle case where no options are returned
        if (optionsData.length === 0) {
          setHistorys([]);
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };
    fetchOptions();
  }, []);

  const handleChange = async (selectedOption) => {
    setSelectedOption(selectedOption);

    if (selectedOption) {
      try {
        setLoading(true);
        const authKey = localStorage.getItem("authKey");

        // Run both APIs in parallel
        const [maintenanceHistoryResponse, equipmentDetailsResponse] =
          await Promise.all([
            axios.get(
              `${API_URL}/api/get_maintenance_history_by_tag/${selectedOption.value}`,
              {
                headers: { Authorization: authKey },
              }
            ),
            axios.get(`${API_URL}/api/get_equipment/${selectedOption.value}`, {
              headers: { Authorization: authKey },
            }),
          ]);

        // Log responses to check the data structure
        console.log(
          "Maintenance History Response:",
          maintenanceHistoryResponse.data
        );
        console.log(
          "Equipment Details Response:",
          equipmentDetailsResponse.data
        );

        // Check if responses are valid
        if (maintenanceHistoryResponse.data && equipmentDetailsResponse.data) {
          setHistorys(maintenanceHistoryResponse.data);
          setEquipmentDetails(equipmentDetailsResponse.data[0]); // Ensure this is the correct structure
        } else {
          // Handle cases where data may be null or undefined
          setHistorys([]);
          setEquipmentDetails(null);
        }
        setIsDropdownAtTop(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setHistorys([]); // Clear history in case of an error
        setEquipmentDetails(null); // Clear equipment details in case of error
      } finally {
        setLoading(false);
      }
    } else {
      // Clear both history and equipment details when no option is selected
      setHistorys([]);
      setEquipmentDetails(null);
    }
  };

  const formatDate = (dateString) => {
    try {
      return moment.utc(dateString).local().format("DD/MM/YYYY hh:mm");
    } catch (error) {
      console.error("Error formatting date:", error);
      return "NA";
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    try {
      const authKey = localStorage.getItem("authKey");

      // Clear data if there's no search input
      if (searchInput.trim() === "") {
        setHistorys([]);
        setTotalItems(0);
        return;
      }

      // Call search_maintenance_history API if searchInput is not empty
      const searchHistoryResponse = await axios.get(
        `${API_URL}/api/search_maintenance_history`,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query: searchInput,
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      if (searchHistoryResponse.status === 200) {
        const data = searchHistoryResponse.data.Items || [];
        setHistorys(data);
        setTotalItems(searchHistoryResponse.data.TotalItems || 0);
        setPage(searchHistoryResponse.data.CurrentPage || 1);
      } else {
        console.error(
          "Error fetching search data:",
          searchHistoryResponse.statusText
        );
        setHistorys([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setHistorys([]);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      handleSearch(page, itemsPerPage);
    }, 600);

    return () => clearTimeout(delay);
  }, [searchInput, page, itemsPerPage]);

  const formatDates = (dateString) => {
    if (!dateString || isNaN(Date.parse(dateString))) {
      return "NA";
    }

    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return formatter.format(date);
  };

  const noDataMessage = selectedOption ? (
    <span>
      There is no Maintenance History found for the selected{" "}
      <span style={{ color: "black", fontWeight: "bold" }}>
        {selectedOption.label}
      </span>{" "}
      tag no.
    </span>
  ) : (
    "No data available."
  );

  const handleViewAttachment = (previewUrl) => {
    // Open the attachment in a new tab
    if (previewUrl) {
      window.open(previewUrl, "_blank");
    } else {
      console.error("Preview URL not available");
    }
  };

  // const handlePrint = () => {
  //   window.print();
  // };

  // const handlePrint = () => {
  //   const printContent = document.getElementById("printableArea").innerHTML;
  //   const newWindow = window.open("", "_blank");
  //   newWindow.document.write(`
  //     <html>
  //       <head>
  //         <title>Print</title>

  //       </head>
  //       <body>
  //         ${printContent}
  //       </body>
  //     </html>
  //   `);
  //   newWindow.document.close();
  //   newWindow.print();
  //   newWindow.close();
  // };

  const handlePrint = () => {
    const printContent = document.getElementById("printableArea").innerHTML;
    const newWindow = window.open("", "_blank");
    newWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            /* Copy styles from your existing stylesheet */
            ${Array.from(document.styleSheets)
              .map((sheet) => {
                try {
                  return Array.from(sheet.cssRules)
                    .map((rule) => rule.cssText)
                    .join("\n");
                } catch (e) {
                  return ""; // Ignore styles from other domains
                }
              })
              .join("\n")}
          </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.print();
    newWindow.close();
  };

  return (
    <div
      className={`data-list-content-container page-content ${
        isSidebarExpanded ? "expanded" : "collapsed"
      }`}
    >
      <div className="data-list-header">
        <div className="data-list-header-content">
          <div className="data-list-heading-content-left-side">
            History Card
          </div>
          <div className="data-list-heading-content-right-sides">
            {historys.length > 0 && (
              <div className="print-156">
                <button onClick={handlePrint} className="print-button">
                  Print
                </button>
              </div>
            )}
            <div className="search-bar-container-history">
              <input
                type="text"
                placeholder="Search History..."
                value={searchInput}
                onChange={handleSearchInputChange}
                className="search-bar"
              />
              <IoSearchOutline className="search-bar-icon" />
            </div>
          </div>
        </div>
        <div className="header-separator"></div>
      </div>
      <div className="body-separators"></div>
      <div className="dropdown-container-history-body">
        <div
          className={`dropdown-container-history ${
            isDropdownAtTop ? "at-top" : "centered"
          }`}
        >
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            placeholder="Please enter tag no"
            isClearable
            className="dropdown-container-history" // Keeping your custom class for layout styling
            classNamePrefix="custom"
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: theme === "dark" ? "black" : "#f0f8ff", // Background color based on theme
                color: theme === "dark" ? "#fff" : "#333", // Text color based on theme
                borderColor: theme === "dark" ? "#444" : "#ccc", // Border color based on theme
                cursor: "pointer",
                boxShadow: "none",
                height: "62.3px", // Set the height
                padding: "12px", // Set the padding
                width: "400px", // Set the width
                borderRadius: "20px", // Set the border radius
                "&:hover": {
                  borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                },
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: theme === "dark" ? "#000" : "#fff", // Background color for dropdown menu based on theme
                zIndex: 1000, // Add high z-index for dropdown menu
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused
                  ? theme === "dark"
                    ? "#666" // Gray when hovered in dark theme
                    : "lightblue" // Light blue when hovered in light theme
                  : theme === "dark"
                  ? "#000"
                  : "#fff", // Background color for options based on theme
                color: state.isFocused
                  ? "#fff"
                  : theme === "dark"
                  ? "#fff"
                  : "#333", // Text color
                cursor: "pointer",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: theme === "dark" ? "#fff" : "#333", // Selected option text color
              }),
            }}
          />
        </div>
      </div>
      <div className="data-list-bodys">
        {historys.length === 0 && !loading && (
          <div className="no-data-containers">
            <div className="no-data-message">{noDataMessage}</div>
          </div>
        )}

        <div id="printableArea">
          <div className="action-purchase-header">
            <div className="action-purchase-header-content">
              {selectedOption &&
                equipmentDetails &&
                Object.keys(equipmentDetails).length > 0 && (
                  <div className="project-detailss">
                    <div className="project-detail-arround">
                      <div className="project-details-left-side">
                        <div className="project-detail">
                          <div className="detail-header">Category:</div>
                          <div className="detail-part">
                            {equipmentDetails.Category || "NA"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Tag No:</div>
                          <div className="detail-part">
                            {equipmentDetails.TagNo || "NA"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Make:</div>
                          <div className="detail-part">
                            {equipmentDetails.Make || "NA"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Model:</div>
                          <div className="detail-part">
                            {equipmentDetails.Model || "NA"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">
                            Year Of Installation:
                          </div>
                          <div className="detail-part">
                            {equipmentDetails.InstallationYear
                              ? formatDates(equipmentDetails.InstallationYear)
                              : "NA"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">
                            Last Maintenance Date:
                          </div>
                          <div className="detail-part">
                            {equipmentDetails.LastMaintenanceDate
                              ? formatDates(
                                  equipmentDetails.LastMaintenanceDate
                                )
                              : "NA"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Location:</div>
                          <div className="detail-part">
                            {equipmentDetails.Location || "NA"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Description:</div>
                          <div className="detail-part">
                            {equipmentDetails.Description || "NA"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>

          {historys.length > 0 && (
            <div className="data-list-scroll-bodys-history">
              <div className="data-list-table-reports">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : (
                  <table className="table multilevel-data">
                    <thead className="data-list-head">
                      <tr>
                        <th>SR. NO.</th>
                        <th>TAG NO</th>
                        <th>ACTIVITY</th>
                        <th>SCHEDULE TYPE</th>
                        <th>SERVICE DATE</th>
                        <th>DATE & TIME</th>
                        <th>ATTACHMENT</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {historys.map((item, index) => (
                        <tr key={item.id} className="hoverable-row">
                          <td>{index + 1}</td>
                          <td>{item.TagNo || "NA"}</td>
                          <td>{item.activity || "NA"}</td>
                          <td>{item.schedule_type || "NA"}</td>
                          <td>{formatDates(item.service_date || "NA")}</td>
                          <td>{formatDate(item.time || "NA")}</td>
                          <td>
                            {item.attachments && item.attachments.length > 0 ? (
                              item.attachments.map((file) => (
                                <div
                                  key={file.file_id}
                                  className="attachment-items"
                                >
                                  {" "}
                                  <span
                                    onClick={() =>
                                      handleViewAttachment(file.preview_url)
                                    }
                                    style={{ cursor: "pointer", color: "blue" }}
                                  >
                                    {file.filename.length > 15
                                      ? `${file.filename.substring(0, 15)}...`
                                      : file.filename}
                                    ({file.file_size} KB)
                                  </span>
                                  <a
                                    href={`${API_URL}/api/download_attachment/${file.file_id}/${file.file_type}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download // Force download
                                  >
                                    <FiDownload className="download-icon" />
                                  </a>
                                </div>
                              ))
                            ) : (
                              <span>No attachments available</span> // Keep this line if you want to indicate that there are no attachments
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default History;
