import { useEffect, useRef } from "react";

const useClickOutside = (refsArray = [], handler, delay = 0) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Clear previous timeout to prevent memory leaks
      clearTimeout(timeoutRef.current);

      if (delay > 0) {
        timeoutRef.current = setTimeout(() => {
          // Check if the click happened outside of all provided refs
          const isOutside = refsArray.every((ref) => ref.current && !ref.current.contains(event.target));

          // If the click was outside all refs, call the handler
          if (isOutside) {
            handler();
          }
        }, delay);
      } else {
        // If no delay, handle the outside click immediately
        const isOutside = refsArray.every((ref) => ref.current && !ref.current.contains(event.target));

        if (isOutside) {
          handler();
        }
      }
    };

    // Add the event listener for mouse clicks and touch events
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      clearTimeout(timeoutRef.current); // Clear timeout on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [refsArray, handler, delay]);
};

export default useClickOutside;
