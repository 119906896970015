import React, { useState, useEffect, useRef } from "react";
import "./ElecticalDepartment.css";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Select, { components } from "react-select";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../Config";
import DatePicker from "react-datepicker";
import { Button, TextField } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { IconButton, Typography, Modal, Paper } from "@mui/material";

function ElectricalDepartment({ isSidebarExpanded }) {
  const { id } = useParams();
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [dueToday, setDueToday] = useState(0); // State for due today
  const [dueTomorrow, setDueTomorrow] = useState(0); // State for due tomorrow
  const [statusCounts, setStatusCounts] = useState({});
  const [totalSchedules, setTotalSchedules] = useState(null);
  const [dueTodaySchedules, setDueTodaySchedules] = useState([]);
  const [dueTomorrowSchedules, setDueTomorrowSchedules] = useState([]);
  const navigate = useNavigate();
  
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);

  const handleOpenModal = (schedule) => {
    setSelectedSchedule(schedule);
    console.log("Selected Schedule:", schedule);
    setModalOpen(true);
  };

  const fetchStatusData = async (ID) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/scheduler_summary_by_department/${ID}`, {
        headers: { Authorization: authKey },
      });
      const data = response.data;
      if (data) {
        // Update state with fetched data
        setDueToday(data.due_today || 0);
        setDueTomorrow(data.due_tomorrow || 0);
        setStatusCounts(data.status_counts || {});
        setTotalSchedules(data.total_schedules || null);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchStatusData(id);
    }
  }, [id]);

  const handleCloseModal = () => {
    setSelectedSchedule(null);
    setModalOpen(false);
  };


  const filterOptions = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Tomorrow", value: "tomorrow" },
    { label: "Next 7 Days", value: "next7days" },
    { label: "Current Week", value: "current_week" },
    { label: "Past Week", value: "past_week" },
    { label: "Current Month", value: "current_month" },
    { label: "Custom Date", value: "custom_date" },
  ];

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleFilterClick = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      // Format the selected start and end dates
      const formattedStartDate = selectedStartDate
        ? new Date(selectedStartDate.getTime() + 86400000)
          .toISOString()
          .split("T")[0]
        : null;
      let formattedEndDate = selectedEndDate
        ? new Date(selectedEndDate.getTime() + 86400000)
          .toISOString()
          .split("T")[0]
        : null;

      const response = await axios.get(`${API_URL}/api/filter_scheduler`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        },
      });

      if (response.status === 200) {
        setSchedules(response.data);
      } else {
        console.error("Error fetching filtered schedule:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching filtered schedule:", error.message);
    }
  };

  const handleFilterScheduler = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      let apiUrl = `${API_URL}/api/get_scheduler`; // Default API URL for "All" filter

      // Update API URL based on selected filter
      if (selectedFilter !== "all" && selectedFilter !== "custom_date") {
        apiUrl = `${API_URL}/api/filter_scheduler_by_filter_option`;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          filter_option: selectedFilter,
        },
      });

      if (response.status === 200) {
        setSchedules(response.data);
      } else {
        console.error("Error fetching scheduler data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
    }
  };

  useEffect(() => {
    if (selectedFilter && selectedFilter !== "custom_date") {
      handleFilterScheduler();
    } else if (selectedFilter === "custom_date" && selectedStartDate && selectedEndDate) {
      handleFilterClick();
    }
  }, [selectedFilter, selectedStartDate, selectedEndDate]);

  const containerRef = useRef(null);


  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };


  const fetchData = async (ID) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_scheduler_by_department/${ID}`, {
        headers: {
          Authorization: authKey,
        },
      });

      setSchedules(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchData(id); // Fetch data for the department with the given ID
    }
  }, [id]);



  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? schedules.map((schedule) => schedule.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (scheduleID) => {
    const updatedSelectedRows = selectedRows.includes(scheduleID)
      ? selectedRows.filter((id) => id !== scheduleID)
      : [...selectedRows, scheduleID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === schedules.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleArchiveschedule = async () => { };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDeparment = () => {
    navigate("/archive-sub-department");
  };

  const handleRefresh = () => {
    fetchData(id);
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleViewClick = async (scheduler_id) => {
    navigate(`/scheduler-view/${scheduler_id}`);
  };
  const uniqueCategories = Array.from(new Set(schedules.map(schedule => schedule.department || "NA")));

  const fetchSchedulesByStatus = async (statusCounts) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_scheduler_by_status/${statusCounts}`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setSchedules(response.data);
      } else {
        console.error("Error fetching schedules by status:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching schedules by status:", error.message);
    }
  };
  useEffect(() => {
    fetchSchedulesByStatus();
  }, []);
  const handleStatusChange = (status) => {
    console.log(`Status clicked: ${status}`);
    setSelectedStatus(status);
    fetchSchedulesByStatus(status);
  };

  const fetchSchedulesByDateType = async (dateType) => {
    console.log(`Fetching schedules for ${dateType}`);
    try {
      const authKey = localStorage.getItem("authKey");
      console.log(`AuthKey: ${authKey}`);
      const apiUrl = `${API_URL}/api/get_scheduler_by_date/${dateType}`;
      console.log(`API URL: ${apiUrl}`);
  
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        console.log(`Fetched schedules for ${dateType}:`, response.data);
        if (dateType === 'today') {
          setDueTodaySchedules(response.data);
        } else if (dateType === 'tomorrow') {
          setDueTomorrowSchedules(response.data);
        }
        setSchedules(response.data);
      } else {
        console.error(`Error fetching schedules for ${dateType}:`, response.statusText);
      }
    } catch (error) {
      console.error(`Error fetching schedules for ${dateType}:`, error.message);
    }
  };
  
  const handleRadioChange = (event) => {
    const status = event.target.value;
    handleStatusChange(status);
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveschedule}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              // uniqueCategories.map((department, index) => (
                <div className="data-list-header">

                  <div className="data-list-header-content">
                  {uniqueCategories.map((department, index) => (
                    <div className="">
                    <div className="data-list-heading-content-left-side">
                      {department}
                    </div>
                    </div>
                  ))}
                    <div className="data-list-heading-content-right-side">
                      <div className="project-ridio-status">
                        <label>
                          <input
                            type="radio"
                            value="active"
                            checked={selectedStatus === "active"}
                            onChange={handleRadioChange}
                          />
                          Active
                        </label>
                        <label>
                          <input
                            type="radio"
                            value="completed"
                            checked={selectedStatus === "completed"}
                            onChange={handleRadioChange}
                          />
                          Completed
                        </label>
                        <label>
                          <input
                            type="radio"
                            value="hold"
                            checked={selectedStatus === "hold"}
                            onChange={handleRadioChange}
                          />
                          Hold
                        </label>
                        <label>
                          <input
                            type="radio"
                            value="Cancelled"
                            checked={selectedStatus === "Cancelled"}
                            onChange={handleRadioChange}
                          />
                          Cancelled
                        </label>
                        <label>
                          <input
                            type="radio"
                            value="overdue"
                            checked={selectedStatus === "overdue"}
                            onChange={handleRadioChange}
                          />
                          Overdue
                        </label>
                        <label>
                          <input
                            type="radio"
                            value="not in tenure"
                            checked={selectedStatus === "not in tenure"}
                            onChange={handleRadioChange}
                          />
                          not in tenure
                        </label>
                      </div>
                      <div className="project-dropdown">
                        <select
                          value={selectedFilter}
                          onChange={handleFilterChange}
                          className="filter-dropdown"
                        >
                          {filterOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      {selectedFilter === "custom_date" && (
                        <div className="input-group-datepickers">
                          <DatePicker
                            placeholderText="Select Date Range"
                            className="dates"
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            selectsRange={true}
                            onChange={(dates) => {
                              const [start, end] = dates;
                              setSelectedStartDate(start);
                              setSelectedEndDate(end);
                            }}
                            renderInput={(startProps, endProps) => (
                              <>
                                <TextField {...startProps} variant="standard" />
                                <TextField {...endProps} variant="standard" />
                              </>
                            )}
                            startText="Start Date"
                            endText="End Date"
                          />
                        </div>
                      )}
                      <div className="search-bar-container">
                        <input
                          type="text"
                          placeholder="Search ..."
                          value={searchInput}
                          onChange={handleSearchInputChange}
                          className="search-bar"
                        />
                        <IoSearchOutline className="search-bar-icon" />
                      </div>

                      <Tooltip title="Refresh List" arrow>
                        <button
                          className="refresh-button"
                          onClick={handleRefresh}
                        >
                          <TbRefresh className="refresh-icon" />
                        </button>
                      </Tooltip>
                      <button className="sort-button" onClick={handleSortpopup}>
                        <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                      </button>
                      {isSortPopup && (
                        <div className="sort-popup-container">
                          <div className="sort-popup-content">
                            <div className="sort-by">
                              SORT BY{" "}
                              {isAscending ? "(Ascending)" : "(Descending)"}
                            </div>
                            <div className="sort-by-button"></div>
                            {/* <button className='delete-item' onClick={handleArchiveDeparment}>Archived Sub Department</button> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              // ))
            )}
            <div>
             
                <div className="entity-dashboard">
                  <div className="entity-dashboard-contant">
                    <div className="entity-dashboard-contant-item">
                      <div className="entity-icon"></div>
                      <div className="entity-text-left">
                        <div className="entity-text">
                          <div className="entity-text-title">Total Schedules</div>
                          <div className="entity-text-title-total" onClick={fetchStatusData}>
                            {totalSchedules || "0"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="entity-text-right">
                      <div className="entity-text">
                        <div className="entity-text-title" onClick={() => fetchSchedulesByDateType('today')}>Due Today</div>
                        <div className="entity-text-title-total">
                          {dueToday || "0"}
                        </div>
                      
                      </div>{" "}
                      <div className="entity-text">
                        <div className="entity-text-title" onClick={() => fetchSchedulesByDateType('tomorrow')}>Due Tomorrow</div>
                        <div className="entity-text-title-total">
                          {dueTomorrow || "0"}
                        </div>
                       
                      </div>
                      <div className="entity-text">
                        <div className="entity-text-title">Overdue</div>
                        <div className="entity-text-title-total" onClick={() => handleStatusChange('overdue')}>{statusCounts.overdue || "0"}</div>
                      </div>

                      <div className="entity-text">
                        <div className="entity-text-title">Completed</div>
                        <div className="entity-text-title-total" onClick={() => handleStatusChange('completed')}>
                          {statusCounts.Completed || "0"}
                        </div>
                      </div>{" "}
                      <div className="entity-text">
                        <div className="entity-text-title">Hold</div>
                        <div className="entity-text-title-total" onClick={() => handleStatusChange('hold')}>{statusCounts.Hold || "0"}</div>
                      </div>
                      <div className="entity-text">
                        <div className="entity-text-title">Active</div>
                        <div className="entity-text-title-total" onClick={() => handleStatusChange('active')}>{statusCounts.active || "0"}</div>
                      </div>
                      <div className="entity-text">
                        <div className="entity-text-title">Cancelled</div>
                        <div className="entity-text-title-total" onClick={() => handleStatusChange('Cancelled')}>{statusCounts.Cancelled || "0"}</div>
                      </div>
                      <div className="entity-text">
                        <div className="entity-text-title">not in tenure</div>
                        <div className="entity-text-title-total" onClick={() => handleStatusChange('not in tenure')}>{statusCounts['not in tenure'] || "0"}</div>
                      </div>

                    </div>
                  </div>
                </div>
            

              {!loading && schedules.length > 0 && (
                <div className="data-list-body" onClick={closePopups}>
                  <div className="data-list-scroll-bodyss">
                    <div className="data-list-tables">
                      {loading ? (
                        <div className="loader-container">
                          <div className="loader"></div>
                          <div>Loading...</div>
                        </div>
                      ) : schedules.length > 0 ? (
                        <table className="table multilevel-data">
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </th>
                              <th>SR. NO.</th>
                              <th>CONTRACTOR NAME</th>
                              <th>CATEGORY</th>
                              <th>TAG NO</th>
                              <th>OISD POINT</th>
                              <th>LOCATION</th>
                              <th>STATUS</th>
                              <th>SERVICE DUE DATE</th>
                              <th>LAST SERVICED DATE</th>

                              <th></th>
                            </tr>
                          </thead>
                          <tbody className="data-list-scroll-bodys">
                            {schedules.map((schedule, index) => (
                              <tr key={index} className="hoverable-row">
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedRows.includes(schedule.id)}
                                    onChange={() => handleSelectRow(schedule.id)}
                                  />
                                </td>

                                <td>{index + 1}</td>
                                <td>{schedule.contractor_details.contractor_name || "NA"}</td>
                                <td>{schedule.category || "NA"}</td>
                                <Tooltip title="Click here to show details">
                                  <td onClick={() => handleOpenModal(schedule)}>
                                    {schedule.equipment_tag_no || "NA"}
                                  </td>
                                </Tooltip>
                                <td>{schedule.oisd_point || "NA"}</td>
                                <td>{schedule.location || "NA"}</td>
                                <td className="status-schedules">
                                  <div style={{
                                    backgroundColor:
                                      schedule.status === "Hold" ? "#D4AC0D" :
                                        schedule.status === "Cancelled" ? "#F81700" :
                                          schedule.status === "not in tenure" ? "#313639" :
                                            schedule.status === "overdue" ? "#C0612B" :
                                              schedule.status === "active" ? "#2980B9" :
                                                schedule.status === "Completed" ? "#2E8B57" : "transparent"
                                  }}>
                                    {schedule.status || "NA"}
                                  </div>
                                </td>
                                <td>{schedule.service_due_date || "NA"}</td>
                                <td>{schedule.last_serviced_date || "NA"}</td>

                                <td>
                                  <button
                                    onClick={() => handleViewClick(schedule.id)}
                                    className="view-button"
                                  >
                                    View
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="no-users-container">
                          <div className="no-users-message">No Schedules Found</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Paper className="modal-container">
            <div className="modal-header">
              <Typography variant="h6">Equipment Details</Typography>
            </div>
            <div className="modal-body">
              {selectedSchedule && (
                <div>
                  <p>Make: {selectedSchedule.Make}</p>
                  <p>Model: {selectedSchedule.Model}</p>
                  <p>
                    Year of Installation: {selectedSchedule.InstallationYear}
                  </p>
                  <p>Warranty Expiry: {selectedSchedule.WarrantyExp}</p>
                  {/* Add more details as needed */}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <Button variant="contained" onClick={handleCloseModal}>
                Close
              </Button>
            </div>
          </Paper>
        </Modal>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ElectricalDepartment;
