import React, { useState, useEffect, useRef } from "react";
import "./UserView.css";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { HiOutlinePlus } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { MdOutlineModeEditOutline } from "react-icons/md";


function ProjectView({ isSidebarExpanded }) {
    const [projects, setProjects] = useState([]);
    const [selectedUserID, setSelectedUserID] = useState(null);
    const [selectedProjectID, setSelectedProjectID] = useState(null);
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const [projectData, setProjectData] = useState(null);

    const { projectID } = useParams();

    const handleBackView = () => {
        navigate("/project")
    };

    const handleEdit = () => {
        if (selectedProjectID) {
            navigate(`/edit-project/${selectedProjectID}`);
        } else {
            navigate(`/edit-project/${projectID}`);
        }
    };


    const handleActualManpower = () => {
        if (selectedProjectID) {
            navigate(`/actualmanpower/${selectedProjectID}`);
        } else {
            navigate(`/actualmanpower/${projectID}`);
        }
    };

    const handleManpower = () => {
        if (selectedProjectID) {
            navigate(`/man-powers/${selectedProjectID}`);
        } else {
            navigate(`/man-powers/${projectID}`);
        }
    };


    const handleReportManpower = () => {
        if (selectedProjectID) {
            navigate(`/report-manpower/${selectedProjectID}`);
        } else {
            navigate(`/report-manpower/${projectID}`);
        }
    };

    const fetchData = async () => {
        try {

            const authKey = localStorage.getItem("authKey");

            const response = await axios.get(`${API_URL}/api/get_projects`, {
                headers: {
                    Authorization: authKey,
                },
            });

            setProjects(response.data.Projects);
            setSelectedUserID(projectID);

        } catch (error) {
            console.error("Error fetching user data:", error.message);

        }
    };

    useEffect(() => {
        fetchData();
      }, []);


    const fetchProjectData = async (projectID) => {
        try {

            const authKey = localStorage.getItem("authKey");
            const response = await axios.get(
                `${API_URL}/api/get_project/${projectID}`,
                {
                    headers: {
                        Authorization: authKey,
                    },
                }
            );
            setProjectData(response.data);
            setSelectedUserID(projectID);
            setSelectedProjectID(projectID);
        } catch (error) {
            console.error("Error fetching user data:", error.message);

        }
    };
    
    const togglePopup = async () => {
        try {

            const authKey = localStorage.getItem("authKey");
            const response = await axios.get(
                `${API_URL}/api/get_project/${projectID}`,
                {
                    headers: {
                        Authorization: authKey,
                    },
                }
            );
            setProjectData(response.data);
            setSelectedUserID(projectID);
            setSelectedProjectID(projectID);

        } catch (error) {
            console.error("Error fetching user data:", error.message);

        }
    };
    useEffect(() => {
        togglePopup();
    }, []);
    

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formatter = new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
        });
        return formatter.format(date);
    };

    const handleClose = () => {
        // onClose();
        navigate("/project")
      }


    return (
        <>
            <div
                className={`main-container page-content${isSidebarExpanded ? "expanded" : "collapsed"
                    }`}
            >
                <div
                    className={`data-lists-content-container page-contents ${isSidebarExpanded ? "expanded" : "collapsed"
                        }`}
                >
                    <div className="data-list-content" ref={containerRef}>
                            <div className="data-list-header">
                                <div className="data-list-header-content">
                                    <div className="data-list-heading-content-left-side">
                                        All Projects
                                    </div>
                                    <div className="data-list-heading-content-right-side">
                                        <Link to="/project-form" className="data-list-new">
                                            <Tooltip title="New User" arrow>
                                                <button className="new-button">
                                                    <HiOutlinePlus className="new-plus-icon" />
                                                </button>
                                            </Tooltip>
                                        </Link>


                                    </div>
                                </div>
                            </div>
                      
                        <div className="data-list-body">
                            <div className="data-list-scroll-body">
                                <div className="data-list-table">



                                    <table className="table multilevel-data">
                                        <thead>
                                            <tr>
                                            <th>SR. NO.</th>
                                                <th>NAME</th>
                                            </tr>
                                        </thead>
                                        <tbody className="data-list-scroll-bodys">
                                            {projects.map((project, index) => (
                                                <tr key={project.ID} className="hoverable-row">
                                                     <td>{index + 1}</td>

                                                    <td onClick={() => fetchProjectData(project.id)} className={selectedUserID === project.id ? 'selected-user' : ''} style={{ cursor: "pointer", color: "blue" }}>
                                                        {project.project_name || "NA"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="data-view-popup">
                    <div className="data-popup-content">
                        <div className="data-view">
                            <div className="data-view-contant">
                                <div className="data-view-header">
                                    <div className="data-view-header-left-side">
                                        {projectData && (
                                            <div className="user-header-title">
                                                <div>
                                                    <Tooltip title="Back" arrow>
                                                        <IoMdArrowBack className="back-company" onClick={handleBackView} />
                                                    </Tooltip>
                                                    {projectData.project_name} {/* Assuming 'Name' is the correct property */}
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                    <div className="user-view-header-right-side">
                                        <div className="header-title-edit">
                                            <button className='edit-header-button' onClick={handleManpower}>Man Power requirment</button>
                                            <button className='edit-header-button' onClick={handleActualManpower}>Actual Manpower</button>
                                            <button className='edit-header-button' onClick={handleReportManpower}>Report Man Power</button>
                                            <button className='edit-header-button' onClick={handleEdit}><MdOutlineModeEditOutline className="edit-view-icon" />Edit</button>
                                            <div className="view-close-button" onClick={handleClose} >
                        <IoCloseOutline />
                      </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="user-view-body">
                                    <div className="rows">
                                        {projectData && (
                                            <div className="user-view-body-container">
                                                <div className="user-view-body-container-content">
                                                    <div className="text-medium-header">Project Details</div>
                                                    <div className="text-medium-body">
                                                        <div className="row">
                                                            <label htmlFor="">Project Name</label>
                                                            <div className="answer">{projectData.project_name || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Customer Name</label>
                                                            <div className="answer">{projectData.customer_name || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">PO Number</label>
                                                            <div className="answer">{projectData.po_number || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Project Type</label>
                                                            <div className="answer">{projectData.project_type || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Start date</label>
                                                            <div className="answer">{formatDate(projectData.start_date || "NA")}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">End date</label>
                                                            <div className="answer">{formatDate(projectData.end_date || "NA")}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Location</label>
                                                            <div className="answer">{projectData.customer_location || "NA"}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
          
        </>
    );
}

export default ProjectView;
