import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";

function EditEquipmentType({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [equipmentData, setEquipmentData] = useState({
    Name: "",
  });

  const [departments, setDepartments] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);
  const [severity, setSeverity] = useState("success")
  const [error, setError] = useState(null);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [equipmentTypeData, setEquipmentTypeData] = useState({
    EquipmentType: "",
    DepartmentName: "",
    DepartmentId: "",
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchEquipmentTypeData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_equipment_types/${ID}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const responseData = response.data[0];
        const formattedData = {
          Department: responseData.DepartmentName,
          Name: responseData.EquipmentType,
        };
  
        const departmentOptions = response.data.map((department) => ({
          value: department.department_id,
          label: department.DepartmentName,
        }));
        setDepartments(departmentOptions);
  
        // Set the selected department based on the API response
        const selectedDepartment = departmentOptions.find(
          (dept) => dept.value === responseData.department_id
        );
        setSelectedDepartment(selectedDepartment);
  
        setEquipmentData(formattedData);
  
        // Set the selected sub-department based on the API response if it exists
        if (responseData.sub_department_id) {
          const selectedSubDept = {
            value: responseData.sub_department_id,
            label: responseData.SubDepartmentName,
          };
          setSelectedSubDepartment(selectedSubDept);
        }
      } catch (error) {
        console.error("Error fetching equipment type data:", error.message);
        setError("An error occurred while fetching equipment type data");
      }
    };
  
    fetchEquipmentTypeData();
  }, [ID]);
  

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const departmentOptions = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(departmentOptions);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEquipmentData({ ...equipmentData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    navigate("/equipment-type");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    // Check for empty fields
    const errors = {};
    let error = false;
    Object.keys(equipmentData).forEach( (key) => {
      if (!equipmentData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning")
        showSnackbar("Required fields are empty");
        error = true;
      } 
    });

    if(error === false) {

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_equipment_type/${ID}`,
        {
          ...equipmentData,
          DepartmentId: selectedDepartment.value, 
        SubDepartmentId: selectedSubDepartment.value,
        SubDepartmentName: selectedSubDepartment.label,
        },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success")
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate("/equipment-type");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating company:", error.message);
      setError("An error occurred while updating company data");
      setIsLoading(false);
    }
  }
  };

  const handleBackProject = () => {
    navigate("/equipment-type");
  };

  const handleDepartmentChange = async (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setEquipmentTypeData((prevFormData) => ({
      ...prevFormData,
      DepartmentName: selectedOption.label,
      DepartmentId: selectedOption.value,
    }));

    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_sub_department/${selectedOption.value}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((subDepartment) => ({
          label: subDepartment.Name,
          value: subDepartment.id,
        }));
        setSubDepartments(options);
      } else {
        console.error("Failed to fetch sub-departments");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching sub-departments:",
        error
      );
    }
  };

  const handleSubDepartmentChange = (selectedOption) => {
    setSelectedSubDepartment(selectedOption);
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Equipment Type
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    options={departments}
                    placeholder="Select a department"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubDepartment">
                    Sub Department <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="subDepartment"
                    value={selectedSubDepartment}
                    onChange={handleSubDepartmentChange}
                    options={subDepartments}
                    placeholder="Select a Sub Department"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Name">
                    Equipment Type<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Name"
                      value={equipmentData.Name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditEquipmentType;
