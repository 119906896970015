import React, { useState, useEffect } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import AddDepartmentPopup from "../Popups/AddDepartmentPopup";
import Select, { components } from "react-select";
import { CiCirclePlus } from "react-icons/ci";
const { Control, Menu } = components;

function SidePettyCashForm({ isSidebarExpanded }) {
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);
    const [showDepartmentPopup, setShowDepartmentPopup] = useState(false);
    const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
    const [departments, setDepartments] = useState([]);

    const [sidePettyCashData, setSidePettyCashData] = useState({
        department_id: "",
        department_name: "",
        user_id: "",
        user_name: "",
        ammount: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSidePettyCashData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevState) => ({
            ...prevState,
            [name]: "", // Clear the error message for this field
        }));
    };

    const handleCancelClick = () => {
        navigate("/department");
    };

    const handleSaveClick = async () => {
        const errors = {};
        let error = false;
        Object.keys(sidePettyCashData).forEach((key) => {
            if (!sidePettyCashData[key]) {
                errors[key] = `${key} is required`;
                setSeverity("warning");
                error = true;
            }
        });

        if (error) {
            showSnackbar("Required fields are empty");
            return; // Prevent further execution if there are validation errors
        }

        try {
            setIsLoading(true);
            const authKey = localStorage.getItem("authKey");

            const response = await axios.post(
                `${API_URL}/api/`,
                sidePettyCashData, // Send departmentData directly
                {
                    headers: {
                        Authorization: authKey,
                    },
                }
            );

            console.log(response.data.message);
            if (response.status === 200) {
                setSeverity("success");
                showSnackbar("Department added successfully");
                setTimeout(() => {
                    navigate("/department");
                }, 1000);
            }
        } catch (error) {
            console.error("Error saving department:", error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleSaveClick();
        }
      };


    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const fetchDepartment = async () => {
        try {
            const authKey = localStorage.getItem("authKey");
            const apiUrl = `${API_URL}/api/get_departments`;
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authKey,
                },
            });
            if (response.ok) {
                const data = await response.json();
                const options = data.map((department) => ({
                    label: department.DepartmentName,
                    value: department.ID,
                }));
                setDepartments(options);
            } else {
                console.error("Failed to fetch departments");
            }
        } catch (error) {
            console.error("An error occurred while fetching departments:", error);
        }
    };

    useEffect(() => {
        fetchDepartment();
    }, []);

    const handleDepartmentMenuOpen = () => {
        setDepartmentMenuIsOpen(true);
    };
    const handleDepartmentChange = (selectedOption) => {
        setSelectedDepartment(selectedOption);
        setSidePettyCashData((prevUserData) => ({
            ...prevUserData,
            department_id: selectedOption ? selectedOption.value : "",
            department_name: selectedOption ? selectedOption.label : "",
        }));
    };

    const CustomControl = ({ children, ...props }) => (
        <Control {...props}>{children}</Control>
    );
    const CustomMenu = ({ children, ...props }) => (
        <Menu {...props}>
            {children}
            <button
                type="button"
                className="add-company-button"
                onClick={handleAddNewDepartment}
            >
                <CiCirclePlus className="new-plus-circle" />
                Add New Department
            </button>
        </Menu>
    );
    const handleAddNewDepartment = () => {
        setShowDepartmentPopup(true);
        setDepartmentMenuIsOpen(false);
    };

    const closeDepartmentPopup = (newDepartment) => {
        setShowDepartmentPopup(false);
        if (newDepartment) {
            setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
            setSelectedDepartment(newDepartment);
            setSidePettyCashData((prevFormData) => ({
                ...prevFormData,
                department_id: newDepartment.value,
                department_name: newDepartment.label,
            }));
        }
    };

    const fetchUserData = async () => {
        try {
            const authKey = localStorage.getItem("authKey");

            const response = await axios.get(`${API_URL}/api/get_user`, {
                headers: {
                    Authorization: authKey,
                },
            });

            if (response.status === 200) {
                const data = response.data;
                const options = Array.isArray(data) ? data.map((user) => ({
                    label: user.Name,
                    value: user.ID,
                })) : [];
                setUsers(options);
            } else {
                console.error(`Error fetching user data: ${response.statusText}`);
                setUsers([]);
            }
        } catch (error) {
            console.error("Error fetching user data:", error.message);
            setUsers([]);
        }
    };


    useEffect(() => {
        fetchUserData();
    }, []);

    const CustomUserControl = ({ children, ...props }) => (
        <Control {...props}>{children}</Control>
    );
    const CustomUserMenu = ({ children, ...props }) => (
        <Menu {...props}>
            {children}
            <button
                type="button"
                className="add-company-button"
            // onClick={handleAddNewUser}
            >
                <CiCirclePlus className="new-plus-circle" />
                Add New User
            </button>
        </Menu>
    );

    const handleUserChange = (selectedOption) => {
        setSelectedUser(selectedOption);
        setSidePettyCashData((prevFormData) => ({
            ...prevFormData,
            user_name: selectedOption ? selectedOption.label : "",
            user_id: selectedOption ? selectedOption.value : "",
        }));
    };

    const handleUserMenuOpen = () => {
        setUserMenuIsOpen(true);
    };
    const handleNewDepartment = (newDepartment) => {
        if (newDepartment) {
            setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
            setSelectedDepartment(newDepartment);
            setSidePettyCashData((prevFormData) => ({
                ...prevFormData,
                DepartmentName: newDepartment.label,
                DepartmentId: newDepartment.value,
            }));
            showSnackbar("Department added successfully");
        }
    };
    return (
        <>
            <div
                className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
                    }`}
            >
                <div className="data-form-content">
                    <div className="data-form-header">
                        <div className="data-form-header-content">
                            <div className="data-form-heading-content-left-side">
                                New Side Petty Cash
                            </div>
                            <div className="data-form-heading-content-right-side"></div>
                        </div>
                    </div>
                    <div className="header-separator"></div>
                    <div className="data-form-body">
                        <form className="form-detail" onKeyDown={handleKeyDown}>
                            <div className="new-data-form">
                                <div className="form-group-select">
                                    <label htmlFor="Department">
                                        Department<span className="text-danger">*</span>
                                    </label>

                                    <Select
                                        id="department"
                                        options={departments}
                                        value={selectedDepartment}
                                        onChange={handleDepartmentChange}
                                        components={{ Control: CustomControl, Menu: CustomMenu }}
                                        placeholder="Select Department"
                                        isSearchable
                                        menuIsOpen={departmentMenuIsOpen}
                                        onMenuOpen={handleDepartmentMenuOpen}
                                        onMenuClose={() => setDepartmentMenuIsOpen(false)}
                                    />
                                </div>
                                <div className="form-group-select">
                                    <label htmlFor="user">
                                        User<span className="text-danger">*</span>
                                    </label>

                                    <Select
                                        id="user"
                                        options={users}
                                        value={selectedUser}
                                        onChange={handleUserChange}
                                        components={{ Control: CustomUserControl, Menu: CustomUserMenu }}
                                        placeholder="Select User"
                                        isSearchable
                                        menuIsOpen={userMenuIsOpen}
                                        onMenuOpen={handleUserMenuOpen}
                                        onMenuClose={() => setUserMenuIsOpen(false)}
                                    />

                                </div>

                                <div className="form-group">
                                    <label htmlFor="ammount">
                                        Amount<span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            name="ammount"
                                            value={sidePettyCashData.ammount}
                                            onChange={handleInputChange}
                                            placeholder="Enter ammount"
                                        />
                                        {errors.DepartmentName && (
                                            <div className="error-message">{errors.ammount}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="remark">
                                        Remarks<span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                        <textarea
                                            name="remark"
                                            value={sidePettyCashData.remark}
                                            onChange={handleInputChange}
                                            placeholder="Enter remarks"
                                        />
                                        {errors.DepartmentName && (
                                            <div className="error-message">{errors.remark}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                        {showDepartmentPopup && (
                            <div className="inquiry-customerperson-popup">
                                <AddDepartmentPopup
                                    closePopup={closeDepartmentPopup}
                                    setSelectedDepartment={setSelectedDepartment}
                                    handleNewDepartment={handleNewDepartment}
                                />
                            </div>
                        )}
                    </div>
                    <div className="footer">
                        <div className="button-group">
                            <button
                                className="data-form-save-button"
                                type="save"
                                onClick={handleSaveClick}
                                disabled={isLoading}
                            >
                                {isLoading ? "Saving..." : "Save"}
                            </button>
                            <button
                                className="data-form-cancel-button"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000} // Converted to milliseconds
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ marginTop: "35px", marginLeft: "20px" }}
            >
                <MuiAlert onClose={handleSnackbarClose} severity={severity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default SidePettyCashForm;
