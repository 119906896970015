import React, { useState, useEffect, useRef } from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import "./PreventiveReportView.css";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { IoMdArrowBack } from "react-icons/io";
import Pagination from "../Component/Pagination";

function ProcurementreportView({ isSidebarExpanded }) {
  const { department_id, pr_status, filter_option } = useParams();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_pr_report_details`, {
        headers: { Authorization: authKey },
        params: {
          department_id,
          filter_option,
          pr_status,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });
  
      console.log("API Response:", response.data);
  
      const prRequests = response.data.pr_requests || [];
      setReports(prRequests);
      setTotalItems(response.data.total || 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching procurement reports:", error.message);
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    const storedPage = localStorage.getItem("currentPage");
    const storedItemsPerPage = localStorage.getItem("itemsPerPage");
  
    const page = storedPage ? parseInt(storedPage, 10) : 0;
    const itemsPerPage = storedItemsPerPage ? parseInt(storedItemsPerPage, 10) : 50;
    setPage(page);
    setItemsPerPage(itemsPerPage);
  
    fetchData(page, itemsPerPage);
  }, [department_id, filter_option, pr_status, page, itemsPerPage]);
  
  
  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);

    // Store values in local storage
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };
  
  const containerRef = useRef(null);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const closePopups = () => {
    setIsSortPopup(false);
  };

  const handleRefresh = () => {
    fetchData();
  };

  const handleBackScheduler = () => {
    navigate("/reports?tab=procurement-reports");
  };
  
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'active':
        return '#2980B9';
      case 'cancelled':
        return '#F81700';
      case 'hold':
        return '#D4AC0D';
      case 'not in tenure':
        return '#313639';
      case 'overdue':
        return '#C0612B';
      case 'In Progress':
        return '#df7f26';
      case 'completed':
        return '#2E8B57';
      default:
        return 'black'; // Fallback color if needed
    }
  };
  const filterOptionMap = {
    all: 'All',
    today: 'Today',
    yesterday: 'Yesterday',
    tomorrow: 'Tomorrow',
    next7days: 'Next 7 Days',
    next_month: 'Next Month',
    current_week: 'Current Week',
    previous_month: 'Previous Month',
    past_week: 'Previous Week',
    current_month: 'Current Month',
    custom_date: 'Custom Date',

    // Add other mappings as needed
  };
  // Get the display text based on filter_option
  const displayText = filterOptionMap[filter_option] || filter_option;

  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    <Tooltip title="Back" arrow>
                      <IoMdArrowBack
                        className="back-company"
                        onClick={handleBackScheduler}
                      />
                    </Tooltip>
                    {reports.length > 0 && (
                      <>
                       <span className="report-name">Procurement Report</span>
                        <span className="status-show" style={{ color: getStatusColor(reports[0].status), fontWeight: '500' }}>
                          {reports[0].status ? reports[0].status.charAt(0).toUpperCase() + reports[0].status.slice(1).toLowerCase() : "0"}
                        </span>
                        <span className="department-show" style={{ color: 'green', marginLeft: '15px', fontWeight: '500' }}>
                          {reports[0].department_name ? reports[0].department_name.charAt(0).toUpperCase() + reports[0].department_name.slice(1).toLowerCase() : "0"}
                        </span>
                        <span className="option-show" style={{ color: '#ce9139ed', marginLeft: '15px', fontWeight: '500' }}>
                        {displayText}
                        </span>
                      </>
                    )}
                  </div>

                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search ..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button"></div>
                          {/* <button className='delete-item' onClick={handleArchiveDeparment}>Archived Sub Department</button> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-bodys">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) :   reports && Array.isArray(reports) && reports.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>SR. NO.</th>
                          <th>DEPARTMENT</th>
                          <th>PR TYPE</th>
                          <th>QUANTITY</th>
                          <th>STATUS</th>
                          <th>ESTIMATED UNIT RATE</th>
                          <th>ESTIMATED COST</th>
                          <th>PREFERRED VENDOR</th>


                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {reports.map((report, index) => (
                          <tr key={index} className="hoverable-row">
                            <td>{index + 1}</td>
                            <td>{report.department_name || "NA"}</td>
                            <td>{report.pr_type || "NA"}</td>
                            <td>{report.qty || "NA"}</td>
                            <td>{report.pr_status || "NA"}</td>
                            <td>{report.est_unit_rate || "NA"}</td>
                            <td>{report.est_cost || "NA"}</td>
                            <td>{report.preferred_vendor || "NA"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Procurement Reports Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ProcurementreportView;
