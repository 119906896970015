
import React, { useState, useEffect } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import Select, { components } from 'react-select';
import API_URL from "../../src/Config";
// import "./DepartmentPopup.css"
import { CiCirclePlus } from "react-icons/ci";
import axios from 'axios';
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
const { Control, Menu } = components;

const AddEquipmentType = ({ closePopup,handleNewEquipmentType,parentSelectedSubDepartment,parentSelectedDepartment }) => {
  const {theme} = useContext(ThemeContext)
   
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(parentSelectedDepartment);
    const [subDepartments, setSubDepartments] = useState([]);
    const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
    const [equipmentTypeData, setEquipmentTypeData] = useState({
      EquipmentType: '',
      DepartmentName: '',
      DepartmentId: '',
      SubDepartmentName: '',
      SubDepartmentId: '',
  });
    const [selectedSubDepartment, setSelectedSubDepartment] = useState(parentSelectedSubDepartment);
    const [subDepartmentMenuIsOpen, setSubDepartmentMenuIsOpen] = useState(false);
    const [showSubDepartmentPopup, setShowSubDepartmentPopup] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(''); // New state for Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
  
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setEquipmentTypeData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  

    const handleSaveClick = async () => {
      const updatedEquipmentData = {
        ...equipmentTypeData,
        DepartmentId: selectedDepartment ? selectedDepartment.value : '',
        DepartmentName: selectedDepartment ? selectedDepartment.label : '',
        SubDepartmentId: selectedSubDepartment ? selectedSubDepartment.value : '',
        SubDepartmentName: selectedSubDepartment ? selectedSubDepartment.label : '',
    };
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem('authKey');
    
        const response = await axios.post(
          `${API_URL}/api/add_equipment_type`,
          updatedEquipmentData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
    
        console.log(response.data.message);
        if (response.status === 200) {
          const newEquipment = {
            label: equipmentTypeData.EquipmentType,
            value: response.data.id,
          };
          handleNewEquipmentType(newEquipment);
          closePopup();
        }
      } catch (error) {
        console.error('Error saving user:', error.message);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false);
      }
    };
    
  
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };
  
    const showSnackbar = (message) => {
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    };
    const handleDepartmentMenuOpen = () => {
      setDepartmentMenuIsOpen(true);
    };
  
    const CustomControl = ({ children, ...props }) => (
      <Control {...props}>{children}</Control>
    );
    
    const handleDepartmentChange = (selectedOption) => {
      setSelectedDepartment(selectedOption);
      setEquipmentTypeData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: selectedOption.label,
        DepartmentId: selectedOption.value,
      }));
    };
  
    const fetchDepartment = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_departments`;
    
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authKey,
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          // Map the API response to the format expected by react-select
          const options = data.map((department) => ({
            label: department.DepartmentName,
            value: department.ID
          }));
          setDepartments(options);
        } else {
          console.error("Failed to fetch departments");
        }
      } catch (error) {
        console.error("An error occurred while fetching departments:", error);
      }
    };
    
    
    useEffect(() => {
      fetchDepartment();
    }, []);
 
    const fetchSubDepartments = async (departmentId) => {
      try {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_sub_department/${departmentId}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authKey,
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          const options = data.map((subDepartment) => ({
            label: subDepartment.Name,
            value: subDepartment.id,
          }));
          setSubDepartments(options);
        } else {
          console.error("Failed to fetch sub-departments");
        }
      } catch (error) {
        console.error("An error occurred while fetching sub-departments:", error);
      }
    };

    useEffect(() => {
      if (selectedDepartment) {
        fetchSubDepartments(selectedDepartment.value);
      }
    }, [selectedDepartment]);

    const handleSubDepartmentChange = (selectedOption) => {
      setSelectedSubDepartment(selectedOption);
      setEquipmentTypeData((prevFormData) => ({
        ...prevFormData,
        SubDepartmentName: selectedOption.label,
        SubDepartmentId: selectedOption.value,
      }));
    };
    
    const CustomSubDepartmentControl = ({ children, ...props }) => (
      <Control {...props}>{children}</Control>
    );
   

    const handleSubDepartmentMenuOpen = () => {
      setSubDepartmentMenuIsOpen(true);
    };
  return (
    <div className="inquiry-customerperson-popup-content">
      <div className="inquiry-customerperson-modal">
        <div className="inquiry-customerperson-modal-content">
          <div className='customerpersonpopupform-header'>
            <h4 className='customerpersonpopupform-title'>New Equipement Type</h4>
            <span className='customerpersonpopupform-btn' onClick={closePopup}><IoCloseOutline /></span>
          </div>
          <div className="customerpersonpopupform-body">
          <form>
          <div className="customerpersonpopup-form">
          <div className="form-group-select">
                  <label htmlFor="Department">Department<span className="text-danger">*</span></label>
                  <Select
                    id="departments"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    components={{ Control: CustomControl }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />

               
                
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubDepartment">Sub  Department<span className="text-danger">*</span></label>
                  <Select
                    id="SubDepartment"
                    options={subDepartments}
                    value={selectedSubDepartment}
                    onChange={handleSubDepartmentChange}
                    components={{ Control: CustomSubDepartmentControl, }}
                    placeholder="Select Sub Department"
                    isSearchable
                    menuIsOpen={subDepartmentMenuIsOpen}
                    onMenuOpen={handleSubDepartmentMenuOpen}
                    onMenuClose={() => setSubDepartmentMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? theme === "dark"
                            ? "#666" // Gray when hovered in dark theme
                            : "lightblue" // Light gray when hovered in light theme
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Background color for options based on theme
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />

               
                
                </div>
                <div className="form-group">
                  <label htmlFor="EquipmentType">Name<span className="text-danger">*</span></label>
                  <div className="input-groups">
                    <input
                      type="text"
                      name="EquipmentType"
                      value={equipmentTypeData.EquipmentType}
                      onChange={handleInputChange}
                      placeholder="Enter Equipment Type"
                    />

                  </div>
                
                </div>
              </div>
            </form>
          </div>
          <div className="customerpersonpopupform-footer">
            <div className="button-group">
              <button className="customerpersonpopupform-save-button"   onClick={handleSaveClick}
                disabled={isLoading} >
                {isLoading ? 'Saving...' : 'Save'}
              </button>
              <button className="customerpersonpopupform-cancel-button"     onClick={closePopup}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEquipmentType;