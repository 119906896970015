import React, { useState, useEffect } from 'react';
import './Pagination.css';
import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const Pagination = ({ showPerPage, total, onPaginationChange }) => {
  // Retrieve from localStorage if available
  const storedCurrentPage = localStorage.getItem("currentPage");
  const storedItemsPerPage = localStorage.getItem("itemsPerPage");

  const [currentPage, setCurrentPage] = useState(storedCurrentPage ? parseInt(storedCurrentPage, 10) : 1);
  const [itemsPerPage, setItemsPerPage] = useState(storedItemsPerPage ? parseInt(storedItemsPerPage, 10) : showPerPage);

  const totalPages = Math.ceil(total / itemsPerPage);
  const totalItems = total;

  useEffect(() => {
    onPaginationChange(currentPage, itemsPerPage);
    // Store the values in localStorage
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  }, [itemsPerPage, currentPage, total]);

  const onButtonclick = (type) => {
    let newPage = currentPage;

    if (type === 'first') {
      newPage = 1;
    } else if (type === 'prev') {
      newPage = Math.max(1, currentPage - 1);
    } else if (type === 'next') {
      newPage = Math.min(totalPages, currentPage + 1);
    } else if (type === 'last') {
      newPage = totalPages;
    }

    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  };

  // const getPageRangeText = () => `Page ${currentPage} of ${totalPages}, Total: ${totalItems} Items`;

  const getPageRangeText = () => (
    <>
      Page {currentPage} of {totalPages}, Total: <strong>{totalItems}</strong> <span style={{ color: '#4e4545' }}>Items</span>
    </>
  );
  

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);  // Reset to page 1 when items per page changes
  }; 

  return (
    <div className="paginations">
      <div className="pagination-body">
        <div className="pagination-left-side">
          <div className="page-range">{getPageRangeText()}</div>
        </div>
        <div className="pagination-right-side">
          <div className="page">
            <div className="itempage">Lines Per Page: </div>
          </div>
          <div className="dropdown">
            <div className="dropdown-option">
              <select name="dropdown" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <div className="arrow-number">
            <div className="arrow1" onClick={() => onButtonclick('first')}>
              <RxDoubleArrowLeft />
            </div>
            <div className="arrow2" onClick={() => onButtonclick('prev')}>
              <IoIosArrowBack />
            </div>
            <div className="arrow3" onClick={() => onButtonclick('next')}>
              <IoIosArrowForward />
            </div>
            <div className="arrow4" onClick={() => onButtonclick('last')}>
              <RxDoubleArrowRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
