import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Level1 from "../Matcode/Level1";
import Level2 from "../Matcode/Level2";
import Level3 from "../Matcode/Level3";
import { Button, TextField, Tabs, Tab } from "@mui/material";

function LevelConfig({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");

  const [tabValue, setTabValue] = useState(
    tabFromUrl || "level-one" 
  );

 useEffect(() => {
    // Update tab value if URL param changes
    if (tabFromUrl && tabFromUrl !== tabValue) {
      setTabValue(tabFromUrl);
    }
  }, [location.search]);


  

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Update URL query parameter to reflect the current tab
    navigate(`?tab=${newValue}`);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
     
          
          <div className="data-list-content" ref={containerRef}>
             <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="report tabs"
              className="tabs-container"
            >
              <Tab label="Level1" value="level-one"/>
              <Tab label="Level2" value="level-two"/>
              <Tab label="Level3" value="level-three"/>
            </Tabs>
            <div className="header-separater"></div>
            <div className="tabs-content">
            {tabValue === "level-one" && <div><Level1/></div>}
              {tabValue === "level-two" && <div><Level2/></div>}
             
              {tabValue === "level-three" && <div><Level3/></div>}
            </div>
          </div>
    
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default LevelConfig;
