import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
// import "./ScheduleView/PreventiveSchedulesView.css";
import "./ApprovedPrView.css";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IoMdArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Select from "react-select"; // Import react-select
import { FiDownload, FiUpload } from "react-icons/fi";
import { FiPlus } from "react-icons/fi";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmPRRequest from "./ConfirmPRRequest";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
function ApprovedPrView({ isSidebarExpanded }) {
  const { pr_id } = useParams();
  const [status, setStatus] = useState("");
  const [approvedPRData, setApprovedPRData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [remark, setRemark] = useState("");
  const [serviceDueDate, setServiceDueDate] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState({});
  // const [activeTab, setActiveTab] = useState("matCode");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // Get query params
  const initialTab = queryParams.get("tab") || "matCode"; // Default to "matCode"
  const [activeTab, setActiveTab] = React.useState(initialTab);
  const {theme} = useContext(ThemeContext)
  const [rows, setRows] = useState([
    {
      vendor_id: "",
      vendor_type: "",
      contact_no: "",
      description: "",
      final_offer: "",
      details: "",
    },
  ]);

  const fileInputRefs = useRef([]);

  const handleSaveClick = async (index) => {
    const row = rows[index];
    const selectedVendor = selectedVendors[index];

    if (!selectedVendor || !selectedVendor.value) {
      setSnackbarMessage("Please select a vendor before saving.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    const payload = {
      pr_request_id: pr_id,
      vendor_id: selectedVendor.value,
      vendor_type: row.vendor_type,
      contact_no: row.contact_no,
    };

    try {
      const authKey = localStorage.getItem("authKey");

      // Save vendor data
      const postResponse = await axios.post(
        `${API_URL}/api/add_pr_vendor`,
        payload,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (postResponse.status === 200) {
        await fetchPRVendorsData();

        // Show success message in Snackbar
        setSnackbarMessage("Vendor saved successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error saving vendor data:", error);

      // Show error message in Snackbar
      setSnackbarMessage("Error saving vendor data.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        vendor_id: "",
        vendor_type: "",
        contact_no: "",
        final_offer: "",
        details: "",
        isEditing: true,
        isNew: true,
      },
    ]);
  };

  const handleEditClick = (index) => {
    const updatedRows = rows.map((row, i) => ({
      ...row,
      isEditing: i === index,
    }));
    setRows(updatedRows);
  };

  const handleVendorChange = (selectedOption, index) => {
    // Update the selected vendors state
    setSelectedVendors((prevSelected) => ({
      ...prevSelected,
      [index]: selectedOption,
    }));

    const selectedVendor = vendors.find(
      (vendor) => vendor.value === selectedOption.value
    );
    if (selectedVendor) {
      const updatedRows = rows.map((row, rowIndex) => {
        if (rowIndex === index) {
          return {
            ...row,
            vendor_type: selectedVendor.vendor_type,
            contact_no: selectedVendor.contact_no,
          };
        }
        return row;
      });
      setRows(updatedRows);
    }
  };

  const handleUploadButtonClick = (index) => {
    fileInputRefs.current[index].click();
  };

  const navigate = useNavigate();

  // const handleTabClick = (tabName) => {
  //   setActiveTab(tabName);
  // };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName); // Update state
    navigate(`?tab=${tabName}`); // Update URL with the new tab
  };

  // Sync state with URL when it changes
  useEffect(() => {
    const tabFromUrl = queryParams.get("tab");
    if (tabFromUrl && tabFromUrl !== activeTab) {
      setActiveTab(tabFromUrl);
    }
  }, [location.search]); // Runs when the URL changes

  const fetchVendorData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_vendors_procurement`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      // Map the vendors from the response
      const vendorOptions = Array.isArray(response.data.Vendors)
        ? response.data.Vendors.map((vendor) => ({
            value: vendor.id,
            label: vendor.vendor_name,
            vendor_type: vendor.vendor_type,
            contact_no: vendor.contact_no,
          }))
        : [];

      setVendors(vendorOptions);
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendorData();
  }, []);

  const handleDetailsClick = (pr_vendor_id) => {
    navigate(`/pr-vendor-details/${pr_vendor_id}`, { state: { pr_id } });
  };

  const handleUploadFinalOffer = async (index) => {
    const row = rows[index];
    const file = fileInputRefs.current[index].files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("attachments", file);

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/upload_final_quotation_attachment/${row.id}`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("File uploaded successfully:", response.data.file_path);
        setSnackbarMessage("File uploaded successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        await fetchPRVendorsData();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setSnackbarMessage("Error uploading file");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDownload = async (fileId, fileType, filename) => {
    try {
      const authKey = localStorage.getItem("authKey");

      const downloadUrl = `${API_URL}/api/download_pr_attachment/${fileId}/${fileType}`;

      // Make the API request to download the file
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download the file:", response.statusText);
      }
    } catch (error) {
      console.error("Error while downloading file:", error);
    }
  };

  const fetchPRRequestData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const prRequestResponse = await fetch(
        `${API_URL}/api/get_pr_request/${pr_id}`,
        {
          method: "GET",
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (prRequestResponse.ok) {
        const prRequestData = await prRequestResponse.json();

        const prRequest = prRequestData.pr_request || {};
        const poListItems = prRequestData.po_list_items || [];

        setApprovedPRData({ ...prRequest, po_list_items: poListItems });

        const fetchedStatus =
          prRequest.status || prRequest.pr_status || "Unknown";
        setStatus(fetchedStatus);
        setServiceDueDate(new Date(prRequest.expected_delivery_date));
        setRemark(prRequest.remarks);
      } else {
        console.error(
          "Error fetching PR request:",
          prRequestResponse.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching PR request data:", error.message);
    }
  };

  const fetchPRVendorsData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const prVendorsResponse = await fetch(
        `${API_URL}/api/get_pr_vendors/${pr_id}`,
        {
          method: "GET",
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (prVendorsResponse.ok) {
        const prVendorsData = await prVendorsResponse.json();
        const prVendors = prVendorsData.pr_vendors || [];

        const vendorOptions = prVendors.map((vendor) => ({
          value: vendor.vendor_id,
          label: vendor.vendor_name,
          vendor_type: vendor.vendor_type,
          contact_no: vendor.contact_no,
        }));

        setRows(prVendors);

        // Update selected vendors based on the rows
        const initialSelectedVendors = prVendors.map(
          (row) =>
            vendorOptions.find((vendor) => vendor.value === row.vendor_id) ||
            null
        );
        setSelectedVendors(initialSelectedVendors);
      } else {
        console.error(
          "Error fetching PR vendors:",
          prVendorsResponse.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching PR vendors data:", error.message);
    }
  };

  useEffect(() => {
    fetchPRRequestData();
    fetchPRVendorsData();
  }, [pr_id]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleBackScheduler = () => {
    navigate("/approved-pr-requests");
  };

  const handleDeleteVendor = async (index) => {
    const row = rows[index];
    try {
      const authKey = localStorage.getItem("authKey");

      const deleteResponse = await axios.delete(
        `${API_URL}/api/delete_pr_vendor/${row.id}`,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (deleteResponse.status === 200) {
        setSnackbarMessage("Vendor deleted successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setIsDeletePopup(false);

        await fetchPRVendorsData();
      }
    } catch (error) {
      console.error("Error deleting vendor:", error);
      setSnackbarMessage("Error deleting vendor.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCancelClick = (index) => {
    // Reset the specific row's editing state
    const updatedRows = rows.map((row, i) => ({
      ...row,
      isEditing: i === index ? false : row.isEditing,
    }));

    setRows(updatedRows);
  };

  const handleDeleteClick = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  // const handleUpdateRow = async (index) => {
  //   const row = rows[index];
  //   const payload = {
  //     vendor_id: selectedVendors[index]?.value,
  //     vendor_type: row.vendor_type,
  //     contact_no: row.contact_no,
  //     pr_request_id:row.id,
  //   };

  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     const putResponse = await axios.put(
  //       `${API_URL}/api/update_pr_vendor/${row.id}`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (putResponse.status === 200) {

  //       console.log("Vendor updated successfully");

  //       // Exit edit mode after successful update
  //       const updatedRows = rows.map((row, i) =>
  //         i === index ? { ...row, isEditing: false, isNew: false } : row
  //       );

  //       setRows(updatedRows);
  //       setSnackbarMessage("Vendor updated successfully!");
  //       setSnackbarSeverity("success");
  //       setSnackbarOpen(true);
  //     }
  //   } catch (error) {
  //     console.error("Error updating vendor:", error);
  //     setSnackbarMessage("Error updating vendor.");
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //   }
  // };

  const handleUpdateRow = async (index) => {
    const row = rows[index];
    const selectedVendor = selectedVendors[index];

    const payload = {
      vendor_id: selectedVendor?.value,
      vendor_type: row.vendor_type,
      contact_no: row.contact_no,
      pr_request_id: row.id,
    };

    try {
      const authKey = localStorage.getItem("authKey");

      const putResponse = await axios.put(
        `${API_URL}/api/update_pr_vendor/${row.id}`,
        payload,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (putResponse.status === 200) {
        console.log("Vendor updated successfully");

        // Update the specific row in the rows state
        const updatedRows = rows.map((r, i) =>
          i === index
            ? {
                ...r,
                vendor_name: selectedVendor?.label || r.vendor_name,
                isEditing: false,
                isNew: false,
              }
            : r
        );

        setRows(updatedRows); // Update the rows state
        setSnackbarMessage("Vendor updated successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating vendor:", error);
      setSnackbarMessage("Error updating vendor.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    // setIsDeletePopupOpen(false);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  return (
    <>
      <div
        className={`invoice-request-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="invoice-request-content">
          <div className="invoice-request-header">
            <div className="invoice-request-header-content">
              <div className="invoice-request-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackScheduler}
                  />
                </Tooltip>
                Approved PR Details
              </div>
            </div>
          </div>
          <div className="action-purchase-header">
            <div className="action-purchase-header-content">
              {approvedPRData ? (
                <div className="project-detailss">
                  <div className="project-detail-arround">
                    <div className="project-details-left-side">
                      {/* Department Name */}
                      <div className="project-detail">
                        <div className="detail-header">Department Name:</div>
                        <div className="detail-part">
                          {approvedPRData.department_name
                            ? approvedPRData.department_name
                            : "N/A"}
                        </div>
                      </div>

                      {/* PR Type */}
                      <div className="project-detail">
                        <div className="detail-header">PR Type:</div>
                        <div className="detail-part">
                          {approvedPRData.pr_type
                            ? approvedPRData.pr_type
                            : "N/A"}
                        </div>
                      </div>

                      {/* Preferred Vendor */}
                      <div className="project-detail">
                        <div className="detail-header">Preferred Vendor:</div>
                        <div className="detail-part">
                          {approvedPRData.preferred_vendor
                            ? approvedPRData.preferred_vendor
                            : "N/A"}
                        </div>
                      </div>

                      {/* PR Status */}
                      <div className="project-detail">
                        <div className="detail-header">PR Status:</div>
                        <div className="detail-part">
                          {approvedPRData.pr_status
                            ? approvedPRData.pr_status
                            : "N/A"}
                        </div>
                      </div>

                      {/* Description */}
                      <div className="project-detail">
                        <div className="detail-header">PR Description:</div>
                        <div className="detail-part">
                          {approvedPRData.description
                            ? approvedPRData.description
                            : "N/A"}
                        </div>
                      </div>

                      {/* Expected Delivery Date */}
                      <div className="project-detail">
                        <div className="detail-header">
                          Expected Delivery Date:
                        </div>
                        <div className="detail-part">
                          {approvedPRData.expected_delivery_date
                            ? new Date(
                                approvedPRData.expected_delivery_date
                              ).toLocaleDateString()
                            : "N/A"}
                        </div>
                      </div>

                      {/* Expenditure Type */}
                      <div className="project-detail">
                        <div className="detail-header">Expenditure Type:</div>
                        <div className="detail-part">
                          {approvedPRData.expenditure_type
                            ? approvedPRData.expenditure_type
                            : "N/A"}
                        </div>
                      </div>

                      {/* Vendor Name */}
                      <div className="project-detail">
                        <div className="detail-header">Vendor Name:</div>
                        <div className="detail-part">
                          {approvedPRData.vendor_name
                            ? approvedPRData.vendor_name
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
          <div className="invoice-body-container">
            <div className="invoice-body-container-content-schedules">
              {/* Tab Headers */}
              <div className="tabs">
                <button
                  className={`tab-button ${
                    activeTab === "matCode" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("matCode")}
                >
                  Material Code
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "vendorSelection" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("vendorSelection")}
                >
                  Vendor Selection
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "prRequest" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("prRequest")}
                >
                  PR Request
                </button>
              </div>

              {/* Tab Content */}
              <div className="tab-content">
                {activeTab === "matCode" && (
                  <div className="tab-pane">
                    <div className="action-purchase-sheet-header">
                      <div className="actions-purchase-sheet-header-content">
                        <div className="actions-purchase-sheet-heading-content-left-side">
                          Item Table
                        </div>
                        <div className="actions-purchase-heading-content-right-side"></div>
                      </div>
                    </div>

                    <div className="action-purchase-body">
                      <div className="action-purchase-scroll-body">
                        <div className="action-purchase-tables">
                          <>
                            <table className="action-table action-multilevel-data ">
                              <thead className="action-purchase-heads">
                                <tr>
                                  <th className="th2">MAT CODE</th>
                                  <th className="th2">DESCRIPTION</th>
                                  <th className="th3">UNIT</th>
                                  <th className="th4">QUANTITY</th>
                                  <th className="th5">ESTIMATED UNIT RATE</th>
                                </tr>
                              </thead>

                              <tbody className="action-purchase-scroll-bodys">
                                {approvedPRData.po_list_items &&
                                approvedPRData.po_list_items.length > 0 ? (
                                  approvedPRData.po_list_items.map((item) => (
                                    <tr key={item.id}>
                                      <td>
                                        {item.mat_code ? item.mat_code : "N/A"}
                                      </td>
                                      <td>
                                        {item.mat_code_description
                                          ? item.mat_code_description
                                          : "N/A"}
                                      </td>
                                      <td>{item.unit ? item.unit : "N/A"}</td>
                                      <td>
                                        {item.quantity !== null
                                          ? item.quantity
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {item.est_unit_rate
                                          ? item.est_unit_rate
                                          : "N/A"}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="5">No items available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "vendorSelection" && (
                  <div className="tab-pane">
                    <div className="action-purchase-sheet-header">
                      <div className="actions-purchase-sheet-header-content">
                        <div className="actions-purchase-sheet-heading-content-left-side">
                          Vendor Selection
                        </div>
                        <div className="actions-purchase-heading-content-right-side"></div>
                      </div>
                    </div>

                    <div className="action-purchase-body">
                      <div className="action-purchase-scroll-body">
                        <div className="action-purchase-tables">
                          <>
                            <table className="action-table action-multilevel-data ">
                              <thead className="action-purchase-heads">
                                <tr>
                                  <th className="th2">VENDOR</th>
                                  <th className="th2">TYPE OF VENDOR</th>
                                  <th className="th3">CONTACT NO</th>

                                  <th className="th3">FINAL OFFER</th>
                                  <th className="th3">DETAILS</th>
                                  <th className="th3">ACTION</th>
                                </tr>
                              </thead>
                              <tbody className="action-purchase-scroll-bodys">
                                {rows.map((row, index) => (
                                  <tr key={index}>
                                    <td className="th2">
                                      {row.isEditing ? (
                                        <Select
                                          options={vendors}
                                          onChange={(selectedOption) =>
                                            handleVendorChange(
                                              selectedOption,
                                              index
                                            )
                                          }
                                          value={selectedVendors[index] || null}
                                          styles={{
                                            control: (provided) => ({
                                              ...provided,
                                              backgroundColor:
                                                theme === "dark"
                                                  ? "black"
                                                  : "white", // Dropdown background color
                                              color:
                                                theme === "dark"
                                                  ? "#fff"
                                                  : "#333", // Text color
                                              borderColor:
                                                theme === "dark"
                                                  ? "#444"
                                                  : "#ccc", // Border color
                                              cursor: "pointer",
                                              boxShadow: "none",
                                              "&:hover": {
                                                borderColor:
                                                  theme === "dark"
                                                    ? "#666"
                                                    : "#888", // Border color on hover
                                              },
                                            }),
                                            menu: (provided) => ({
                                              ...provided,
                                              backgroundColor:
                                                theme === "dark"
                                                  ? "#000"
                                                  : "#fff", // Black background for dropdown menu in dark mode
                                            }),
                                            option: (provided, state) => ({
                                              ...provided,
                                              backgroundColor: state.isFocused
                                                ? "#666" // Gray when hovered
                                                : theme === "dark"
                                                ? "#000"
                                                : "#fff", // Black background for options in dark mode
                                              color: state.isFocused
                                                ? "#fff"
                                                : theme === "dark"
                                                ? "#fff"
                                                : "#333", // Text color
                                              cursor: "pointer",
                                            }),
                                            singleValue: (provided) => ({
                                              ...provided,
                                              color:
                                                theme === "dark"
                                                  ? "#fff"
                                                  : "#333", // Selected option text color
                                            }),
                                          }}
                                        />
                                      ) : (
                                        // row.vendor_name ||
                                        // selectedVendors[index]?.label ||
                                        // "N/A"
                                        row.vendor_name || "N/A"
                                      )}
                                    </td>
                                    <td>
                                      {row.isEditing ? (
                                        <input
                                          type="text"
                                          value={row.vendor_type || ""}
                                          disabled
                                        />
                                      ) : (
                                        row.vendor_type || "N/A"
                                      )}
                                    </td>
                                    <td>
                                      {row.isEditing ? (
                                        <input
                                          type="text"
                                          value={row.contact_no || ""}
                                          disabled
                                        />
                                      ) : (
                                        row.contact_no || "N/A"
                                      )}
                                    </td>
                                    <td>
                                      {row.isEditing ? (
                                        <>
                                          <button
                                            type="button"
                                            className="challan-btn"
                                            onClick={() =>
                                              handleUploadButtonClick(index)
                                            }
                                            disabled={
                                              row.isNew || !row.vendor_id
                                            }
                                          >
                                            <FiUpload /> Upload File
                                          </button>
                                          <input
                                            id={`attachments-${row.id}`}
                                            type="file"
                                            ref={(el) =>
                                              (fileInputRefs.current[index] =
                                                el)
                                            }
                                            style={{ display: "none" }}
                                            onChange={() =>
                                              handleUploadFinalOffer(index)
                                            }
                                          />
                                        </>
                                      ) : // Check if there are attachments and display file details
                                      row.attachments &&
                                        row.attachments.length > 0 ? (
                                        <div>
                                          {row.attachments.map(
                                            (file, fileIndex) => (
                                              <div
                                                key={file.file_id}
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  marginBottom: "5px",
                                                }}
                                              >
                                                {/* <a
                                                    href={`${API_URL}/api/view_pr_attachment/${file.file_id}/${file.file_type}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="download-link"
                                                  >
                                                    {file.filename} (
                                                    {file.file_size} KB)
                                                  </a> */}
                                                <a
                                                  href={`${API_URL}/api/view_pr_attachment/${file.file_id}/${file.file_type}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="download-link"
                                                >
                                                  {file.filename.length > 25
                                                    ? `${file.filename.substring(
                                                        0,
                                                        15
                                                      )}...`
                                                    : file.filename}{" "}
                                                  ({file.file_size} KB)
                                                </a>

                                                <FiDownload
                                                  className="download-icon"
                                                  style={{
                                                    cursor: "pointer",
                                                    marginLeft: "10px",
                                                  }}
                                                  onClick={() =>
                                                    handleDownload(
                                                      file.file_id,
                                                      file.file_type,
                                                      file.filename
                                                    )
                                                  }
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        "N/A"
                                      )}
                                    </td>

                                    <td>
                                      <button
                                        className="data-form-details-button"
                                        onClick={() =>
                                          handleDetailsClick(row.id)
                                        }
                                        disabled={row.isNew || !row.vendor_id}
                                      >
                                        Details
                                      </button>
                                    </td>

                                    <td>
                                      {row.isEditing ? (
                                        <>
                                          <button
                                            className="data-form-save-button"
                                            onClick={() =>
                                              row.isNew
                                                ? handleSaveClick(index)
                                                : handleUpdateRow(index)
                                            }
                                          >
                                            {row.isNew ? "Save" : "Update"}
                                          </button>
                                          {row.isNew ? (
                                            <button
                                              className="remove-button"
                                              onClick={() =>
                                                handleDeleteClick(index)
                                              }
                                            >
                                              Remove
                                            </button>
                                          ) : (
                                            <button
                                              className="cancel-button"
                                              onClick={() =>
                                                handleCancelClick(index)
                                              }
                                            >
                                              Cancel
                                            </button>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            className="data-form-save-button"
                                            onClick={() =>
                                              handleEditClick(index)
                                            }
                                          >
                                            Edit
                                          </button>
                                          <button
                                            className="delete-row"
                                            // onClick={() =>
                                            //   handleDeleteVendor(index)
                                            // }
                                            onClick={handleDeletePopup}
                                          >
                                            Delete
                                          </button>
                                          {isDeletePopup && (
                                            <div className="confirm-delete-popup">
                                              <div className="confirm-delete-popup-content">
                                                <div className="confirm-model">
                                                  <div className="confirm-model-content">
                                                    <div className="confirm-model-content-body">
                                                      <p>
                                                        Are you sure want to
                                                        delete vendor?
                                                      </p>
                                                    </div>
                                                    <div className="confirm-model-content-footer">
                                                      <button
                                                        className="confirm-delete-button"
                                                        // onClick={handleArchiveCustomer}
                                                        onClick={() =>
                                                          handleDeleteVendor(
                                                            index
                                                          )
                                                        }
                                                      >
                                                        Yes
                                                      </button>
                                                      <button
                                                        className="confirm-cancel-button"
                                                        onClick={handleCancel}
                                                      >
                                                        No
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="row">
                              <div className="action-caluculation">
                                <div className="new-rows">
                                  <button
                                    onClick={handleAddRow}
                                    className="add-rows"
                                  >
                                    <FiPlus className="add-row-icon" /> Add New
                                    Row
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "prRequest" && (
                  <div className="tab-pane">
                    {/* <h2>PR Request</h2>
                    <p>Display PR Request information here.</p> */}
                    <ConfirmPRRequest />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ApprovedPrView;
