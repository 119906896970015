import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
// import "./PreventiveSchedulesView.css";
import "./VendorView.css";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { LuDownload, LuEye } from "react-icons/lu";

function VendorView({ isSidebarExpanded }) {
  const { id } = useParams();
  const [vendorData, setVendorData] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const fetchVendorData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await fetch(`${API_URL}/api/get_vendor/${id}`, {
        method: "GET",
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setVendorData(data);
      } else {
        console.error("Error fetching vendor data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendorData();
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleBackScheduler = () => {
    navigate("/vendors");
  };

  const handleViewAttachment = (attachment) => {
    const url = `${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`;
    window.open(url, "_blank");
  };


  const renderAttachments = (type) => {
    if (!vendorData || !vendorData.attachments) return null;

    const filteredAttachments = vendorData.attachments
      .filter((attachment) => attachment.file_type === type);

    if (filteredAttachments.length === 0) {
      return <div className="file-name-label">Document details not found</div>;
    }

    return filteredAttachments.map((attachment) => (
      <div className="file-name-label" key={attachment.file_id}>
        <div className="filename">
          <label htmlFor="" className="file-name-lists">
            File Name:
          </label>
          <span className="file-name"> {attachment.filename.length > 15
            ? `${attachment.filename.substring(0, 25)}...`
            : attachment.filename}</span>
        </div>
        <div className="button-fleex">
          <button className="view-attachment" onClick={() => handleViewAttachment(attachment)}>
            <LuEye className="view-attachment-icon" />
          </button>
          <a
            href={`${API_URL}/api/download_attachment/${attachment.file_id}/${attachment.file_type}`}
            download
            className="download-attachment"
          >
            <LuDownload className="download-attachment-icon" />
          </a>
        </div>
      </div>
    ));
  };


  return (
    <>
      <div
        className={`invoice-request-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="invoice-request-content">
          <div className="invoice-request-header">
            <div className="invoice-request-header-content">
              <div className="invoice-request-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackScheduler}
                  />
                </Tooltip>
                Vendor View
              </div>
            </div>
            <div className="action-purchase-header">
              <div className="action-purchase-header-content">
                {vendorData && (
                  <div>
                    <div className="project-detailss">
                      <div className="project-detail-arround">
                        <div className="project-details-left-side">
                          <div className="project-detail">
                            <div className="detail-header">Vendor Name:</div>
                            <div className="detail-part">
                              {vendorData.vendor_name || "N/A"}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">Vendor Type:</div>
                            <div className="detail-part">
                              {vendorData.vendor_type || "N/A"}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">PAN No:</div>
                            <div className="detail-part">
                              {vendorData.pan || "N/A"}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">GST No:</div>
                            <div className="detail-part">
                              {vendorData.gst_no || "N/A"}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">Address:</div>
                            <div className="detail-part">
                              {vendorData.address || "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="invoice-body-container">
              <div className="invoice-body-container-content">
                <div className="invoice-upload-files">
                  <label className="upload-label" htmlFor="docket-file">
                    GST Documents:
                  </label>
                  {renderAttachments("gst")}
                </div>
                <div className="invoice-upload-files">
                  <label className="upload-label" htmlFor="docket-file">
                    PAN Documents:
                  </label>
                  {renderAttachments("pan")}
                </div>
                <div className="invoice-upload-files">
                  <label className="upload-label" htmlFor="docket-file">
                    Incorporation Documents:
                  </label>
                  {renderAttachments("incorporation")}
                </div>
                <div className="invoice-upload-files">
                  <label className="upload-label" htmlFor="docket-file">
                    MSME Documents:
                  </label>
                  {renderAttachments("msme")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default VendorView;
